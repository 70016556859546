import { supabase } from "utils/supabaseClient";

const DB_NAME = "notification";

export default class Notification {
    static async getUnreadNotificationCountByID(id) {
        return await supabase
            .from(DB_NAME)
            .select('id', { count: 'exact' })
            .eq("user_id", id)
            .eq("is_read", false);
    }

    static async getNotificationByID(id) {
        return await supabase
            .from(DB_NAME)
            .select('*, creator (id, first_name)')
            .eq("user_id", id)
            .order("created_at", { ascending: false });
    }

    static async markAsRead(notificationIDs) {
        return await supabase.from(DB_NAME)
            .update({ is_read: true })
            .in('id', notificationIDs)
            .select();
    }

    static async delete(notificationIDs) {
        return await supabase.from(DB_NAME)
            .delete()
            .in('id', notificationIDs)
            .select();
    }
}
