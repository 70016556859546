import React, { useEffect, useState } from "react";
import { EntityStatus, convertUTCToESTFormatted } from "utils/utils";
import { CommonTable } from "./CommonTable";
import License from "db/license";

const tableColumns = [
  { key: "id", header: "ID" },
  {
    key: "customer.name",
    header: "Customer",
    cell: (row) => {
      return (
        <div className="flex flex-col gap-3">
          <div className="flex gap-2 whitespace-nowrap">
            <p className="text-sm">{row?.customer?.name}</p>
          </div>
        </div>
      );
    },
  },
  { key: "license_type.value", header: "License Type" },
  { key: "key", header: "License Key" },
  { key: "memo", header: "Memo" },
  {
    key: "created_at",
    header: "Created Date",
    cell: (row) => {
      return (
        <div className="whitespace-nowrap">
          {convertUTCToESTFormatted(row.created_at)}
        </div>
      );
    },
  },
  {
    key: "activated_at",
    header: "Activated Date",
    cell: (row) => {
      return (
        <div className="whitespace-nowrap">
          {row.activated_at && convertUTCToESTFormatted(row.activated_at)}
        </div>
      );
    },
  },
  {
    key: "is_active",
    header: "Status",
    cell: (row) => <EntityStatus inactive={!row.is_active} />,
  },
];

export const SettingTabLicense = () => {
  const [license, setLicense] = useState([]);
  const fetchLicense = async () => {
    const { data } = await License.getAllData();
    if (data) {
      setLicense(data);
    }
  };

  useEffect(() => {
    fetchLicense();
  }, []);

  return (
    <div className="m-2 lg:m-5">
      <CommonTable
        tableName="Licenses"
        tableColumns={tableColumns}
        tableRows={license}
        requireCreateButton={true}
        requireSearch={false}
      />
    </div>
  );
};
