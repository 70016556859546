import { AppContext } from "AppContext";
import { useContext } from "react";

export const useToast = () => {
  const { toastRef } = useContext(AppContext);

  const showSuccess = (
    summary = "Success!",
    detail = "Your action was successful",
  ) => {
    toastRef.current.show({
      severity: "success",
      summary: summary,
      detail: detail,
      life: 1500,
    });
  };

  const showError = (
    summary = "Error!",
    detail = "Something went wrong. Please try again later.",
  ) => {
    toastRef.current.show({
      severity: "error",
      summary: summary,
      detail: detail,
      life: 1500,
    });
  };

  return {
    showSuccess,
    showError,
  };
};
