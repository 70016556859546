import { supabase } from "utils/supabaseClient";

const DB_NAME = "customer";

export default class Customer {
  static async getAllData() {
    return await supabase
      .from(DB_NAME)
      .select(
        `
                *,
                rep (
                    id,
                    first_name,
                    last_name
                ),
                platform (
                  id,
                  value,
                  inactive
                )
            `,
      )
      .order("created_at", { ascending: false });
  }

  static async getCustomersWithRangeAndFilter(from, to, filters) {
    const inactive = filters["inactive"] ?? null;
    const searchTerm = filters["searchInput"] ?? null;
    const { sortField, sortOrder } = filters["sortState"] ?? {
      sortField: null,
      sortOrder: null,
    };

    const query = supabase.from(DB_NAME).select(
      `
                *,
                rep (
                    id,
                    first_name,
                    last_name
                ),
                platform (
                  id,
                  value,
                  inactive
                )
            `,
      { count: "exact" },
    );

    if (inactive !== null) {
      query.eq("inactive", inactive);
    }

    if (searchTerm !== null && searchTerm !== "") {
      query.or(`customer_search_index.ilike."%${searchTerm}%"`);
    }

    if (sortField != null) {
      query.order(sortField, { ascending: sortOrder === 1, nullsFirst: false });
    } else {
      query.order("created_at", { ascending: false });
    }

    return await query.order("id", { ascending: false }).range(from, to);
  }

  static async getCustomerById(id) {
    return await supabase
      .from(DB_NAME)
      .select(
        `*, 
        rep (
          id,
          first_name,
          last_name
        )`,
      )
      .eq("id", id);
  }

  static async getCustomerPhotosByID(id) {
    return await supabase.rpc("get_all_photos_of_customer", {
      customer_id: id,
    });
  }

  static async getCustomerNameList() {
    return await supabase
      .from(DB_NAME)
      .select("id, name, address, city, state, zip, inactive")
  }

  static async upsertCustomer(customer) {
    if (customer.last_pci_date === "") {
      customer.last_pci_date = null;
    }
    if (customer?.rep?.id != null) {
      customer.rep = customer.rep.id;
    }

    for (var i = customer.accounts.length - 1; i >= 0; i--) {
      var account = customer.accounts[i];

      if (
        account.account === "" &&
        account.password === "" &&
        account.type === ""
      ) {
        customer.accounts.pop();
      }
    }

    return await supabase.from(DB_NAME).upsert(customer).select();
  }

  static async getTopKNewestCustomers(k) {
    return await supabase
      .from(DB_NAME)
      .select(
        `
            id,
            created_at,
            name,
            merchant_id,
            owner_fname,
            owner_lname,
            contact_store,
            contact_owner,
            contact_owner2,
            email,
            email2,
            rep (
              id,
              first_name,
              last_name
            ),
            platform (
              id,
              value,
              inactive
            )
        `,
      )
      .eq("inactive", false)
      .order("created_at", { ascending: false })
      .limit(k);
  }
}
