import React, { useContext, useEffect, useRef } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { SideNavBar } from "./SideNavBar";
import { supabase } from "utils/supabaseClient";
import { Toast } from "primereact/toast";
import { AppContext } from "AppContext";

export const MainHome = () => {
  const navigate = useNavigate();
  const toastRef = useRef(null);
  const { setToastRef } = useContext(AppContext);
  // Non user shouldn't have any access beyond this point.
  useEffect(() => {
    const checkUserAuthentication = async () => {
      const {
        data: { user },
      } = await supabase.auth.getUser();
      const {
        data: { session },
      } = await supabase.auth.getSession();
      if (user == null || session == null) {
        navigate("/");
      }
    };
    checkUserAuthentication();
    setToastRef(toastRef);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="w-100 flex">
      <div className="sticky top-0 z-10 h-screen">
        <SideNavBar />
      </div>
      <div className="w-100 overflow-y-hidden">
        <Toast ref={toastRef} position="top-right" />
        {/* top nav bar for mobile view */}
        <div className="h-[60px] max-w-screen-xl bg-[#1d2026] lg:hidden"></div>
        <Outlet />
      </div>
    </div>
  );
};
