import moment from "moment";
import { supabase } from "utils/supabaseClient";

const DB_NAME = "event";

export default class Event {
    static async getAllData() {
        return await supabase
            .from(DB_NAME)
            .select(`*`)
            .order("created_at", { ascending: true });
    }

    static async getEventById(id) {
        return await supabase
            .from(DB_NAME)
            .select(`*`)
            .eq("id", id);
    }

    static async upsertEvent(event) {
        if (event.customer === "") {
            event.customer = null;
        }

        if (event.service === "") {
            event.service = null;
        }

        event.start = moment(event.start).utc();
        event.end = moment(event.end).utc();

        return await supabase.from(DB_NAME).upsert(event).select();
    }

    static async deleteEventById(id) {
        return await supabase
            .from(DB_NAME)
            .delete()
            .eq("id", id);
    }
}
