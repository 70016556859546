import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Device from "db/device";
import DropdownField from "db/dropdownField";
import { useGetCurrentEmployeeInfo } from "hooks/useGetCurrentEmployee";
import { useToast } from "hooks/useToast";

const STATUS_IN_STOCK = 1;

export const InventoryTab = () => {
  const [rows, setRows] = useState([]);
  const [settingFields, setSettingFields] = useState({});
  const [checkAll, setCheckAll] = useState(false);
  const [activeModels, setActiveModels] = useState([]);
  const [activeEncryptions, setActiveEncryptions] = useState([]);
  const { is_admin, can_edit_inventory, loading } = useGetCurrentEmployeeInfo();
  const { showSuccess, showError } = useToast();
  const newRowInitialVal = {
    checked: checkAll, // when adding new row, if checkAll is checked, then new row will be checked.
    model: "",
    serial_number: "",
    order_number: "",
    purchase_date: "",
    encryption: "",
    status: STATUS_IN_STOCK, // Upon receipt, automatically set to IN STOCK
    inactive: false,
    assigned_number: 0,
  };

  const fetchActiveModels = async () => {
    const { data } = await DropdownField.getActiveDataByType("Device Model");
    if (data) setActiveModels(data);
  };

  const fetchActiveEncryptions = async () => {
    const { data } = await DropdownField.getActiveDataByType("Encryption");
    if (data) setActiveEncryptions(data);
  };

  useEffect(() => {
    fetchActiveModels();
    fetchActiveEncryptions();
  }, []);

  const handleSettingFields = (e) => {
    const { name, value } = e.target;
    setSettingFields({
      ...settingFields,
      [name]: value,
    });
  };

  const handleInputChange = (index) => (e) => {
    const { name, value, checked } = e.target;
    var copyRows = [...rows];

    if (name === "checked") {
      if (!checked) {
        setCheckAll(checked);
      }
      copyRows[index][name] = checked;
    } else if (name === "checkAll") {
      setCheckAll(checked);
      for (var rowIndex in copyRows) {
        copyRows[rowIndex]["checked"] = checked;
      }
    } else {
      copyRows[index][name] = value;
    }

    setRows(copyRows);
  };

  const columns = [
    {
      key: "checked",
      header: (
        <input
          type="checkbox"
          className="h-5 w-5 rounded border-gray-300 bg-gray-100 text-blue-600"
          name="checkAll"
          onChange={handleInputChange(-1)}
          checked={checkAll}
        />
      ),
      cell: (row, prop) => (
        <input
          type="checkbox"
          className="h-5 w-5 rounded border-gray-300 bg-gray-100 text-blue-600"
          name="checked"
          onChange={handleInputChange(prop.rowIndex)}
          checked={row.checked}
        />
      ),
    },
    { key: "order_number", header: "Order #" },
    { key: "purchase_date", header: "Purchase Date" },
    { key: "model.value", header: "Model", cell: (row) => activeModels.filter(m => m.id === Number(row.model))[0]?.value },
    { key: "encryption.value", header: "Encryption", cell: (row) => activeEncryptions.filter(m => m.id === Number(row.encryption))[0]?.value },
    { key: "serial_number", header: "Serial #" },
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (rows.length === 0) {
      showError("Error", "Please add device(s).");
      return;
    }

    const data = await Device.bulkInsertDevice(rows);

    if (data.data) {
      setRows([]);
      showSuccess("Creation successful!", "New customer was created.");
    }

    if (data.error) {
      showError("Error", data.error.message);
    }
  };

  const addRow = (e) => {
    e.preventDefault();

    var newRow = newRowInitialVal;

    for (var elementName in settingFields) {
      newRow[elementName] = settingFields[elementName];
    }

    setRows([...rows, newRow]);
    setSettingFields({ ...settingFields, serial_number: "" });
  };

  return (
    loading ? <></> :
      is_admin || can_edit_inventory ? (
        <div className="m-2 lg:m-5">
          <div className="mb-2 flex items-center justify-between gap-3">
            <div className="flex items-center gap-3">
              <h1 className="title mb-0">Inventory Receive</h1>
              <button
                type="submit"
                disabled={!is_admin && !can_edit_inventory}
                title={
                  !is_admin && !can_edit_inventory
                    ? "You need admin or edit access to add devices"
                    : null
                }
                onClick={handleSubmit}
                className="flex items-center gap-2 rounded-full border-2 bg-blue-700 px-6 py-2 text-white hover:bg-blue-800 disabled:bg-gray-300"
              >
                Save {rows.length} Devices
              </button>
            </div>
          </div>
          <form
            className="grid grid-cols-12 mb-2 items-center justify-between gap-1 md:gap-2 bg-gray-200 p-1 md:p-2"
            onSubmit={addRow}
          >
            <div className="col-span-6 sm:col-span-2">
              <input
                type="text"
                className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 shadow-sm focus:border-blue-600 focus:ring-blue-600"
                placeholder="Order #"
                name="order_number"
                required={true}
                value={!settingFields.order_number ? "" : settingFields.order_number}
                onChange={handleSettingFields}
              />
            </div>
            <div className="col-span-6 sm:col-span-2">
              <input
                type="date"
                className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 shadow-sm focus:border-blue-600 focus:ring-blue-600"
                name="purchase_date"
                required={true}
                value={!settingFields.purchase_date ? "" : settingFields.purchase_date}
                onChange={handleSettingFields}
              />
            </div>
            <div className="col-span-6 sm:col-span-2">
              <select
                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5"
                name="model"
                value={!settingFields?.model ? "" : settingFields.model.id}
                onChange={handleSettingFields}
                required={true}
              >
                <option value="">Select Model</option>
                {activeModels.map((m) => {
                  return (
                    <option key={m.id} value={m.id}>{m.value}</option>
                  );
                })}
              </select>
            </div>
            <div className="col-span-6 sm:col-span-2">
              <select
                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5"
                name="encryption"
                value={!settingFields?.encryption ? "" : settingFields.encryption}
                onChange={handleSettingFields}
                required={true}
              >
                <option value="">Select Encryption</option>
                {activeEncryptions.map((m) => {
                  return (
                    <option key={m.id} value={m.id}>{m.value}</option>
                  );
                })}
              </select>
            </div>
            <div className="col-span-6 sm:col-span-2">
              <input
                type="text"
                className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 shadow-sm focus:border-blue-600 focus:ring-blue-600"
                placeholder="Serial #"
                name="serial_number"
                required={true}
                value={!settingFields.serial_number ? "" : settingFields.serial_number}
                onChange={handleSettingFields}
              />
            </div>
            <div className="flex items-center justify-end sm:items-start sm:justify-start col-span-6 sm:col-span-2">
              <button
                type="submit"
                className="flex items-center gap-2 rounded-full border-2 bg-blue-700 px-3 md:px-5 py-2 text-white hover:bg-blue-800"
              >
                Add
              </button>
              <button
                type="button"
                className="flex items-center gap-2 rounded-full border-2 bg-red-700 px-2 md:px-5 py-2 text-white hover:bg-red-800"
                onClick={() => { setRows(rows.filter((r) => r.checked === false)); }}
              >
                Remove
              </button>
            </div>
          </form>
          <div className="h-[65vh] md:h-[80vh] border shadow-md">
            <DataTable
              value={rows}
              stripedRows
              size="normal"
              tableStyle={{ width: "100%" }}
              scrollable
              scrollHeight="flex"
              rowHover={true}
              emptyMessage="No data found."
            >
              {columns.map((col, index) => {
                return (
                  <Column
                    key={index}
                    field={col.key}
                    header={col.header}
                    headerClassName="py-2 px-2 text-xs whitespace-nowrap"
                    bodyClassName="text-xs py-3 px-2 align-top"
                    body={col.cell}
                    style={{ minWidth: col.minWidth }}
                    showClearButton={true}
                  />
                );
              })}
            </DataTable>
          </div>
        </div>
      ) : <div>You don't have the permission. Please contact Admin.</div>
  );
};
