import React, { useEffect, useState } from "react";
import Audit from "db/audit";
import { convertUTCToESTFormatted } from "utils/utils";

// Data created by Supabase trigger
// On update entity, it will create a row in audit table with old, new changes. Check supabase trigger for more details.

export const CommonHistoryComponent = ({ entityType, entityID }) => {
  const [historyList, setHistoryList] = useState([]);
  const [creationDate, setCreationDate] = useState("");

  const fetchHistoryOnCurrentEntity = async () => {
    const { data } = await Audit.getAuditsByEntityTypeAndID(
      entityType,
      entityID,
    );
    if (data) {
      setHistoryList(data);
    }
  };

  const fetchCreationDateOnCurrentEntity = async () => {
    const { data } = await Audit.getCreationDateByEntityTypeAndID(
      entityType,
      entityID,
    );
    if (data) {
      setCreationDate(data[0].created_at);
    }
  };

  useEffect(() => {
    fetchHistoryOnCurrentEntity();
    fetchCreationDateOnCurrentEntity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="relative flex flex h-full flex-col items-center space-y-6 rounded-b-lg bg-white p-6 shadow">
      <div className="no-scrollbar flex h-auto w-full flex-col items-center gap-3 overflow-y-auto sm:w-3/4 lg:w-3/4">
        {historyList.map((history, index) => {
          return (
            <div key={index} className="w-full rounded-xl bg-gray-100 p-3">
              <div className="flex-col">
                <div className="mb-3 flex items-center justify-between font-medium">
                  <div className="flex items-center gap-2">
                    <img
                      className="h-8 w-8 rounded-full sm:h-10 sm:w-10"
                      src={`https://ui-avatars.com/api/?name=${history.employee_id.first_name}+${history.employee_id.last_name}&rounded=true&background=random`}
                      alt="Rounded avatar"
                    />
                    <p className="m-0 text-sm sm:text-base">
                      {history.employee_id.first_name +
                        " " +
                        history.employee_id.last_name}
                    </p>
                  </div>
                  <p className="text-xs sm:text-base">
                    {convertUTCToESTFormatted(history.created_at)}
                  </p>
                </div>
                <div>
                  <p className="m-0 text-sm sm:text-base">
                    {history.employee_id.first_name +
                      " " +
                      history.employee_id.last_name}{" "}
                    edited the following fields:
                  </p>
                </div>
                <div className="flex justify-center text-sm sm:text-base">
                  <table className="w-full table-fixed">
                    <thead>
                      <tr>
                        <th className="w-1/4"></th>
                        <th>Previous</th>
                        <th>New</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(history?.changed_field?.split(",") ?? []).map(
                        (cf, index) => {
                          const oldValues = history?.old_value?.split(",");
                          const newValues = history?.new_value?.split(",");

                          if (oldValues == null || newValues == null) {
                            return;
                          }

                          return (
                            <tr key={index}>
                              <th className="no-scrollbar w-1/4 overflow-x-scroll">
                                {cf}
                              </th>
                              <td className="no-scrollbar overflow-x-scroll whitespace-normal break-all pr-3">
                                {oldValues[index] === "" ||
                                  oldValues[index] === "field_empty" ? (
                                  <p className="mb-0 text-gray-400">EMPTY</p>
                                ) : (
                                  oldValues[index]
                                )}
                              </td>
                              <td className="no-scrollbar overflow-x-scroll whitespace-normal break-all">
                                {newValues[index] === "" ||
                                  newValues[index] === "field_empty" ? (
                                  <p className="mb-0 text-gray-400">EMPTY</p>
                                ) : (
                                  newValues[index]
                                )}
                              </td>
                            </tr>
                          );
                        },
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          );
        })}
        <div className="w-full rounded-xl bg-gray-100 p-3">
          <div className="flex-col">
            🎈 This {entityType} was created on{" "}
            {convertUTCToESTFormatted(creationDate)}
          </div>
        </div>
      </div>
    </div>
  );
};
