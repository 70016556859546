import { Auth } from "@supabase/auth-ui-react";
import { ThemeSupa } from "@supabase/auth-ui-shared";
import React from "react";
import { useNavigate } from "react-router-dom";
import { supabase } from "utils/supabaseClient";

export const PasswordReset = () => {
  const navigate = useNavigate();
  supabase.auth.onAuthStateChange((event) => {
    if (event === "USER_UPDATED") {
      navigate("/");
    }
  });
  return (
    <div
      className="w-1/4 px-3"
      style={{
        boxShadow: "2px 2px 15px black, -2px -2px 15px black",
      }}
    >
      <Auth
        supabaseClient={supabase}
        appearance={{
          theme: ThemeSupa,
          variables: {
            default: {
              colors: {
                inputLabelText: "white",
              },
            },
          },
        }}
        providers={[]}
        theme="dark"
        view="update_password"
        localization={{
          variables: {
            sign_in: {
              email_label: "Email Address",
              password_label: "Password",
              email_input_placeholder: "",
              password_input_placeholder: "",
            },
            sign_up: {
              link_text: "",
            },
          },
        }}
      />
    </div>
  );
};
