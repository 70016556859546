import React from "react";
import { Outlet } from "react-router-dom";
import { useGetCurrentEmployeeInfo } from "hooks/useGetCurrentEmployee";

export const SettingTab = () => {
  const { is_admin, loading } = useGetCurrentEmployeeInfo();

  return (
    loading ? <></> :
      is_admin ? (
        <div>
          <Outlet />
        </div>
      ) : <div>You don't have the permission. Please contact Admin.</div>
  );
};
