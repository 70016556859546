import { supabase } from "utils/supabaseClient";

const DB_NAME = "dropdown field";

export default class DropdownField {
  static async getAllData() {
    return await supabase
      .from(DB_NAME)
      .select("*")
      .order("id", { ascending: true })
  }

  static async getDrowdownFieldByID(id) {
    return await supabase
      .from(DB_NAME)
      .select("*")
      .eq("id", id);
  }


  static async getDataByType(type) {
    return await supabase
      .from(DB_NAME)
      .select("*")
      .eq("type", type);
  }

  static async getActiveDataByType(type) {
    return await supabase
      .from(DB_NAME)
      .select("*")
      .match({ "type": type, "inactive": false });
  }

  static async upsertDropdown(dropdownData) {
    return await supabase.from(DB_NAME).upsert(dropdownData).select();
  }
}
