import React, { useContext } from "react";
import { StatusBadgeWithColor, convertUTCToESTFormatted } from "utils/utils";
import Device from "db/device";
import { CommonTable } from "./CommonTable";
import { useGetCurrentEmployeeInfo } from "hooks/useGetCurrentEmployee";
import { useInfiniteLoading } from "hooks/useInfiniteLoading";
import { AppContext } from "AppContext";

const tableColumns = [
  { key: "id", header: "#" },
  {
    key: "id",
    header: "Device",
    cell: (row) => {
      return (
        <div className="flex flex-col gap-3">
          <div className="flex gap-2 whitespace-nowrap">
            <StatusBadgeWithColor status={row?.status?.value} />
            <p className="text-sm font-bold ">
              {row?.serial_number} ({row?.model?.value})
            </p>
          </div>
          <div className="flex gap-2">
            <p className="font-semibold">Customer:</p>{" "}
            {row?.customer?.name || "N/A"}
          </div>
          <div className="flex gap-2">
            <p className="font-semibold">Encryption:</p>{" "}
            {row?.encryption?.value || ""}
          </div>
        </div>
      );
    },
  },
  {
    key: "order_number",
    header: "Order Info",
    cell: (row) => {
      return (
        <div className="flex flex-col gap-3">
          <div className="flex gap-2 whitespace-nowrap">
            <p className="font-semibold">Purchased On:</p>{" "}
            {row?.purchase_date || "N/A"}
          </div>
          <div className="flex gap-2 whitespace-nowrap">
            <p className="font-semibold">Order #:</p>{" "}
            {row?.order_number || "N/A"}
          </div>
        </div>
      );
    },
  },
  {
    key: "created_at",
    header: "Created Date",
    cell: (row) => {
      return (
        <div className=" whitespace-nowrap">
          {convertUTCToESTFormatted(row.created_at)}
        </div>
      );
    },
  },
];

export const DeviceTab = () => {
  const { is_admin, can_edit_inventory } = useGetCurrentEmployeeInfo();
  const { deviceTableFilter } = useContext(AppContext);

  const {
    items,
    hasMore,
    loadNext,
    loadingNext,
    triggerReload,
    filters,
    setFilters,
    initialPageLoaded,
  } = useInfiniteLoading({
    getItemsLazy: (start, end, lazyFilters) => {
      return Device.getDevicesWithRange(start, end, lazyFilters);
    },
    filterValues: deviceTableFilter || {
      searchInput: "",
      customer: null,
      deviceStatus: null,
    },
    name: "device",
  });

  return (
    <div className="m-2 lg:m-5">
      <CommonTable
        tableName="Devices"
        tableColumns={tableColumns}
        tableRows={items}
        requireCreateButton={false}
        requireSearch={true}
        enableInsert={is_admin || can_edit_inventory}
        lazyLoadRows={loadNext}
        lazyLoading={loadingNext}
        lazyHasMoreData={hasMore}
        refetchRows={triggerReload}
        tableFilter={filters}
        setTableFilter={setFilters}
        initialPageLoaded={initialPageLoaded}
      />
    </div>
  );
};
