// General utils file for the app.
import React from "react";
import { FaTh, FaUserAlt } from "react-icons/fa";
import { AiOutlineCalendar, AiFillSetting } from "react-icons/ai";
import { MdInventory2, MdSupportAgent, MdDevices } from "react-icons/md";
import { supabase } from "utils/supabaseClient";
import { Tag } from "primereact/tag";

export const isDev = () => {
  return !process.env.NODE_ENV || process.env.NODE_ENV === "development";
};

export const StatusBadgeWithColor = ({ status }) => {
  if (status == null) {
    return;
  }
  // chatGPTed function to create color hash.
  const stringToColor = (str) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    const hexColor = (hash & 0x00ffffff).toString(16).toUpperCase();
    return `#${"00000".substring(0, 6 - hexColor.length)}${hexColor}`;
  };

  const color = stringToColor(status);

  return (
    <Tag
      className={`h-[18px] w-20 whitespace-nowrap px-1 py-0 text-xs`}
      value={status}
      style={{ backgroundColor: color }}
    ></Tag>
  );
};

export const EntityHotSpot = ({ has_hotspot }) => {
  return !has_hotspot ? (
    ""
  ) : (
    <Tag
      className="h-[18px] bg-red-600 px-1 py-0 text-white"
      value="Hot Spot"
    ></Tag>
  );
};

export const EntityPlatform = ({ platform }) => {
  return !platform ? (
    ""
  ) : platform === "TSYS" ? (
    <Tag
      className="h-[18px] bg-[#003C71] px-1 py-0 text-white"
      value={platform}
    ></Tag>
  ) : platform === "FD" ? (
    <Tag
      className="h-[18px] bg-[#FF6600] px-1 py-0 text-white"
      value={platform}
    ></Tag>
  ) : platform.includes("PPS") ? (
    <Tag
      className="h-[18px] bg-[#62BD98] px-1 py-0 text-white"
      value={platform}
    ></Tag>
  ) : (
    <Tag
      className="h-[18px] bg-black px-1 py-0 text-white"
      value={platform}
    ></Tag>
  );
};

export const EntitySimple = ({ value }) => {
  return !value ? (
    ""
  ) : (
    <Tag className="h-[18px] bg-black px-1 py-0 text-white" value={value}></Tag>
  );
};

export const EntityStatusSmall = ({ inactive }) => {
  return inactive === false ? (
    <Tag
      className="h-[18px] bg-green-300 px-1 py-0 text-green-700"
      severity="success"
      value="Active"
    ></Tag>
  ) : (
    <Tag
      className="h-[18px] bg-red-300 px-1 py-0 text-red-700"
      severity="danger"
      value="Inactive"
    ></Tag>
  );
};

export const EntityStatus = ({ inactive }) => {
  return inactive === false ? (
    <span className="text-md mr-2 rounded bg-green-100 px-2.5 py-0.5 font-medium text-green-800">
      Active
    </span>
  ) : (
    <span className="text-md mr-2 rounded bg-red-100 px-2.5 py-0.5 font-medium text-red-800">
      Inactive
    </span>
  );
};

export const EntityBool = ({ isTrue }) => {
  return isTrue ? (
    <span className="text-md mr-2 rounded bg-green-100 px-2.5 py-0.5 font-medium text-green-800">
      Yes
    </span>
  ) : (
    <span className="text-md mr-2 rounded bg-red-100 px-2.5 py-0.5 font-medium text-red-800">
      No
    </span>
  );
};

export const redirectBaseUrl = () => {
  if (isDev()) {
    return "http://localhost:3000";
  } else {
    return "https://pos-connect-crm.vercel.app";
  }
};

export const SIDE_NAV_MENU_ITEMS = [
  {
    path: "/home/dashboard",
    name: "Dashboard",
    icon: <FaTh size={25} />,
    admin_only: false,
  },
  {
    path: "/home/customer",
    name: "Customer",
    icon: <FaUserAlt size={25} />,
    admin_only: false,
  },
  {
    path: "/home/device",
    name: "Device",
    icon: <MdDevices size={25} />,
    admin_only: false,
  },
  {
    path: "/home/inventory",
    name: "Inventory",
    icon: <MdInventory2 size={25} />,
    admin_only: false,
    need_inventory_permission: true,
  },
  {
    path: "/home/service",
    name: "Service",
    icon: <MdSupportAgent size={25} />,
    admin_only: false,
  },
  {
    path: "/home/calendar",
    name: "Calendar",
    icon: <AiOutlineCalendar size={25} />,
    admin_only: false,
  },
  {
    path: "/home/setting",
    name: "Setting",
    icon: <AiFillSetting size={25} />,
    admin_only: true,
    subtabs: [
      {
        name: "Employee",
        path: "employee",
      },
      {
        name: "Dropdown",
        path: "dropdown-management",
      },
      {
        name: "License",
        path: "license"
      }
    ],
  },
];

// This is to handle tab changes, or copy pasting url into another tab.
// We should get the input url and persist on users view.
export const getRouteFromCurrentUrl = () => {
  const current = window.location.href;
  for (const item of SIDE_NAV_MENU_ITEMS) {
    if (current.includes(item.path)) {
      return item.path;
    }
  }
  return SIDE_NAV_MENU_ITEMS[0].path;
};

export const getModalType = (modalType) => {
  return modalType === "add"
    ? "Add"
    : modalType === "edit"
      ? "Edit"
      : modalType === "view"
        ? "View"
        : "Error";
};

export const getCurrentUser = async () => {
  const {
    data: { user },
  } = await supabase.auth.getUser();
  return user;
};

export const openGoogleMapToAddress = (toLocation) => {
  if (!toLocation || toLocation === "") return;

  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(success, error);
  } else {
    console.log("Geolocation not supported");
  }

  function success(position) {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;
    window.open(
      `https://www.google.com/maps/dir/${latitude},${longitude}/${toLocation.replace(" ", "+")}`,
    );
  }

  function error() {
    console.log("Unable to retrieve your location");
  }
};

export const convertUTCToESTFormatted = (timestampz) => {
  const datetime = new Date(timestampz);
  return datetime.toLocaleString("en-US");
};

export const formatPhoneNumber = (phoneNumber) => {
  const numericOnly = phoneNumber.replace(/\D/g, "");
  if (numericOnly.length < 1) {
    return "";
  } else if (numericOnly.length < 4) {
    return `(${numericOnly.slice(0, 3)}`;
  } else if (numericOnly.length < 7) {
    return `(${numericOnly.slice(0, 3)})-${numericOnly.slice(3, 6)}`;
  } else {
    return `(${numericOnly.slice(0, 3)})-${numericOnly.slice(3, 6)}-${numericOnly.slice(6, 10)}`;
  }
};

export const generateLicenseKey = () => {
  const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  const sections = 5;
  const sectionLength = 4;

  return [...Array(sections)].map(() =>
    chars.slice(0, sectionLength).split('').map(() => chars[Math.floor(Math.random() * chars.length)]).join('')
  ).join('-');
}

export const getStates = () => {
  return [
    "AL",
    "AK",
    "AZ",
    "AR",
    "CA",
    "CO",
    "CT",
    "DE",
    "FL",
    "GA",
    "HI",
    "ID",
    "IL",
    "IN",
    "IA",
    "KS",
    "KY",
    "LA",
    "ME",
    "MD",
    "MA",
    "MI",
    "MN",
    "MS",
    "MO",
    "MT",
    "NE",
    "NV",
    "NH",
    "NJ",
    "NM",
    "NY",
    "NC",
    "ND",
    "OH",
    "OK",
    "OR",
    "PA",
    "RI",
    "SC",
    "SD",
    "TN",
    "TX",
    "UT",
    "VT",
    "VA",
    "WA",
    "WV",
    "WI",
    "WY",
  ];
};
