import { supabase } from "utils/supabaseClient";

const DB_NAME = "audit";

export default class Audit {
  static async getAuditsByEntityTypeAndID(type, id) {
    return await supabase
      .from(DB_NAME)
      .select(
        `id, created_at, changed_field, old_value, new_value, employee_id (first_name, last_name, id)`
      )
      .eq("entity_type", type)
      .eq("entity_id", id)
      .order("created_at", { ascending: false });
  }

  static async getCreationDateByEntityTypeAndID(type, id) {
    return await supabase.from(type).select(`created_at`).eq("id", id);
  }

  static async upsertAudit(audit) {
    return await supabase.from(DB_NAME).upsert(audit).select();
  }
}
