/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useNavigate } from "react-router-dom";

export const MiniTable = ({ tableColumns, tableRows, type }) => {
  const navigate = useNavigate();

  return (
    <div className="w-full overflow-x-auto">
      <div className="relative h-[70vh] overflow-auto shadow-md sm:rounded-lg">
        <table className="text-md w-full  text-left text-gray-700 ">
          <thead className="text-s bg-gray-50 text-gray-800">
            <tr className="border-b-2 border-gray-500">
              {tableColumns.map((col, index) => {
                return (
                  <th
                    key={col.key}
                    scope="col"
                    className={`px-3 py-3`}
                    style={{
                      minWidth: col.minWidth,
                    }}
                  >
                    {col.header}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {tableRows.map((row, row_index) => {
              return (
                <tr
                  key={row.id}
                  className="border-b border-gray-300 hover:cursor-pointer hover:bg-slate-200"
                  onClick={() => {
                    navigate(`/home/${type}/form?type=edit&id=${row.id}`);
                  }}
                >
                  {tableColumns.map((col, index) => {
                    return (
                      <td key={index} className="px-3 py-3">
                        {col?.cell != null ? col.cell(row) : row[col.key]}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        {(tableRows ?? []).length === 0 && (
          <div className="flex flex-col items-center justify-center overflow-auto py-3">
            <h1 className="mt-4 text-center text-2xl font-semibold text-gray-700">
              No Data Found.
            </h1>
            <p className="mt-2 text-center text-gray-500">
              If you want to add a data, please go to the appropriate tab and
              create new associated data.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};
