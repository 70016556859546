import React, { useState, useEffect } from "react";
// import { Calendar } from "primereact/calendar";
import { useNavigate } from "react-router-dom";
import { MdOutlineSubtitles } from "react-icons/md";
import { AiOutlineClose, AiOutlineInfoCircle } from "react-icons/ai";
import { BsDashLg, BsCardText } from "react-icons/bs";
import { BiTimeFive } from "react-icons/bi";
import { FiUsers } from "react-icons/fi";
import { IoLocationOutline } from "react-icons/io5";
import Event from "db/event";
import { getCurrentUser, openGoogleMapToAddress } from "utils/utils";
import { useGetCurrentEmployeeInfo } from "hooks/useGetCurrentEmployee";
import { useToast } from "hooks/useToast";
import { Dropdown } from "primereact/dropdown";
import { useGetFrequentlyUsedValues } from "hooks/useGetFrequentlyUsedValues";
import { MultiSelect } from "primereact/multiselect";
import moment from "moment";

const EVENT_ENUM = {
  "service": "Service",
  "timeoff": "Time Off",
  "holiday": "Holiday",
  "birthday": "Birthday"
};

export const CalendarModal = ({
  resetModal,
  isModalOpen,
  fetchEvents
}) => {
  const [event, setEvent] = useState({});
  const { Employees, Customers } = useGetFrequentlyUsedValues({});
  const { employee_id, is_admin } = useGetCurrentEmployeeInfo();
  const { showSuccess, showError } = useToast();
  const navigate = useNavigate();
  const eventType = isModalOpen.type;

  const setEventField = async () => {
    var user = await getCurrentUser();
    setEvent({
      ...event,
      creator: user.id,
      assigned:
        eventType === "timeoff" && isModalOpen.modalType === "add"
          ? [user.id]
          : [],
      start: moment(isModalOpen.start).format().substring(0, 16),
      end: moment(isModalOpen.end).format().substring(0, 16),
      type: eventType,
      completed: false
    });
  };

  const fetchEventById = async () => {
    if (!isModalOpen.id.toString().includes("hd")) {
      const { data } = await Event.getEventById(isModalOpen.id);
      if (data.length > 0) {
        data[0].start = moment(data[0].start).format().substring(0, 16);
        data[0].end = moment(data[0].end).format().substring(0, 16);

        setEvent(data[0]);
      } else {
        if (data.length === 0) {
          resetModal();
          navigate(-1);
          showError("Error", "Event not found.");
        }
      }
    } else {
      setEvent(isModalOpen.currentEvent);
    }
  };

  useEffect(() => {
    if (isModalOpen.modalType === "edit") {
      fetchEventById();
    } else {
      setEventField();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInputChange = (e) => {
    const { name, value, checked } = e.target;

    if (name === "completed") {
      setEvent({
        ...event,
        [name]: checked,
      });
    } else if (name === "assigned") {
      setEvent({
        ...event,
        [name]: value,
      });
    } else if (name === "customer") {
      var fullAddress = "";
      if (value) {
        const selected = Customers.filter((c) => c.id === value);
        fullAddress = `${selected[0].address}, ${selected[0].city}, ${selected[0].state}, ${selected[0].zip}`;
      }

      setEvent({
        ...event,
        [name]: value === undefined ? "" : value,
        address: fullAddress,
      });
    } else if (["start", "end"].indexOf(name) !== -1) {
      setEvent({
        ...event,
        [name]: value
      });
    } else {
      setEvent({
        ...event,
        [name]: value,
      });
    }
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    const data = await Event.deleteEventById(event.id);

    if (data.error) {
      console.log(data.error);
      showError();
    } else {
      showSuccess("Success!", "Event is deleted.",);
    }

    fetchEvents();
    resetModal();
    navigate(-1);
  }

  const onFormSubmit = async (e) => {
    e.preventDefault();
    const data = await Event.upsertEvent(event);

    if (data.data) {
      showSuccess("Success!", "Event is created.",);
    }
    if (data.error) {
      console.log(data.error);
      showError();
    }

    fetchEvents();
    resetModal();
    if (isModalOpen.modalType === "edit") navigate('../../home/calendar');
  };

  return (
    <div
      aria-hidden="true"
      className={`fixed left-0 right-0 top-0 z-50 flex h-screen w-screen items-center justify-center overflow-y-auto overflow-x-hidden bg-black bg-opacity-25 p-4`}
    >
      <div className="relative max-h-full">
        <form
          className="relative rounded-b-lg bg-white shadow"
          onSubmit={onFormSubmit}
        >
          <div className="relative flex items-start justify-between rounded-t-lg border-b bg-white p-2">
            <div className="block w-full px-3 pt-1 text-xl font-semibold">
              <h4>Event - {EVENT_ENUM[eventType]}</h4>
            </div>
            <button
              type="button"
              className="ml-auto inline-flex h-8 w-8 items-center justify-center rounded-lg bg-transparent text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900"
              onClick={() => {
                resetModal();
                if (isModalOpen.modalType === "edit") navigate('../../home/calendar');
              }}
            >
              <AiOutlineClose size={24} />
            </button>
          </div>
          <div className="space-y-6 p-6">
            <div className="grid grid-cols-12 items-center gap-6">
              <div className="col-span-1">
                <MdOutlineSubtitles size={24} />
              </div>
              <div className={eventType === "service" ? "col-span-8" : "col-span-11"}>
                <input
                  type="text"
                  className="mr-5 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-xl font-semibold text-gray-900 shadow-sm focus:border-blue-600 focus:ring-blue-600"
                  placeholder="Title"
                  required={true}
                  name="title"
                  value={!event?.title ? "" : event.title}
                  onChange={handleInputChange}
                />
              </div>
              {eventType === "service" && <div className="col-span-3">
                <input
                  type="checkbox"
                  className="h-5 w-5 rounded border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500"
                  name="completed"
                  onChange={handleInputChange}
                  checked={!event.completed ? false : event.completed}
                />
                <label className="ml-2 text-sm font-medium text-gray-900">
                  Completed?
                </label>
              </div>
              }
            </div>
            {["timeoff", "holiday", "birthday"].indexOf(isModalOpen.type) !== -1 ? (
              ""
            ) : (
              <div className="grid grid-cols-12 items-center gap-6">
                <div className="col-span-1">
                  <AiOutlineInfoCircle size={24} />
                </div>
                <div className="col-span-5">
                  <label className="mb-2 block text-sm font-medium text-gray-900">
                    Creator
                  </label>
                  <select
                    className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 shadow-sm focus:border-blue-600 focus:ring-blue-600"
                    required={true}
                    name="creator"
                    value={event?.creator == null ? "" : event.creator}
                    onChange={handleInputChange}
                    disabled
                  >
                    <option value="" disabled>
                      Select Creator
                    </option>
                    {Employees.map((e) => {
                      return (
                        <option key={e.id} value={e.id}>
                          {e.first_name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="col-span-6">
                  <label className="mb-2 block text-sm font-medium text-gray-900">
                    Customer
                  </label>
                  <Dropdown
                    value={event?.customer == null ? "" : event.customer}
                    onChange={handleInputChange}
                    placeholder="Select Customer"
                    name="customer"
                    options={Customers.filter(c => !c.inactive || event?.customer?.id === c.id)}
                    optionLabel="name"
                    optionValue="id"
                    showClear
                    valueTemplate={(option, props) => {
                      if (option) {
                        return <div>{option?.name}</div>;
                      }

                      return <span>{props.placeholder}</span>;
                    }}
                    itemTemplate={(option) => {
                      return <div>{option?.name}</div>;
                    }}
                    scrollHeight="300px"
                    filter
                    className="w-full rounded-lg border border-gray-300 bg-gray-50 text-sm text-gray-900 shadow-sm focus:border-blue-600 focus:ring-blue-600"
                  />
                  {/* <select
                    className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 shadow-sm focus:border-blue-600 focus:ring-blue-600"
                    required={false}
                    name="customer"
                    value={event?.customer == null ? "" : event.customer}
                    onChange={handleInputChange}
                  >
                    <option value="">Select Customer</option>
                    {activeCustomers.map((c) => {
                      return (
                        <option key={c.id} value={c.id}>
                          {c.name}
                        </option>
                      );
                    })}
                  </select> */}
                </div>
              </div>
            )}
            <div className="grid grid-cols-12 items-center gap-6">
              <div className="col-span-1">
                <BiTimeFive size={24} />
              </div>
              <div className="col-span-5">
                <input
                  type="datetime-local"
                  name="start"
                  onChange={handleInputChange}
                  required
                  value={!event.start ? "" : event.start}
                  className="w-full rounded-lg border text-center border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 shadow-sm focus:border-blue-600 focus:ring-blue-600"
                />
                {/* <Calendar
                  id="calendar-12h"
                  className="w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 shadow-sm focus:border-blue-600 focus:ring-blue-600"
                  inputStyle={{
                    textAlign: "center",
                    backgroundColor: "rgb(249 250 251 / var(--tw-bg-opacity))",
                  }}
                  placeholder="Start Date/Time"
                  value={!event.start ? "" : event.start}
                  name="start"
                  onChange={handleInputChange}
                  required
                  showTime
                  hideOnDateTimeSelect
                  hourFormat="12"
                /> */}
              </div>
              <div className="col-span-1">
                <BsDashLg size={24} />
              </div>
              <div className="col-span-5">
                <input
                  type="datetime-local"
                  name="end"
                  onChange={handleInputChange}
                  required
                  value={!event.end ? "" : event.end}
                  className="w-full rounded-lg border text-center border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 shadow-sm focus:border-blue-600 focus:ring-blue-600"
                />
                {/* <Calendar
                  id="calendar-12h"
                  className="w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 shadow-sm focus:border-blue-600 focus:ring-blue-600"
                  inputStyle={{
                    textAlign: "center",
                    backgroundColor: "rgb(249 250 251 / var(--tw-bg-opacity))",
                  }}
                  placeholder="End Date/Time"
                  value={!event.end ? "" : event.end}
                  name="end"
                  onChange={handleInputChange}
                  required
                  showTime
                  hideOnDateTimeSelect
                  hourFormat="12"
                /> */}
              </div>
            </div>
            {["timeoff", "holiday", "birthday"].indexOf(isModalOpen.type) !== -1 ? (
              ""
            ) : (
              <div className="grid grid-cols-12 items-center gap-6">
                <div className="col-span-1">
                  <IoLocationOutline size={24} />
                </div>
                <div className="col-span-9">
                  <input
                    type="text"
                    className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 shadow-sm focus:border-blue-600 focus:ring-blue-600"
                    placeholder="Address"
                    value={!event.address ? "" : event.address}
                    name="address"
                    onChange={handleInputChange}
                  />
                </div>
                <div
                  className="col-span-2"
                  onClick={() =>
                    openGoogleMapToAddress(!event.address ? "" : event.address)
                  }
                >
                  <button
                    type="button"
                    className="w-full rounded-lg bg-blue-700 p-2.5 text-center text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300"
                  >
                    Go
                  </button>
                </div>
              </div>
            )}
            {["holiday", "birthday"].indexOf(isModalOpen.type) !== -1 ? (
              ""
            ) : (
              <div className="grid grid-cols-12 items-center gap-6">
                <div className="col-span-1">
                  <FiUsers size={24} />
                </div>
                <div className="col-span-11">
                  <MultiSelect
                    value={!event.assigned ? [] : event.assigned}
                    onChange={handleInputChange}
                    name="assigned"
                    placeholder="Select Assigned"
                    options={Employees.filter(e => !e.inactive || (!event.assigned ? [] : event.assigned).indexOf(e.id) !== -1)}
                    optionLabel="first_name"
                    optionValue="id"
                    itemTemplate={(option) => option.first_name}
                    filter
                    scrollHeight="300px"
                    className="w-full rounded-lg border border-gray-300 bg-gray-50 text-sm text-gray-900 shadow-sm focus:border-blue-600 focus:ring-blue-600" />
                  {/* <select
                    className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 shadow-sm focus:border-blue-600 focus:ring-blue-600"
                    name="assigned"
                    multiple
                    value={!event.assigned ? [] : event.assigned}
                    onChange={handleInputChange}
                  >
                    {activeEmployees.map((e) => {
                      return (
                        <option key={e.id} value={e.id}>
                          {e.first_name}
                        </option>
                      );
                    })}
                  </select> */}
                </div>
              </div>
            )}
            {["holiday", "birthday"].indexOf(isModalOpen.type) !== -1 ? (
              ""
            ) : (
              <div className="grid grid-cols-12 items-center gap-6">
                <div className="col-span-1">
                  <BsCardText size={24} />
                </div>
                <div className="col-span-11">
                  <textarea
                    rows={3}
                    className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 shadow-sm focus:border-blue-600 focus:ring-blue-600"
                    placeholder="Notes"
                    value={!event.notes ? "" : event.notes}
                    name="notes"
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            )}
          </div>
          {["holiday", "birthday"].indexOf(isModalOpen.type) !== -1 ? (
            ""
          ) : (
            <div className="flex items-center justify-end space-x-2 rounded-b border-t border-gray-200 p-3">
              {isModalOpen.modalType === "edit" &&
                <button
                  type="button"
                  onClick={e => handleDelete(e)}
                  disabled={employee_id !== event?.creator && !is_admin}
                  title={
                    employee_id !== event?.creator
                      ? "Only the creator of this event can edit."
                      : null
                  }
                  className="rounded-lg bg-red-600 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-4 focus:ring-red-300 disabled:bg-gray-300"
                >
                  Delete
                </button>
              }
              <button
                type="submit"
                disabled={employee_id !== event?.creator && !is_admin}
                title={
                  employee_id !== event?.creator
                    ? "Only the creator of this event can edit."
                    : null
                }
                className="rounded-lg bg-blue-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 disabled:bg-gray-300"
              >
                Save
              </button>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};
