import { supabase } from "utils/supabaseClient";

const DB_NAME = "service_device";

export default class ServiceDevice {
  static async getDeviceByServiceID(service_id) {
    return await supabase
      .from(DB_NAME)
      .select(
        `
                id,
                device (
                    id,
                    customer(id, name),
                    serial_number,
                    model(id, value)
                )
            `,
      )
      .eq("service", service_id)
      .order("id", { ascending: false });
  }

  static async getServicesByDeviceID(device_id) {
    return await supabase
      .from(DB_NAME)
      .select(
        `
        service(
              *,
              creator (
                  id,
                  first_name,
                  last_name
              ),
              assigned (
                  id,
                  first_name,
                  last_name
              ),
              customer (
                  id,
                  name
              ),
              device (
                  id,
                  serial_number
              ),
              status (
                  id,
                  value
              )
            )
          `,
      )
      .eq("device", device_id)
      .order("service", { ascending: true });
  }

  static async upsertServiceDevice(service_id, device_id) {
    await supabase.from(DB_NAME).delete().eq("service", service_id);

    var serviceDevice = [];
    device_id.forEach(device => {
      serviceDevice.push({
        service: service_id,
        device: device
      })
    });

    return await supabase.from(DB_NAME).insert(serviceDevice).select();
  }

  static async deleteServiceDeviceByServiceId(id) {
    return await supabase
      .from(DB_NAME)
      .delete()
      .eq("service", id);
  }
}
