import { AppContext } from "AppContext";
import { useCallback, useContext } from "react";

const { useState, useEffect, useRef } = require("react");

const DATA_LOAD_AMOUNT = 50;

export const useInfiniteLoading = ({
  getItemsLazy,
  filterValues,
  name = null,
}) => {
  const {
    setServiceTableFilter,
    setCustomerTableFilter,
    setDeviceTableFilter,
  } = useContext(AppContext);

  const [items, setItems] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [loadingNext, setLoadingNext] = useState(false);
  const [updateCounter, setUpdateCounter] = useState(0);
  const [filters, setFilters] = useState(filterValues);

  const initialPageLoaded = useRef(false);
  const firstIndex = useRef(0);

  const loadNext = useCallback(async () => {
    if (loadingNext || !hasMore) {
      return;
    }
    setLoadingNext(true);

    const { data, count } = await getItemsLazy(
      firstIndex.current,
      firstIndex.current + DATA_LOAD_AMOUNT,
      filters,
    );
    setItems((prevItems) => {
      const appendNewData = [...prevItems, ...data];
      setHasMore(appendNewData.length < count);
      setLoadingNext(false);
      return appendNewData;
    });
    firstIndex.current += DATA_LOAD_AMOUNT + 1;
  }, [filters, getItemsLazy, hasMore, loadingNext]);

  // Initial load
  useEffect(() => {
    if (initialPageLoaded.current) {
      return;
    }

    loadNext();
    initialPageLoaded.current = true;
  }, [loadNext]);

  // Re-fetch when updateCounter changes
  useEffect(() => {
    if (updateCounter > 0) {
      firstIndex.current = 0;
      loadNext();
    }
  }, [updateCounter]);

  // used to persist/cache filter into context when filters change.
  useEffect(() => {
    if (name === "service") {
      setServiceTableFilter(filters);
    }

    if (name === "customer") {
      setCustomerTableFilter(filters);
    }

    if (name === "device") {
      setDeviceTableFilter(filters);
    }
  }, [
    filters,
    name,
    setCustomerTableFilter,
    setDeviceTableFilter,
    setServiceTableFilter,
  ]);

  const triggerReload = () => {
    setItems([]);
    setHasMore(true);
    setLoadingNext(false);
    setUpdateCounter((prevCounter) => prevCounter + 1);

    // This means user saw the new sets of data after all the filters were applied
    // Save this to localStorage with searchInput empty (they dont want search input saved)
    // Reload this when user refreshes/logs in into filter states.
    if (name === "service") {
      localStorage.setItem(
        "serviceTableFilter",
        JSON.stringify({ ...filters, searchInput: "" }),
      );
    }

    if (name === "customer") {
      localStorage.setItem(
        "customerTableFilter",
        JSON.stringify({ ...filters, searchInput: "" }),
      );
    }

    if (name === "device") {
      localStorage.setItem(
        "deviceTableFilter",
        JSON.stringify({ ...filters, searchInput: "" }),
      );
    }
  };

  const handleSetFilter = (newKey, newVal, reset = false) => {
    if (reset) {
      setFilters(
        Object.fromEntries(Object.keys(filters).map((key) => [key, null])),
      );
    } else {
      setFilters((prev) => {
        return { ...prev, [newKey]: newVal };
      });
    }
  };

  return {
    items,
    hasMore,
    loadNext,
    loadingNext,
    triggerReload,
    filters,
    setFilters: handleSetFilter,
    initialPageLoaded: initialPageLoaded.current,
  };
};
