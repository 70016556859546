import React, { useState, useEffect } from "react";
import Customer from "db/customer";
import Device from "db/device";
import Service from "db/service";
import ServiceDevice from "db/service_device";
import { FiEdit } from "react-icons/fi";
import { FaRegComments } from "react-icons/fa";
import { GoHistory } from "react-icons/go";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CommonCommentsComponent } from "../shared/CommonCommentsComponent";
import { CommonHistoryComponent } from "shared/CommonHistoryComponent";
import { convertUTCToESTFormatted, formatPhoneNumber } from "utils/utils";
import { useToast } from "hooks/useToast";
import { useGetCurrentEmployeeInfo } from "hooks/useGetCurrentEmployee";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import { useGetFrequentlyUsedValues } from "hooks/useGetFrequentlyUsedValues";

const initialServiceVal = {
  customer: null,
  contact: "",
  creator: "",
  request: "",
  status: null,
  inactive: false,
  devices: "",
  assigned_arr: [],
  assigned_text: "",
};

const SERVICE_MODAL_TABS = [
  { name: "Main", icon: <FiEdit size={20} /> },
  { name: "Comments", icon: <FaRegComments size={20} /> },
  { name: "History", icon: <GoHistory size={20} /> },
];

export const ServiceForm = () => {
  const [params, setSearchParam] = useSearchParams();
  const formType = params.get("type");
  const id = params.get("id");
  const numOfTabChange = Number(params.get("back")) || 0;
  const navigate = useNavigate();
  const { employee_id, is_admin } = useGetCurrentEmployeeInfo();
  const [service, setService] = useState(initialServiceVal);
  const [serviceDevice, setServiceDevice] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [activeDevices, setActiveDevices] = useState([]);
  const [currentTab, setCurrentTab] = useState(
    params.get("tab") || SERVICE_MODAL_TABS[0].name,
  );
  const { Employees, ServiceStatus, Customers } = useGetFrequentlyUsedValues(
    {},
  );
  const { showSuccess, showError } = useToast();

  const fetchActiveDevices = async (customerId) => {
    const { data } = await Device.getDevicesForService(
      customerId,
      serviceDevice,
    );
    if (data) {
      setActiveDevices(data);
    }
  };

  const fetchCurrentService = async () => {
    const { data } = await Service.getServiceByID(id);

    if (data.length === 0) {
      navigate(-1);
      showError("Error", "Service not found.");
    }
    if (data) setService(data[0]);
  };

  const fetchCurrentServiceDevice = async () => {
    const { data } = await ServiceDevice.getDeviceByServiceID(id);
    if (data) setServiceDevice(data.map((d) => d.device.id));
  };

  const getSelectedCustomer = async (id) => {
    const { data } = await Customer.getCustomerById(id);
    if (data) setSelectedCustomer(data[0]);
  };

  const handleDelete = async () => {
    const data = await ServiceDevice.deleteServiceDeviceByServiceId(id);

    if (data.error) {
      console.log(data.error);
      showError();
    } else {
      const data2 = await Service.deleteService(id);

      if (data2.error) {
        console.log(data.error);
        showError();
      } else {
        showSuccess("Success!", "Service is deleted.");
        navigate(-1 - numOfTabChange);
      }
    }
  };

  useEffect(() => {
    if (formType === "edit") {
      fetchCurrentServiceDevice();
      fetchCurrentService();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (service?.customer?.id || service?.customer) {
      fetchActiveDevices(service?.customer?.id || service?.customer);
      getSelectedCustomer(service?.customer?.id || service?.customer);
    } else {
      setSelectedCustomer(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [service]);

  useEffect(() => {
    if (serviceDevice.length > 0) {
      fetchActiveDevices(service?.customer?.id || service?.customer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceDevice]);

  const handleInputChange = (e) => {
    const { name, value, checked } = e.target;
    if (name === "inactive") {
      setService({
        ...service,
        [name]: checked,
      });
    } else if (name === "contact") {
      setService({
        ...service,
        [name]: formatPhoneNumber(value),
      });
    } else if (name.includes("assigned")) {
      setService({
        ...service,
        assigned_arr: value === null ? [] : value,
        assigned_text: value === null ? "" : Employees.filter((e) => value.indexOf(e.id) !== -1)
          .map((e) => e.first_name)
          .join(" | "),
      });
    } else {
      setService({
        ...service,
        [name]: value === undefined ? "" : value,
      });
      // This allows the user to de-select device, in case they don't necessarily need device associated with service.
      if (name === "device" && value === "") {
        setService({
          ...service,
          device: null,
        });
      }

      if (name === "devices") {
        setServiceDevice(value === null ? [] : value);
        setService({
          ...service,
          devices: value === null ? "" : activeDevices
            .filter((ad) => value.indexOf(ad.id) !== -1)
            .map((m) => m?.serial_number + "(" + m?.model?.value + ")")
            .join(" | "),
        });
      }
    }
  };

  const onFormSubmit = async (e) => {
    e.preventDefault();

    var missing = [];

    if (service.assigned_arr.length === 0) missing.push("assigned");
    if (!service.customer) missing.push("customer");
    if (missing.length > 0) {
      showError("Missing Mandatory Fields", missing.join(", ") + " fields are required.");
      return;
    }

    const handleCreator = formType === "add" ? { ...service, creator: employee_id } : service;
    const data = await Service.upsertService(handleCreator);

    if (data.data) {
      const data2 = await ServiceDevice.upsertServiceDevice(
        data.data[0].id,
        serviceDevice,
      );

      if (data2.data) {
        if (formType === "edit") {
          showSuccess(
            "Edit successful!",
            "Your changes were saved successfully.",
          );
        } else {
          showSuccess("Creation successful!", "New Service was created.");
        }
      }

      if (data2.error) {
        console.log(data2.error);
        showError();
      }
    }

    if (data.error) {
      console.log(data.error);
      showError();
    }

    //navigate("/home/service");
    navigate(-1 - numOfTabChange); // Goes back to where it came from
  };

  const serviceForm = () => {
    return (
      <form onSubmit={onFormSubmit}>
        <div className="flex items-center justify-end space-x-6 rounded-b border-t border-gray-200 px-3 pb-0 pt-3">
          <button
            type="button"
            hidden={!is_admin || formType !== "edit"}
            disabled={!is_admin || formType !== "edit"}
            className="rounded-lg bg-slate-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-slate-800 focus:outline-none focus:ring-4 focus:ring-slate-300 disabled:bg-gray-300"
            onClick={() => handleDelete()}
          >
            Delete
          </button>
          <button
            type="button"
            className="hover:bg-reed-800 rounded-lg bg-red-500 px-5 py-2.5 text-center text-sm font-medium text-white focus:outline-none focus:ring-4 focus:ring-red-300"
            onClick={() => navigate(-1 - numOfTabChange)}
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={
              formType !== "add" &&
              !is_admin &&
              employee_id !== service?.creator?.id &&
              service?.assigned_arr.indexOf(employee_id) === -1
            }
            title={
              formType !== "add" &&
                !is_admin &&
                employee_id !== service?.creator?.id &&
                service?.assigned_arr.indexOf(employee_id) === -1
                ? "Only the creator or assigned of this service can edit."
                : null
            }
            className="rounded-lg bg-blue-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 disabled:bg-gray-300"
          >
            Save
          </button>
        </div>
        <fieldset
          disabled={
            formType !== "add" &&
            !is_admin &&
            employee_id !== service?.creator?.id &&
            service?.assigned_arr.indexOf(employee_id) === -1
          }
        >
          <div className="space-y-6 p-6">
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6 sm:col-span-2">
                <label className="mb-2 block text-sm font-medium text-gray-900">
                  Creator *
                </label>
                <select
                  className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 shadow-sm focus:border-blue-600 focus:ring-blue-600"
                  required={true}
                  name="creator"
                  value={service?.creator?.id || employee_id}
                  disabled
                >
                  <option value="" disabled>
                    Select Creator
                  </option>
                  {Employees.map((e) => {
                    return (
                      <option key={e.id} value={e.id}>
                        {e.first_name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="col-span-6 sm:col-span-2">
                <label className="mb-2 block text-sm font-medium text-gray-900">
                  Assigned *
                </label>
                <MultiSelect
                  value={service?.assigned_arr}
                  onChange={handleInputChange}
                  name="assigned"
                  placeholder="Select Assigned"
                  showClear
                  options={Employees.filter(
                    (e) => !e.inactive || (service?.assigned_arr.length > 0 && service?.assigned_arr.indexOf(e.id) !== -1)
                  )}
                  disabled={
                    formType !== "add" &&
                    !is_admin &&
                    employee_id !== service?.creator?.id &&
                    service?.assigned_arr.indexOf(employee_id) === -1
                  }
                  optionLabel="first_name"
                  optionValue="id"
                  itemTemplate={(option) => option.first_name}
                  filter
                  scrollHeight="300px"
                  className="w-full rounded-lg border border-gray-300 bg-gray-50 text-sm text-gray-900 shadow-sm focus:border-blue-600 focus:ring-blue-600"
                />
                {/* <option value="">Select Assigned</option> */}
                {/* <select
                  className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 shadow-sm focus:border-blue-600 focus:ring-blue-600"
                  required={true}
                  name="assigned"
                  value={service?.assigned?.id}
                  onChange={handleInputChange}
                >
                  {activeEmployees.map((e) => {
                    return (
                      <option key={e.id} value={e.id}>
                        {e.first_name}
                      </option>
                    );
                  })}
                </select> */}
              </div>
              <div className="col-span-6 sm:col-span-2">
                <label className="mb-2 block text-sm font-medium text-gray-900">
                  Service Status *
                </label>
                <select
                  className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 shadow-sm focus:border-blue-600 focus:ring-blue-600"
                  required={true}
                  name="status"
                  value={service?.status?.id}
                  onChange={handleInputChange}
                >
                  <option value="">Select Status</option>
                  {ServiceStatus.filter(
                    (s) => !s.inactive || service?.status?.id === s.id,
                  ).map((s) => {
                    return (
                      <option key={s.id} value={s.id}>
                        {s.value}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6 sm:col-span-2">
                <label className="mb-2 block text-sm font-medium text-gray-900">
                  Customer *
                </label>
                <Dropdown
                  value={service?.customer?.id || service?.customer || ""}
                  onChange={handleInputChange}
                  placeholder="Select Customer"
                  name="customer"
                  options={Customers.filter(
                    (c) => !c.inactive || service?.customer?.id === c.id,
                  )}
                  optionLabel="name"
                  optionValue="id"
                  showClear
                  valueTemplate={(option, props) => {
                    if (option) {
                      return <div>{option?.name}</div>;
                    }

                    return <span>{props.placeholder}</span>;
                  }}
                  itemTemplate={(option) => {
                    return <div>{option?.name}</div>;
                  }}
                  disabled={
                    formType !== "add" &&
                    !is_admin &&
                    employee_id !== service?.creator?.id &&
                    service?.assigned_arr.indexOf(employee_id) === -1
                  }
                  scrollHeight="300px"
                  filter
                  className="w-full rounded-lg border border-gray-300 bg-gray-50 text-sm text-gray-900 shadow-sm focus:border-blue-600 focus:ring-blue-600"
                />
                {/* <select
                  className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 shadow-sm focus:border-blue-600 focus:ring-blue-600"
                  required={true}
                  name="customer"
                  value={service?.customer?.id}
                  onChange={handleCustomerChange}
                >
                  <option value="">Select Customer</option>
                  {activeCustomers.map((c) => {
                    return (
                      <option key={c.id} value={c.id}>
                        {c.name}
                      </option>
                    );
                  })}
                </select> */}
              </div>
              <div className="col-span-6 sm:col-span-2">
                <label className="mb-2 block text-sm font-medium text-gray-900">
                  Device
                </label>
                <MultiSelect
                  value={serviceDevice}
                  onChange={handleInputChange}
                  name="devices"
                  placeholder="Select Device(s)"
                  showClear
                  options={activeDevices.map((ad) => {
                    return {
                      id: ad.id,
                      label: `${ad?.serial_number} (${ad?.model?.value})`,
                    };
                  })}
                  disabled={
                    formType !== "add" &&
                    !is_admin &&
                    employee_id !== service?.creator?.id &&
                    service?.assigned_arr.indexOf(employee_id) === -1
                  }
                  optionValue="id"
                  itemTemplate={(option) => option.label}
                  filter
                  scrollHeight="300px"
                  className="w-full rounded-lg border border-gray-300 bg-gray-50 text-sm text-gray-900 shadow-sm focus:border-blue-600 focus:ring-blue-600"
                />
              </div>
              <div className="col-span-6 sm:col-span-2">
                {activeDevices.length !== 0 &&
                  serviceDevice.length !== 0 &&
                  serviceDevice.map((ad, i) => {
                    const selectedDevice = activeDevices.filter(
                      (d) => d.id === ad,
                    )[0];
                    // If the device belongs to other customer
                    if (
                      service?.customer?.id != selectedDevice?.customer?.id &&
                      service?.customer != selectedDevice?.customer?.id
                    ) {
                      return (
                        <div key={"sd" + i} className="text-sm text-red-500">
                          {selectedDevice?.serial_number} (
                          {selectedDevice?.model?.value}) currently belongs to{" "}
                          {selectedDevice?.customer?.name}
                        </div>
                      );
                    }
                  })}
              </div>
            </div>
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6 sm:col-span-6">
                <label className="mb-2 block text-sm font-medium text-gray-900">
                  Request *
                </label>
                <textarea
                  className="block h-64 w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 shadow-sm focus:border-blue-600 focus:ring-blue-600"
                  rows={4}
                  placeholder="Enter Customer Request"
                  required={true}
                  name="request"
                  value={service?.request}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-3 flex items-center sm:col-span-1">
                <input
                  type="checkbox"
                  className="focus:ring-blue-500focus:ring-2 h-4 w-4 rounded border-gray-300 bg-gray-100 text-blue-600"
                  name="inactive"
                  onChange={handleInputChange}
                  checked={service?.inactive}
                />
                <label className="ml-2 text-sm font-medium text-gray-900">
                  Inactive
                </label>
              </div>
              {
                formType === "edit" &&
                <div className="col-span-3 flex items-center">
                  <label className="ml-2 text-sm font-medium text-gray-900">
                    Created Date: {convertUTCToESTFormatted(service?.created_at)}
                  </label>
                </div>
              }
            </div>
          </div>
        </fieldset>
      </form>
    );
  };

  return (
    <div className="relative h-full max-h-full w-full align-middle">
      <div className="flex border-b bg-white p-4 shadow">
        <h1 className="subtitle md:title">
          Service {formType === "edit" ? `#${service?.id}` : ""}
        </h1>
        {formType === "edit" && (
          <div className="ml-8 flex flex-wrap gap-7">
            {SERVICE_MODAL_TABS.map((tab, index) => {
              return (
                <div
                  key={index}
                  className={`flex items-center gap-2 hover:cursor-pointer ${tab.name === currentTab ? "text-black" : "text-gray-400"}`}
                  onClick={() => {
                    setCurrentTab(SERVICE_MODAL_TABS[index].name);
                    params.set("tab", SERVICE_MODAL_TABS[index].name);
                    params.set("back", (numOfTabChange + 1).toString());
                    setSearchParam(params);
                  }}
                >
                  {tab.icon}
                  <h3 className="mb-0 hidden text-lg md:block">{tab.name}</h3>
                </div>
              );
            })}
          </div>
        )}
      </div>
      {currentTab === "Main" ? (
        serviceForm()
      ) : currentTab === "Comments" ? (
        <CommonCommentsComponent entityType="service" entityID={id} />
      ) : currentTab === "History" ? (
        <CommonHistoryComponent entityType="service" entityID={id} />
      ) : (
        <div className="relative flex flex-col items-center justify-center space-y-6 rounded-b-lg bg-white p-6 shadow">
          <p>component not found</p>
        </div>
      )}
      {selectedCustomer && (
        <div className="flex flex-col p-6 text-sm">
          <p className="font-bold">Customer Info - {selectedCustomer?.name}</p>
          <div className="flex gap-2">
            <p className="font-semibold">MID: </p>{" "}
            {selectedCustomer?.merchant_id}
          </div>
          <div className="flex gap-2 whitespace-nowrap">
            <p className="font-semibold">Address: </p>{" "}
            {selectedCustomer?.address}, {selectedCustomer?.city},{" "}
            {selectedCustomer?.state}, {selectedCustomer?.zip}
          </div>
          <div className="flex gap-2">
            <p className="font-semibold">Owner: </p>{" "}
            {selectedCustomer?.owner_fname} {selectedCustomer?.owner_lname}
          </div>
          <div className="flex gap-2">
            <p className="font-semibold">Store: </p>{" "}
            {selectedCustomer?.contact_store}
          </div>
          <div className="flex gap-2">
            <p className="font-semibold">Owner #1: </p>{" "}
            {selectedCustomer?.contact_owner}
          </div>
          <div className="flex gap-2">
            <p className="font-semibold">Owner #2: </p>{" "}
            {selectedCustomer?.contact_owner2}
          </div>
          <div className="flex gap-2">
            <p className="font-semibold">Email: </p> {selectedCustomer?.email}
          </div>
          <div className="flex gap-2">
            <p className="font-semibold">Email #2: </p>{" "}
            {selectedCustomer?.email2}
          </div>
        </div>
      )}
    </div>
  );
};
