import React, { useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { AiOutlinePlus } from "react-icons/ai";
import { useLocation, useNavigate } from "react-router-dom";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import { InputText } from "primereact/inputtext";
import { TriStateCheckbox } from "primereact/tristatecheckbox";
import { GrFilter } from "react-icons/gr";
import { IoMdCloseCircle } from "react-icons/io";

import { Button } from "primereact/button";
import { OverlayPanel } from "primereact/overlaypanel";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { useGetFrequentlyUsedValues } from "hooks/useGetFrequentlyUsedValues";
import { ListBox } from "primereact/listbox";
import { StatusBadgeWithColor } from "utils/utils";
import { Checkbox } from "primereact/checkbox";
import { MdLayersClear } from "react-icons/md";

export const CommonTable = ({
  tableColumns,
  tableRows,
  tableName = null,
  requireCreateButton = false,
  requireSearch = false,
  enableInsert = true,
  refetchRows = null,
  tableFilter = null,
  setTableFilter = null,
  lazyLoadRows = null,
  lazyLoading = null,
  lazyHasMoreData = null,
  initialPageLoaded = null,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const current_url_path = location.pathname;
  const op = useRef(null);

  // table data filter from parent component
  const { Employees, ServiceStatus, DeviceStatus, Customers } =
    useGetFrequentlyUsedValues({ tableFilter: tableFilter });

  let tempLoading = useRef(false);

  useEffect(() => {
    const element = document.querySelector("#dataTable");
    const childDiv = element.querySelector("div");

    const scrollEndEventCallback = (event) => {
      // @ts-ignore
      const { scrollTop, clientHeight, scrollHeight } = event.target;
      // When they are 90% scrolled through, render the next batch
      if (scrollTop + clientHeight >= scrollHeight - scrollHeight * 0.05) {
        if (!tempLoading.current) {
          tempLoading.current = true;
          lazyLoadRows().then(() => {
            tempLoading.current = false;
          });
        }
      }
    };

    if (!lazyHasMoreData) {
      childDiv.removeEventListener("scrollend", scrollEndEventCallback);
    } else {
      childDiv.addEventListener("scrollend", scrollEndEventCallback);
    }

    return () => {
      childDiv.removeEventListener("scrollend", scrollEndEventCallback);
    };
    // we need to manually add/remove these listeners, they dont really respond to filter updates or anything.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lazyHasMoreData, tableFilter]);

  useEffect(() => {
    if (initialPageLoaded ?? false) {
      const timerId = setTimeout(() => {
        refetchRows && refetchRows();
      }, 500);

      return () => clearTimeout(timerId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableFilter?.searchInput]);

  return (
    <>
      <div className="mb-2 flex items-center justify-between gap-3">
        <div className="flex items-center gap-3">
          <h1 className="subtitle md:title mb-0">{tableName}</h1>
          {requireCreateButton && (
            <button
              className={`flex items-center gap-2 rounded-full border-2 border-gray-300 bg-gray-100 px-3 py-2 ${!enableInsert ? "hidden" : ""}`}
              onClick={() => {
                if (current_url_path.includes("dropdown-management")) {
                  navigate(
                    current_url_path +
                      `/form?type=add&dropdownType=${tableName}`,
                  );
                } else {
                  navigate(current_url_path + "/form?type=add");
                }
              }}
            >
              <AiOutlinePlus size={20} />
              <span className="hidden md:block">Create</span>
            </button>
          )}
        </div>
        {tableFilter && setTableFilter && (
          <OverlayPanel
            className="normal p-4"
            ref={op}
            appendTo="self"
            showCloseIcon
            closeIcon={<IoMdCloseCircle size={24} />}
          >
            <div className="mb-4 flex items-center gap-4">
              {tableFilter.hasOwnProperty("created_at") && (
                <Calendar
                  placeholder="Created Date"
                  className="w-full rounded-lg border border-gray-300 bg-white p-2 text-sm"
                  value={
                    tableFilter["created_at"] == null
                      ? null
                      : tableFilter["created_at"].map((d) =>
                          d == null ? null : new Date(d),
                        )
                  }
                  onChange={(e) => {
                    setTableFilter("created_at", e.value);
                  }}
                  selectionMode="range"
                  readOnlyInput
                />
              )}
              {tableFilter.hasOwnProperty("customer") && (
                <Dropdown
                  value={tableFilter["customer"] || ""}
                  onChange={(e) => {
                    setTableFilter("customer", e.target.value || null);
                  }}
                  placeholder="Customer"
                  options={Customers}
                  optionLabel="name"
                  optionValue="id"
                  valueTemplate={(option, props) => {
                    if (option) {
                      return <div className="text-sm">{option?.name}</div>;
                    }

                    return <span className="text-sm">{props.placeholder}</span>;
                  }}
                  itemTemplate={(option) => {
                    return <div className="text-sm">{option?.name}</div>;
                  }}
                  showClear
                  filter
                  className="w-full rounded-lg border border-gray-300 bg-white text-xs text-gray-900 shadow-sm focus:border-blue-600 focus:ring-blue-600"
                />
              )}
            </div>
            <div className="flex flex-col items-start gap-4 md:flex-row">
              {tableFilter.hasOwnProperty("creator") && Employees && (
                <>
                  <div className="flex w-full flex-col gap-1">
                    <div className="flex justify-between">
                      <p className="font-semibold">Creator</p>
                      <p
                        className="hover:cursor-pointer"
                        onClick={() => setTableFilter("creator", [])}
                      >
                        Clear
                      </p>
                    </div>
                    <hr />
                    <ListBox
                      multiple
                      value={
                        Array.isArray(tableFilter["creator"])
                          ? tableFilter["creator"]
                          : [tableFilter["creator"]]
                      }
                      onChange={(e) =>
                        setTableFilter(
                          "creator",
                          e.value.filter((v) => v),
                        )
                      }
                      options={Employees.filter((e) => e.inactive === false)}
                      itemTemplate={(option) => {
                        const creatorArray = Array.isArray(
                          tableFilter["creator"],
                        )
                          ? tableFilter["creator"]
                          : [tableFilter["creator"]];

                        const isSelected = creatorArray.includes(option?.id);

                        return (
                          <div className="flex justify-start gap-4 text-sm">
                            <Checkbox
                              className="[&>*]:bg-gray-400"
                              checked={isSelected}
                            />
                            <p>{option.first_name}</p>
                          </div>
                        );
                      }}
                      optionLabel="first_name"
                      optionValue="id"
                      className="h-100 w-full overflow-y-auto"
                    />
                  </div>
                  {/* <Dropdown
                  value={tableFilter["creator"] || ""}
                  onChange={(e) => {
                    setTableFilter("creator", e.target.value || null);
                  }}
                  placeholder="Created"
                  options={Employees}
                  optionLabel="first_name"
                  optionValue="id"
                  valueTemplate={(option, props) => {
                    if (option) {
                      return <div>{option?.first_name}</div>;
                    }

                    return <span>{props.placeholder}</span>;
                  }}
                  itemTemplate={(option) => {
                    return <div>{option?.first_name}</div>;
                  }}
                  showClear
                  filter
                  className="w-full rounded-lg border border-gray-300 bg-white text-sm text-gray-900 shadow-sm focus:border-blue-600 focus:ring-blue-600"
                /> */}
                </>
              )}
              {tableFilter.hasOwnProperty("assigned") && Employees && (
                <>
                  <div className="flex w-full flex-col gap-1">
                    <div className="flex justify-between">
                      <p className="font-semibold">Assigned</p>
                      <p
                        className="hover:cursor-pointer"
                        onClick={() => setTableFilter("assigned", [])}
                      >
                        Clear
                      </p>
                    </div>
                    <hr />
                    <ListBox
                      multiple
                      value={
                        Array.isArray(tableFilter["assigned"])
                          ? tableFilter["assigned"]
                          : [tableFilter["assigned"]]
                      }
                      onChange={(e) => {
                        setTableFilter(
                          "assigned",
                          e.value.filter((v) => v),
                        );
                      }}
                      options={Employees.filter((e) => e.inactive === false)}
                      itemTemplate={(option) => {
                        const assignedArray = Array.isArray(
                          tableFilter["assigned"],
                        )
                          ? tableFilter["assigned"]
                          : [tableFilter["assigned"]];

                        const isSelected = assignedArray.includes(option?.id);

                        return (
                          <div className="flex justify-start gap-4 text-sm">
                            <Checkbox
                              className="[&>*]:bg-gray-400"
                              checked={isSelected}
                            />
                            <p>{option.first_name}</p>
                          </div>
                        );
                      }}
                      optionLabel="first_name"
                      optionValue="id"
                      className="h-100 w-full overflow-y-auto"
                    />
                  </div>
                </>
                // <Dropdown
                //   value={tableFilter["assigned"] || ""}
                //   onChange={(e) => {
                //     setTableFilter("assigned", e.target.value || null);
                //   }}
                //   placeholder="Assigned"
                //   options={Employees}
                //   optionLabel="first_name"
                //   optionValue="id"
                //   valueTemplate={(option, props) => {
                //     if (option) {
                //       return <div>{option?.first_name}</div>;
                //     }

                //     return <span>{props.placeholder}</span>;
                //   }}
                //   itemTemplate={(option) => {
                //     return <div>{option?.first_name}</div>;
                //   }}
                //   showClear
                //   filter
                //   className="w-full rounded-lg border border-gray-300 bg-white text-sm text-gray-900 shadow-sm focus:border-blue-600 focus:ring-blue-600"
                // />
              )}
              {tableFilter.hasOwnProperty("serviceStatus") && ServiceStatus && (
                <>
                  <div className="flex w-full flex-col gap-1">
                    <div className="flex justify-between">
                      <p className="font-semibold">Service Status</p>
                      <p
                        className="hover:cursor-pointer"
                        onClick={() => setTableFilter("serviceStatus", [])}
                      >
                        Clear
                      </p>
                    </div>
                    <hr />
                    <ListBox
                      multiple
                      value={
                        Array.isArray(tableFilter["serviceStatus"])
                          ? tableFilter["serviceStatus"]
                          : [tableFilter["serviceStatus"]]
                      }
                      onChange={(e) =>
                        setTableFilter(
                          "serviceStatus",
                          e.value.filter((v) => v),
                        )
                      }
                      options={ServiceStatus}
                      itemTemplate={(option) => {
                        const serviceStatusArray = Array.isArray(
                          tableFilter["serviceStatus"],
                        )
                          ? tableFilter["serviceStatus"]
                          : [tableFilter["serviceStatus"]];

                        const isSelected = serviceStatusArray.includes(
                          option?.id,
                        );

                        return (
                          <div className="flex justify-start gap-5">
                            <Checkbox
                              className="[&>*]:bg-gray-400"
                              checked={isSelected}
                            />
                            <StatusBadgeWithColor status={option?.value} />
                          </div>
                        );
                      }}
                      optionLabel="value"
                      optionValue="id"
                      className="h-100 w-full overflow-y-auto"
                    />
                  </div>
                </>
                // <Dropdown
                //   value={tableFilter["serviceStatus"] || ""}
                //   onChange={(e) => {
                //     setTableFilter("serviceStatus", e.target.value || null);
                //   }}
                //   placeholder="Status"
                //   options={ServiceStatus}
                //   optionLabel="value"
                //   optionValue="id"
                //   valueTemplate={(option, props) => {
                //     if (option) {
                //       return <div>{option?.value}</div>;
                //     }

                //     return <span>{props.placeholder}</span>;
                //   }}
                //   itemTemplate={(option) => {
                //     return <div>{option?.value}</div>;
                //   }}
                //   showClear
                //   filter
                //   className="w-full rounded-lg border border-gray-300 bg-white text-sm text-gray-900 shadow-sm focus:border-blue-600 focus:ring-blue-600"
                // />
              )}
              {tableFilter.hasOwnProperty("deviceStatus") && DeviceStatus && (
                <>
                  <div className="flex w-full flex-col gap-1">
                    <div className="flex justify-between">
                      <p className="font-semibold">Device Status</p>
                      <p
                        className="hover:cursor-pointer"
                        onClick={() => setTableFilter("deviceStatus", [])}
                      >
                        Clear
                      </p>
                    </div>
                    <hr />
                    <ListBox
                      multiple
                      value={
                        Array.isArray(tableFilter["deviceStatus"])
                          ? tableFilter["deviceStatus"]
                          : [tableFilter["deviceStatus"]]
                      }
                      onChange={(e) => setTableFilter("deviceStatus", e.value)}
                      options={DeviceStatus}
                      itemTemplate={(option) => {
                        const deviceStatusArray = Array.isArray(
                          tableFilter["deviceStatus"],
                        )
                          ? tableFilter["deviceStatus"]
                          : [tableFilter["deviceStatus"]];

                        const isSelected = deviceStatusArray.includes(
                          option?.id,
                        );

                        return (
                          <div className="flex justify-start gap-5">
                            <Checkbox
                              className="[&>*]:bg-gray-400"
                              checked={isSelected}
                            />
                            <StatusBadgeWithColor status={option?.value} />
                          </div>
                        );
                      }}
                      optionLabel="value"
                      optionValue="id"
                      className="h-100 w-full overflow-y-auto"
                    />
                  </div>
                  {/* <Dropdown
                    value={tableFilter["deviceStatus"] || ""}
                    onChange={(e) => {
                      setTableFilter("deviceStatus", e.target.value);
                    }}
                    placeholder="Status"
                    options={DeviceStatus}
                    optionLabel="value"
                    optionValue="id"
                    valueTemplate={(option, props) => {
                      if (option) {
                        return <div>{option?.value}</div>;
                      }

                      return <span>{props.placeholder}</span>;
                    }}
                    itemTemplate={(option) => {
                      return <div>{option?.value}</div>;
                    }}
                    showClear
                    filter
                    className="w-full rounded-lg border border-gray-300 bg-white text-sm text-gray-900 shadow-sm focus:border-blue-600 focus:ring-blue-600"
                  /> */}
                </>
              )}
            </div>
            <div className="mt-3 flex flex-col items-center">
              <div className="flex gap-5">
                <Button
                  label="Clear All"
                  onClick={(e) => {
                    setTableFilter("", "", true);
                    if (refetchRows) {
                      refetchRows();
                    }
                    op.current.hide();
                  }}
                ></Button>
                <Button
                  label="Apply"
                  onClick={(e) => {
                    if (refetchRows) {
                      refetchRows();
                    }
                    op.current.hide();
                  }}
                ></Button>
                {tableFilter.hasOwnProperty("inactive") && (
                  <div className="align-items-center flex gap-4">
                    <label className="">Inactive?</label>
                    <div className="flex flex-col items-start">
                      <TriStateCheckbox
                        className="[&>*]:bg-gray-400"
                        value={tableFilter["inactive"]}
                        onChange={(e) => {
                          setTableFilter("inactive", e.value);
                        }}
                      />
                      <label className="text-sm">
                        {tableFilter["inactive"] == null
                          ? "All"
                          : tableFilter["inactive"]
                            ? "Inactive"
                            : "Active"}
                      </label>
                    </div>
                  </div>
                )}
              </div>
              <div
                title="*Caution: this will remove all of your saved filters and reload the page."
                className="flex items-center gap-1 hover:cursor-pointer"
                onClick={() => {
                  // List of keys you want to remove from localStorage
                  const keysToRemove = [
                    "customerTableFilter",
                    "serviceTableFilter",
                    "deviceTableFilter",
                  ];

                  // Remove each key
                  keysToRemove.forEach((key) => localStorage.removeItem(key));

                  // Reload the page
                  window.location.reload();
                }}
              >
                <MdLayersClear className="fill-gray-400" />
                <p className="font-small text-xs text-gray-400">
                  Clear my cache
                </p>
              </div>
            </div>
          </OverlayPanel>
        )}

        {!requireSearch ? (
          ""
        ) : (
          <div className="flex w-full items-center gap-2 p-2 md:w-4/12">
            {tableFilter && setTableFilter && (
              <Button onClick={(e) => op.current.toggle(e)}>
                <GrFilter size={24} />
              </Button>
            )}
            <div className="relative w-full">
              <InputText
                className="normal w-full border p-2"
                value={tableFilter?.searchInput || ""}
                onChange={(e) => {
                  setTableFilter("searchInput", e.target.value);
                }}
                placeholder={`Search ${tableName}`}
              />
              {tableFilter?.searchInput && (
                <IoMdCloseCircle
                  onClick={(e) => {
                    setTableFilter("searchInput", "");
                  }}
                  className="absolute right-3 top-2 hover:cursor-pointer"
                  size={24}
                  color="gray"
                />
              )}
            </div>
          </div>
        )}
      </div>
      <div className="h-[80vh] border shadow-md md:h-[88vh]">
        <DataTable
          loading={lazyLoading}
          id="dataTable"
          value={tableRows}
          stripedRows
          size="normal"
          tableStyle={{ width: "100%" }}
          onRowClick={(e) => {
            navigate("form?type=edit&id=" + e.data.id);
          }}
          lazy
          removableSort
          onSort={(e) => {
            setTableFilter("sortState", {
              sortField: e.sortField,
              sortOrder: e.sortOrder,
            });
            refetchRows();
          }}
          sortField={tableFilter?.sortState?.sortField}
          sortOrder={tableFilter?.sortState?.sortOrder}
          scrollable={true}
          scrollHeight="flex"
          rowHover={true}
          filterDisplay="menu"
          emptyMessage="No data found."
        >
          {tableColumns.map((col, index) => {
            return (
              <Column
                key={index}
                field={col.key}
                header={col.header}
                headerClassName="py-2 px-2 text-xs whitespace-nowrap"
                bodyClassName="text-xs py-3 px-2 align-top"
                body={col.cell}
                style={{
                  minWidth: col.minWidth,
                }}
                showFilterMenu={col.filter_type === "boolean"}
                showClearButton={true}
                dataType={col.filter_type === "boolean" ? "boolean" : undefined}
                sortable={col?.sortable}
              ></Column>
            );
          })}
        </DataTable>
      </div>
    </>
  );
};
