import { supabase } from "utils/supabaseClient";

const DB_NAME = "comment";
const STORAGE_NAME = "service_photos";

export default class Comment {
  static async getCommentByEntityTypeAndID(type, id) {
    return await supabase
      .from(DB_NAME)
      .select(
        "id, created_at, comment, deleted, user (first_name, last_name, id), photoKeys",
      )
      .eq("entity_type", type)
      .eq("entity_id", id)
      .eq("deleted", false)
      .order("created_at", { ascending: false });
  }

  static async getCommentByCommentID(comment_id) {
    return await supabase
      .from(DB_NAME)
      .select(
        "id, created_at, comment, deleted, user (first_name, last_name, id), photoKeys",
      )
      .eq("id", comment_id)
      .single();
  }

  static async upsertComment(comment) {
    return await supabase.from(DB_NAME).upsert(comment).select();
  }

  // ONLY FOR SERVICE
  static async uploadFiles(files, service_id, comment_id) {
    let keyOfSuccessfulUploads = [];
    for (const file of files) {
      const { data } = await supabase.storage
        .from("service_photos")
        .upload(
          `service_${service_id}/comment_${comment_id}/${file.name}`,
          file,
          {
            cacheControl: "3600",
            upsert: false,
          },
        );
      if (data) {
        keyOfSuccessfulUploads.push(data.path);
      }
    }

    const { data, error } = await Comment.getCommentByCommentID(comment_id);
    if (error) {
      console.error("Error fetching data:", error.message);
      return;
    }

    const currentList = data["photoKeys"] || [];

    const updatedList = [...currentList, ...keyOfSuccessfulUploads];

    const { data: updatedData, error: updatedError } = await supabase
      .from(DB_NAME)
      .update({ photoKeys: updatedList })
      .eq("id", comment_id)
      .single();

    if (updatedError) {
      console.error("Error updating photoKeys array:", updatedError.message);
      return;
    }
  }

  static async getPhotosURL(photoKeys) {
    let photoPublicUrls = [];
    for (const key of photoKeys) {
      const { data } = supabase.storage.from(STORAGE_NAME).getPublicUrl(key);

      if (data) {
        photoPublicUrls.push(data.publicUrl);
      }
    }
    return photoPublicUrls;
  }

  static async getPhotoURL(photoKey) {
    const { data } = supabase.storage.from(STORAGE_NAME).getPublicUrl(photoKey);

    if (data) {
      return data.publicUrl;
    }

    return null;
  }
}
