import React from "react";
import { useNavigate } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "primereact/resources/themes/lara-light-indigo/theme.css";

export const DashboardTable = ({
  tableColumns,
  tableRows,
  tableName,
  type,
  dropDownMenu = null,
}) => {
  const navigate = useNavigate();
  return (
    <div className="h-full">
      <div className="flex items-center justify-between p-2">
        <p className="subtitle mb-0 text-base font-semibold sm:text-lg">
          {tableName}
        </p>
        {dropDownMenu}
      </div>
      <div>
        <DataTable
          value={tableRows}
          stripedRows
          size="small"
          tableStyle={{ width: "100%" }}
          removableSort
          scrollable
          scrollHeight="40vh"
          rowHover={true}
          rows={10}
          rowClassName={(row) => {
            return {
              // @ts-ignore
              [`${tableName.replace(/\s/g, "")}-row-tooltip-${row?.data?.id}`]: true,
            };
          }}
          onRowClick={(row) => {
            navigate(`/home/${type}/form?type=edit&id=${row?.data?.id}`);
          }}
          emptyMessage="No data found."
        >
          {tableColumns.map((col, index) => {
            return (
              <Column
                key={index}
                field={col.key}
                header={col.header}
                headerClassName="py-2 px-2 text-xs whitespace-nowrap"
                bodyClassName="text-xs py-3 px-2 align-top"
                body={col.cell}
              ></Column>
            );
          })}
        </DataTable>
      </div>
    </div>
  );
};

// {tableRows.map((row) => {
//   return (
//     <Tooltip
//       anchorSelect={`.${tableName.replace(/\s/g, "")}-row-tooltip-${
//         row?.id
//       }`}
//       place="bottom"
//       delayShow={500}
//       key={row.id}
//     >
//       {/* <div className="flex flex-col">
//         {Object.entries(row).map(([key, value]) => {
//           return (
//             <p>
//               {key} : {value?.toString() ?? "Empty"}
//             </p>
//           );
//         })}
//       </div> */}
//       <pre>{JSON.stringify(row, null, 2)}</pre>
//     </Tooltip>
//   );
// })
// }
