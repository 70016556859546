import { supabase } from "utils/supabaseClient";

const DB_NAME = "service";

export default class Service {
  static async getAllData() {
    return await supabase
      .from(DB_NAME)
      .select(
        `
                *,
                creator (
                    id,
                    first_name,
                    last_name
                ),
                customer (
                    id,
                    name
                ),
                status (
                    id,
                    value
                )
            `,
      )
      .order("created_at", { ascending: false });
  }

  static async getServicesWithRangeAndFilter(from, to, filters) {
    const inactive = filters["inactive"] ?? null;

    // these three are array now. Multi input filters.
    let creator = filters["creator"] ?? [];
    creator = Array.isArray(creator) ? creator : [creator];

    let assigned = filters["assigned"] ?? [];
    assigned = Array.isArray(assigned) ? assigned : [assigned];

    let status = filters["serviceStatus"] ?? [];
    status = Array.isArray(status) ? status : [status];

    const customer = filters["customer"] ?? null;
    const created_at_minmax = filters["created_at"] ?? null;
    const created_at_min = created_at_minmax
      ? created_at_minmax[0] ?? null
      : null;
    const created_at_max = created_at_minmax
      ? created_at_minmax[1] ?? null
      : null;
    const searchTerm = filters["searchInput"] ?? null;

    const query = supabase.from(DB_NAME).select(
      `
          *,
          creator (
              id,
              first_name,
              last_name
          ),
          assigned (
              id,
              first_name,
              last_name
          ),
          customer (
              id,
              name
          ),
          device (
              id,
              serial_number,
              model(id, value)
          ),
          status (
              id,
              value
          )
        `,
      { count: "exact" },
    );

    if (inactive !== null) {
      query.eq("inactive", inactive);
    }

    if (creator !== null && Array.isArray(creator) && creator.length !== 0) {
      // query.eq("creator", creator);
      query.in(
        "creator",
        creator.filter((s) => s),
      );
    }

    if (assigned !== null && Array.isArray(assigned) && assigned.length !== 0) {
      query.overlaps(
        "assigned_arr",
        assigned.filter((s) => s),
      );
    }

    if (status !== null && Array.isArray(status) && status.length !== 0) {
      query.in(
        "status",
        status.filter((s) => s),
      );
    }

    if (customer !== null) {
      query.eq("customer", customer);
    }

    if (created_at_max && created_at_min) {
      const minDate = new Date(created_at_min);
      const maxDate = new Date(created_at_max);

      // if dates are same, get all data from that date.
      if (minDate.getTime() === maxDate.getTime()) {
        const utcDate = new Date(minDate.toUTCString());
        const utcDatePlus24Hours = new Date(minDate.toUTCString());
        utcDatePlus24Hours.setHours(utcDatePlus24Hours.getHours() + 24);
        query
          .gte("created_at", utcDate.toISOString())
          .lte("created_at", utcDatePlus24Hours.toISOString());
      } else {
        // min and max are different times.
        const utcDateMin = new Date(minDate.toUTCString());
        const utcDateMax = new Date(maxDate.toUTCString());
        utcDateMax.setHours(utcDateMax.getHours() + 24);
        query
          .gte("created_at", utcDateMin.toISOString())
          .lte("created_at", utcDateMax.toISOString());
      }
    } else if (created_at_min) {
      const minDate = new Date(created_at_min);
      const utcDate = new Date(minDate.toUTCString());
      const utcDatePlus24Hours = new Date(minDate.toUTCString());
      utcDatePlus24Hours.setHours(utcDatePlus24Hours.getHours() + 24);
      query
        .gte("created_at", utcDate.toISOString())
        .lte("created_at", utcDatePlus24Hours.toISOString());
    } else if (created_at_max) {
      throw new Error(
        "Max date exists without min? that is weird. Please report a bug.",
      );
    }

    if (searchTerm !== null && searchTerm !== "") {
      // need to create a computed column if we want to search across many foreign tables & main table.
      // https://supabase.com/docs/guides/database/full-text-search?language=js
      query.or(`service_search_index.ilike.%${searchTerm}%`);
    }

    return await query
      .order("created_at", { ascending: false })
      .order("id", { ascending: false })
      .range(from, to);
  }

  static async getServiceByID(serviceID) {
    return await supabase
      .from(DB_NAME)
      .select(
        `
                *,
                creator (
                    id,
                    first_name,
                    last_name
                ),
                customer (
                    id,
                    name
                ),
                status (
                    id,
                    value
                )
            `,
      )
      .eq("id", serviceID);
  }

  static async getServicesByCustomerID(customer_id) {
    return await supabase
      .from(DB_NAME)
      .select(
        `
                *,
                creator (
                    id,
                    first_name,
                    last_name
                ),
                customer (
                    id,
                    name
                ),
                status (
                    id,
                    value
                )
            `,
      )
      .eq("customer", customer_id)
      .order("created_at", { ascending: true });
  }

  static async getServicesByDeviceID(device_id) {
    return await supabase
      .from(DB_NAME)
      .select(
        `
                *,
                creator (
                    id,
                    first_name,
                    last_name
                ),
                customer (
                    id,
                    name
                ),
                status (
                    id,
                    value
                )
            `,
      )
      .eq("device", device_id)
      .order("created_at", { ascending: true });
  }

  static async upsertService(service) {
    // if (service.hasOwnProperty("creator")) delete service["creator"];
    if (service.device === "") delete service["device"];
    if (service?.customer?.id != null) {
      service.customer = service.customer.id;
    }
    if (service?.status?.id != null) {
      service.status = service.status.id;
    }
    if (service?.creator?.id != null) {
      service.creator = service.creator.id;
    }

    if (service.status === "") {
      service.status = null;
    }
    if (service.customer === "") {
      service.customer = null;
    }
    return await supabase.from(DB_NAME).upsert(service).select();
  }

  static async deleteService(id) {
    return await supabase.from(DB_NAME).delete().eq("id", id);
  }

  static async getTopKNewestServices(k) {
    return await supabase
      .from(DB_NAME)
      .select(
        `
            id,
            request,
            customer,
            created_at,
            assigned_text,
            customer (
                id,
                name
            ),
            status (
                id,
                value
            )
        `,
      )
      .eq("inactive", false)
      .order("created_at", { ascending: false })
      .limit(k);
  }

  static async getTopKNewestUpdatedServices(k) {
    return await supabase
      .from(DB_NAME)
      .select(
        `
            id,
            request,
            customer,
            created_at,
            modified_at,
            assigned_text,
            customer (
                id,
                name
            ),
            status (
                id,
                value
            )
        `,
      )
      .eq("inactive", false)
      .order("modified_at", { ascending: false })
      .limit(k);
  }

  static async getTopKOldestServicesByStatus(k, service_status_id) {
    return await supabase
      .from(DB_NAME)
      .select(
        `
            id,
            request,
            customer,
            created_at,
            modified_at,
            assigned_text,
            customer (
                id,
                name
            ),
            status (
                id,
                value
            )
        `,
      )
      .eq("inactive", false)
      .eq("status", service_status_id)
      .order("created_at", { ascending: true })
      .limit(k);
  }
}
