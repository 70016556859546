import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useGetCurrentEmployeeInfo } from "hooks/useGetCurrentEmployee";
import { useToast } from "hooks/useToast";
import DropdownField from "db/dropdownField";

const initialDropdownVal = {
  value: "",
  type: "",
  inactive: false,
};

export const SettingTabDropdownForm = () => {
  const navigate = useNavigate();
  const { is_admin } = useGetCurrentEmployeeInfo();
  const [params] = useSearchParams();
  const formType = params.get("type");
  const id = params.get("id");
  const dropdownType = params.get("dropdownType");
  const { showSuccess, showError } = useToast();
  const [dropdown, setDropdown] = useState({
    ...initialDropdownVal,
    type: dropdownType,
  });

  const fetchDropdownFieldById = async (id) => {
    const { data } = await DropdownField.getDrowdownFieldByID(id);

    if (data) {
      setDropdown(data[0]);
    }
  };

  useEffect(() => {
    if (formType === "edit") {
      fetchDropdownFieldById(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInputChange = (e) => {
    const { name, value, checked } = e.target;
    if (name === "inactive") {
      setDropdown({
        ...dropdown,
        [name]: checked,
      });
    } else {
      setDropdown({
        ...dropdown,
        [name]: value,
      });
    }
  };

  const onFormSubmit = async (e) => {
    e.preventDefault();
    const data = await DropdownField.upsertDropdown(dropdown);

    if (data.data) {
      if (formType === "edit") {
        showSuccess("Creation successful!", "New customer was created.");
      } else {
        showSuccess(
          "Edit successful!",
          "Your changes were saved successfully.",
        );
      }
    }

    if (data.error) {
      showError();
    }

    navigate("/home/setting/dropdown-management");
  };

  return (
    <div className="relative h-full max-h-full w-full align-middle">
      <div className="border-b bg-white p-4 shadow">
        <h1 className="subtitle md:title">Dropdown</h1>
      </div>
      <form onSubmit={onFormSubmit}>
        <div className="flex items-center justify-end space-x-6 rounded-b border-t border-gray-200 px-3 pb-0 pt-3">
          <button
            type="button"
            className="hover:bg-reed-800 rounded-lg bg-red-500 px-5 py-2.5 text-center text-sm font-medium text-white focus:outline-none focus:ring-4 focus:ring-red-300"
            onClick={() => navigate(-1)}
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={!is_admin}
            title={!is_admin ? "Only admin can edit a user" : null}
            className="rounded-lg bg-blue-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 disabled:bg-gray-300"
          >
            Save
          </button>
        </div>
        <div className="space-y-6 p-6">
          <div className="grid grid-cols-6 gap-6">
            <div className="col-span-6 sm:col-span-2">
              <label className="mb-2 block text-sm font-medium text-gray-900">
                Value *
              </label>
              <input
                type="text"
                className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 shadow-sm focus:border-blue-600 focus:ring-blue-600"
                placeholder="Enter dropdown value"
                required={true}
                value={dropdown.value}
                name="value"
                onChange={handleInputChange}
              />
            </div>
            <div className="col-span-3 flex items-center sm:col-span-1">
              <input
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-blue-600"
                name="inactive"
                onChange={handleInputChange}
                checked={dropdown.inactive}
              />
              <label className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                Inactive
              </label>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
