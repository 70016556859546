import { supabase } from "utils/supabaseClient";

const DB_NAME = "device";

export default class Device {
  static async getAllData() {
    return await supabase
      .from(DB_NAME)
      .select(
        `
                *,
                customer (
                    id,
                    name
                ),
                model(
                    id,
                    value
                ),
                status(
                    id,
                    value
                ),
                encryption(
                  id,
                  value
                )
            `,
      )
      .order("id", { ascending: false });
  }

  static async getDevicesWithRange(from, to, filters) {
    const searchTerm = filters["searchInput"] ?? null;
    const customer = filters["customer"] ?? null;
    // This is now going to an array of statuses
    let status = filters["deviceStatus"] ?? [];
    status = Array.isArray(status) ? status : [status];

    const query = supabase.from(DB_NAME).select(
      `
          *,
          customer (
              id,
              name
          ),
          model(
              id,
              value
          ),
          status(
              id,
              value
          ),
          encryption(
            id,
            value
          )
        `,
      { count: "exact" },
    );

    if (searchTerm !== null && searchTerm !== "") {
      // need to create a computed column if we want to search across many foreign tables & main table.
      // https://supabase.com/docs/guides/database/full-text-search?language=js
      query.or(`device_search_index.ilike.%${searchTerm}%`);
    }

    if (customer !== null) {
      query.eq("customer", customer);
    }

    if (status !== null && Array.isArray(status) && status.length !== 0) {
      query.in(
        "status",
        status.filter((s) => s),
      );
    }

    return await query
      .order("created_at", { ascending: false })
      .order("id", { ascending: false })
      .range(from, to);
  }

  static async getDevicesForService(customer_id, device_id) {
    return await supabase
      .from(DB_NAME)
      .select(
        `
                *,
                customer (
                    id,
                    name
                ),
                model(
                    id,
                    value
                ),
                status(
                    id,
                    value
                ),
                encryption(
                  id,
                  value
                )
            `,
      )
      .or(`customer.eq."${customer_id}", id.in.(${device_id.join(",")})`)
      .order("id", { ascending: true });
  }

  static async getDevicesByCustomerID(customer_id) {
    return await supabase
      .from(DB_NAME)
      .select(
        `
                *,
                customer (
                    id,
                    name
                ),
                model(
                    id,
                    value
                ),
                status(
                    id,
                    value
                ),
                encryption(
                  id,
                  value
                )
            `,
      )
      .eq("customer", customer_id)
      .order("id", { ascending: true });
  }

  static async getDeviceByID(device_id) {
    return await supabase
      .from(DB_NAME)
      .select(
        `
                *,
                customer (
                    id,
                    name
                ),
                model(
                    id,
                    value
                ),
                status(
                    id,
                    value
                ),
                encryption(
                  id,
                  value
                )
            `,
      )
      .eq("id", device_id);
  }

  static async getActiveDeviceListByCustomerId(customerId) {
    return await supabase
      .from(DB_NAME)
      .select("id, serial_number, model(id, value)")
      .match({ customer: customerId, inactive: false });
  }

  static async upsertDevice(device) {
    if (device.purchase_date === "") device["purchase_date"] = null;
    if (device?.customer?.id != null) {
      device.customer = device.customer.id;
    }
    if (device?.model?.id != null) {
      device.model = device.model.id;
    }
    if (device?.status?.id != null) {
      device.status = device.status.id;
    }
    if (device?.encryption?.id != null) {
      device.encryption = device.encryption.id;
    }

    if (device.customer === "") {
      device.customer = null;
    }
    if (device.status === "") {
      device.status = null;
    }
    if (device.encryption === "") {
      device.encryption = null;
    }
    if (device.terminal_id === "") {
      device.terminal_id = null;
    }
    if (device.assigned_number === "") {
      device.assigned_number = null;
    }

    return await supabase.from(DB_NAME).upsert(device).select();
  }

  static async bulkInsertDevice(devices) {
    for (var intIndex in devices) {
      delete devices[intIndex].checked;

      if (devices[intIndex].purchase_date === "") {
        delete devices[intIndex]["purchase_date"];
      }

      if (devices[intIndex].status === "") {
        devices[intIndex].status = null;
      }
    }

    return await supabase.from(DB_NAME).insert(devices).select();
  }

  static async deleteDevice(id) {
    return await supabase.from(DB_NAME).delete().eq("id", id);
  }
}
