import DropdownField from "db/dropdownField";
import React, { useEffect, useState } from "react";
import { LoadingSpinner } from "./LoadingSpinner";
import { EntityStatus } from "utils/utils";
import { useGetCurrentEmployeeInfo } from "hooks/useGetCurrentEmployee";
import { CommonTable } from "./CommonTable";

const DEVICE_MODEL = "Device Model";
const DEVICE_STATUS = "Device Status";
const SERVICE_STATUS = "Service Status";
const PLATFORM = "Platform";
const ENCRYPTION = "Encryption";

export const SettingTabDropdownManagement = () => {
  const [deviceModel, setDeviceModel] = useState([]);
  const [deviceStatus, setDeviceStatus] = useState([]);
  const [serviceStatus, setServiceStatus] = useState([]);
  const [platform, setPlatform] = useState([]);
  const [encryption, setEncryption] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { is_admin } = useGetCurrentEmployeeInfo();

  const fetchDropdowns = async () => {
    const { data } = await DropdownField.getAllData();
    if (data) {
      let groups = {};
      data.forEach((row) => {
        var list = groups[row.type];
        if (list) {
          list.push(row);
        } else {
          groups[row.type] = [row];
        }
      });

      setDeviceModel(groups[DEVICE_MODEL]);
      setDeviceStatus(groups[DEVICE_STATUS]);
      setServiceStatus(groups[SERVICE_STATUS]);
      setPlatform(groups[PLATFORM]);
      setEncryption(groups[ENCRYPTION]);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchDropdowns();
  }, []);

  const tableColumns = [
    {
      key: "value",
      header: "Value",
      cell: (row) => row.value,
    },
    {
      key: "inactive",
      header: "Status",
      cell: (row) => <EntityStatus inactive={row.inactive} />,
    },
  ];

  if (!is_admin) {
    return (
      <div className="flex h-screen items-center justify-center">
        <p>Only Admin can see this page.</p>
      </div>
    );
  }

  return (
    <div className="m-3 lg:m-5">
      {isLoading ? (
        <div className="flex justify-center	">
          <LoadingSpinner />
        </div>
      ) : (
        <div className="flex flex-col gap-5">
          <div className="grid grid-cols-6 gap-6">
            <div className="col-span-6 md:col-span-3 xl:col-span-2">
              <CommonTable
                tableName={DEVICE_MODEL}
                tableColumns={tableColumns}
                tableRows={deviceModel}
                requireCreateButton={true}
                enableInsert={is_admin}
                requireSearch={false}
              />
            </div>
            <div className="col-span-6 md:col-span-3 xl:col-span-2">
              <CommonTable
                tableName={DEVICE_STATUS}
                tableColumns={tableColumns}
                tableRows={deviceStatus}
                requireCreateButton={true}
                enableInsert={is_admin}
                requireSearch={false}
              />
            </div>
            <div className="col-span-6 md:col-span-3 xl:col-span-2">
              <CommonTable
                tableName={SERVICE_STATUS}
                tableColumns={tableColumns}
                tableRows={serviceStatus}
                requireCreateButton={true}
                enableInsert={is_admin}
                requireSearch={false}
              />
            </div>
            <div className="col-span-6 md:col-span-3 xl:col-span-2">
              <CommonTable
                tableName={PLATFORM}
                tableColumns={tableColumns}
                tableRows={platform}
                requireCreateButton={true}
                enableInsert={is_admin}
                requireSearch={false}
              />
            </div>
            <div className="col-span-6 md:col-span-3 xl:col-span-2">
              <CommonTable
                tableName={ENCRYPTION}
                tableColumns={tableColumns}
                tableRows={encryption}
                requireCreateButton={true}
                enableInsert={is_admin}
                requireSearch={false}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
