import React from "react";
import "./App.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./components/Login";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { Login } from "./components/Login";
import { MainHome } from "./components/MainHome";
import { DashboardTab } from "components/DashboardTab";
import { InventoryTab } from "components/InventoryTab";
import { CustomerTab } from "components/CustomerTab";
import { CustomerForm } from "components/CustomerForm";
import { DeviceTab } from "components/DeviceTab";
import { DeviceForm } from "components/DeviceForm";
import { ServiceTab } from "components/ServiceTab";
import { ServiceForm } from "components/ServiceForm";
import { SettingTab } from "components/SettingTab";
import { SettingTabEmployee } from "components/SettingTabEmployee";
import { SettingTabDropdownManagement } from "components/SettingTabDropdownManagement";
import { NotificationTab } from "components/NotificationTab";
import { PasswordReset } from "components/PasswordReset";
import { CalendarTab } from "components/CalendarTab";
import { SettingTabEmployeeForm } from "components/SettingTabEmployeeForm";
import { SettingTabDropdownForm } from "components/SettingTabDropdownForm";
import { SettingTabLicenseForm } from "components/SettingTabLicenseForm";
import { SettingTabLicense } from "components/SettingTabLicense";

function App() {
  const location = useLocation();
  const current_url_path = location.pathname;
  return (
    <div
      className={
        current_url_path === "/" || current_url_path === "/reset-password"
          ? "login-theme"
          : "main-home"
      }
    >
      <Routes>
        <Route path="home" element={<MainHome />}>
          <Route path="dashboard" element={<DashboardTab />} />
          <Route path="customer/form" element={<CustomerForm />} />
          <Route path="customer" element={<CustomerTab />} />
          <Route path="device/form" element={<DeviceForm />} />
          <Route path="device" element={<DeviceTab />} />
          <Route path="inventory" element={<InventoryTab />} />
          <Route path="service/form" element={<ServiceForm />} />
          <Route path="service" element={<ServiceTab />} />
          <Route path="calendar" element={<CalendarTab />} />
          <Route path="calendar/:id" element={<CalendarTab />} />
          <Route path="setting" element={<SettingTab />}>
            <Route index element={<Navigate to="employee" />} />
            <Route path="employee/form" element={<SettingTabEmployeeForm />} />
            <Route path="employee" element={<SettingTabEmployee />} />
            <Route path="dropdown-management/form" element={<SettingTabDropdownForm />} />
            <Route path="dropdown-management" element={<SettingTabDropdownManagement />} />
            <Route path="license/form" element={< SettingTabLicenseForm />} />
            <Route path="license" element={<SettingTabLicense />} />
          </Route>
          <Route path="notification" element={<NotificationTab />} />
        </Route>
        <Route path="/" element={<Login />} />
        <Route path="/reset-password" element={<PasswordReset />} />
        <Route path="/*" element={<header>PAGE NOT FOUND</header>} />
      </Routes>
    </div>
  );
}

export default App;
