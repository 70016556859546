import React, { useEffect, useRef, useState } from "react";
import Comment from "db/comment";
import { convertUTCToESTFormatted } from "utils/utils";

import Customer from "db/customer";
import { Card } from "primereact/card";
import { Image } from "primereact/image";
import { Galleria } from "primereact/galleria";

export const CommonPhotosComponent = ({ entityID }) => {
  const [photos, setPhotos] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const galleria = useRef(null);

  const fetchAllPhotos = async () => {
    const { data } = await Customer.getCustomerPhotosByID(entityID);
    if (data) {
      let tempData = data;
      for (let i = 0; i < tempData.length; i++) {
        const comm = tempData[i];
        if (comm.photokey) {
          const url = await Comment.getPhotoURL(comm.photokey);
          tempData[i] = {
            ...comm,
            // @ts-ignore -- this is ok.
            photoUrl: url,
          };
        }
      }
      setPhotos(tempData);
    }
  };
  useEffect(() => {
    fetchAllPhotos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="relative flex h-full flex-col items-center space-y-6 rounded-b-lg bg-white p-6 shadow">
      {(photos ?? []).length === 0 ? (
        <div className="mx-auto w-full rounded-lg bg-white px-10 py-4 sm:w-3/4 lg:w-3/4">
          <div className="flex flex-col items-center justify-center py-3">
            <h1 className="mt-4 text-center text-2xl font-semibold text-gray-700">
              No Photos
            </h1>
            <p className="mt-2 text-center text-gray-500">
              Photos are from all of the services on this customer.
            </p>
          </div>
        </div>
      ) : (
        <div className="no-scrollbar overflow-wrap flex h-auto w-11/12 w-full flex-row flex-wrap items-start gap-3">
          <Galleria
            className="photo-gallery"
            ref={galleria}
            value={photos}
            activeIndex={activeIndex}
            onItemChange={(e) => setActiveIndex(e.index)}
            circular
            fullScreen
            showItemNavigators
            showThumbnails={false}
            item={(item) => {
              return (
                <img
                  src={item.photoUrl}
                  alt={`Taken by ${item?.name}`}
                  style={{ width: "50%", maxHeight: "90%", display: "block" }}
                />
              );
            }}
          />
          {photos.map((photo, index) => {
            const extension = photo.photoUrl.split(".").pop().toLowerCase();

            return (
              <Card
                className="h-90 w-80"
                key={index}
                footer={
                  <p className="text-sm">
                    {convertUTCToESTFormatted(photo.created_at)}
                  </p>
                }
                title={<p className="text-base">{photo.name}</p>}
                subTitle={<p className="text-sm">Service #{photo.id}</p>}
              >
                {["mp4", "avi", "mov", "wmv", "mkv"].includes(extension) ? (
                  <video className="h-auto" controls>
                    <source src={photo.photoUrl} key={index} />
                  </video>
                ) : (
                  <Image
                    className="hover:cursor-pointer"
                    imageClassName="h-11/12"
                    src={photo.photoUrl}
                    onClick={() => {
                      setActiveIndex(index);
                      galleria.current.show();
                    }}
                  />
                )}
              </Card>
            );
          })}
        </div>
      )}
    </div>
  );
};
