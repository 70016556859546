import { AppContext } from "AppContext";
import React, { useContext, useState, useEffect } from "react";
import { FaArrowRight } from "react-icons/fa";
import { BiSubdirectoryRight, BiLogOutCircle } from "react-icons/bi";
import { MdNotifications, MdNotificationsActive } from "react-icons/md";
import logo from "../images/logo.png";
import { useLocation, useNavigate } from "react-router-dom";
import { supabase } from "utils/supabaseClient";
import { SIDE_NAV_MENU_ITEMS } from "utils/utils";
import { FiMenu } from "react-icons/fi";
import { Fragment } from "react";
import { useGetCurrentEmployeeInfo } from "hooks/useGetCurrentEmployee";

export const SideNavBar = () => {
  const { loading, is_admin, can_edit_inventory } = useGetCurrentEmployeeInfo();
  const {
    currentUser,
    setCurrentUser,
    notificationCount,
    fetchNotificationCount,
    setServiceTableFilter,
    setCustomerTableFilter,
    setDeviceTableFilter,
  } = useContext(AppContext);
  const navigate = useNavigate();
  const location = useLocation();
  const current_url_path = location.pathname;
  const [currentPath, setCurrentPath] = useState(current_url_path);
  const [isOpen, setIsOpen] = useState(true);
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const menuItem = SIDE_NAV_MENU_ITEMS;
  const onUserSignOut = async () => {
    // You can't log out a user when user is not logged in.
    // weird how supabase api doesn't throw error when this happens.
    // Manually check user is currently logged in before signing them out to avoid confusion.
    // const {
    //   data: { user },
    // } = await supabase.auth.getUser();
    // if (user == null) {
    //   setCurrentUser(null);
    //   navigate("/");
    // }
    setCurrentUser(null);
    await supabase.auth.signOut();
    navigate("/");
  };

  supabase
    .channel("notification")
    .on(
      "postgres_changes",
      { event: "INSERT", schema: "public", table: "notification" },
      fetchNotificationCount,
    )
    .subscribe();

  supabase
    .channel("notification")
    .on(
      "postgres_changes",
      { event: "UPDATE", schema: "public", table: "notification" },
      fetchNotificationCount,
    )
    .subscribe();

  useEffect(() => {
    if (currentUser) {
      fetchNotificationCount();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  // Reset search input ONLY
  useEffect(() => {
    setServiceTableFilter((prevFilters) => {
      if (prevFilters) {
        return { ...prevFilters, searchInput: "" };
      } else {
        return null;
      }
    });
    setCustomerTableFilter((prevFilters) => {
      if (prevFilters) {
        return { ...prevFilters, searchInput: "" };
      } else {
        return null;
      }
    });
    setDeviceTableFilter((prevFilters) => {
      if (prevFilters) {
        return { ...prevFilters, searchInput: "" };
      } else {
        return null;
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current_url_path]);

  return (
    !loading && (
      <div className="flex">
        <div className="lg:hidden">
          <button
            className="border-1 absolute left-4 top-4 cursor-pointer rounded border-solid border-white bg-[#1d2026] text-gray-600 hover:text-gray-900"
            onClick={() => setMobileNavOpen(true)}
          >
            <FiMenu color="white" size={30} />
          </button>
          {/* MOBILE SIDE BAR. HIDDEN ON LARGE VIEWPORT */}
          {mobileNavOpen && (
            <div
              className={`absolute flex h-screen w-[70vw] flex-col items-stretch bg-[#1d2026] p-3`}
            >
              <div className="w-100">
                <img
                  src={logo}
                  className="App-logo m-auto"
                  alt="logo"
                  width={50}
                />
              </div>
              <ul className="pl-0">
                {menuItem.map((menu, index) =>
                  is_admin === false && menu.admin_only === true ? (
                    <Fragment key={"mobile" + index}></Fragment>
                  ) : can_edit_inventory === false &&
                    is_admin === false &&
                    menu.need_inventory_permission === true ? (
                    <Fragment key={"mobile" + index}></Fragment>
                  ) : (
                    <Fragment key={"mobile" + index}>
                      <li
                        onClick={() => {
                          setCurrentPath(menu.path);
                          navigate(menu.path);
                          setMobileNavOpen(false);
                        }}
                        className={`mt-2 flex cursor-pointer items-center gap-x-4 rounded-md p-1 text-lg text-gray-300 hover:bg-[#fff] ${
                          currentPath.includes(menu.path) && "bg-[#fff]"
                        } `}
                      >
                        <div className="p-1">{menu.icon}</div>
                        <span className={`origin-left duration-200`}>
                          {menu.name}
                        </span>
                      </li>
                      {(menu?.subtabs ?? []).length !== 0 &&
                        isOpen &&
                        currentPath.includes(menu.path) && (
                          <ul>
                            {(menu?.subtabs ?? []).map((subtab, i) => (
                              <li
                                key={"mobile" + subtab.name + i}
                                onClick={() => {
                                  setCurrentPath(menu.path + "/" + subtab.path);
                                  navigate(menu.path + "/" + subtab.path);
                                  setMobileNavOpen(false);
                                }}
                                className={`mt-2 flex cursor-pointer items-center gap-x-4 rounded-md p-1 pl-0 text-lg text-gray-300 hover:bg-[#fff] ${
                                  !isOpen && "justify-center"
                                } ${
                                  (currentPath.includes(
                                    menu.path + "/" + subtab.path,
                                  ) ||
                                    (currentPath === menu.path && i === 0)) &&
                                  "bg-[#fff]"
                                } `}
                              >
                                <div>
                                  <BiSubdirectoryRight />
                                </div>
                                {subtab.name}
                              </li>
                            ))}
                          </ul>
                        )}
                    </Fragment>
                  ),
                )}
              </ul>
              <hr className="w-full text-gray-300"></hr>
              <li
                onClick={() => {
                  setCurrentPath("/home/notification");
                  navigate("/home/notification");
                  setMobileNavOpen(false);
                }}
                className={`mt-3 flex cursor-pointer items-center gap-x-4 rounded-md p-1 text-xl text-gray-300 hover:bg-[#fff]
            ${!isOpen && "justify-center"} ${
              currentPath.includes("notification") && "bg-[#fff]"
            }
          `}
              >
                <div>
                  {notificationCount > 0 ? (
                    <MdNotificationsActive
                      size="25"
                      className="text-yellow-300"
                    />
                  ) : (
                    <MdNotifications size="25" />
                  )}
                </div>
                <span
                  className={`${!isOpen && "hidden"} origin-left duration-200`}
                >
                  Notification
                </span>
                <div
                  className={`flex w-full ${
                    (!isOpen || notificationCount === 0) && "hidden"
                  } justify-end`}
                >
                  <div className="rounded-md bg-gray-600 px-2 py-1 text-sm">
                    {notificationCount}
                  </div>
                </div>
              </li>
              <button
                className={`mt-3 flex w-full items-center gap-x-4 rounded-md bg-red-500 px-2 py-1 text-xl text-white hover:bg-red-700 ${
                  !isOpen && "justify-center"
                }`}
                onClick={() => onUserSignOut()}
              >
                <div>
                  <BiLogOutCircle color="white" size="25" />
                </div>
                <span
                  className={`${!isOpen && "hidden"} origin-left duration-200`}
                >
                  Log Out
                </span>
              </button>
              <FaArrowRight
                className={`absolute bottom-10 right-6 cursor-pointer rounded-full border-black ${
                  isOpen && "rotate-180"
                }`}
                color="white"
                size="30"
                onClick={() => setMobileNavOpen(!isOpen)}
              />
            </div>
          )}
        </div>

        {/* WEB VIEWPORT */}

        <div
          className={`flex flex-col items-stretch ${
            isOpen ? "w-[20vw] xl:w-[16vw]" : "w-[13vw] xl:w-[5vw]"
          } relative hidden h-screen bg-[#1d2026] duration-300 lg:block ${
            (isOpen && "p-3") || "px-2 py-3"
          }`}
        >
          <div className="w-100">
            <img src={logo} className="App-logo m-auto" alt="logo" width={70} />
            <div className="mt-4 rounded-lg bg-gray-700 px-3 py-2 text-center">
              <div className="flex justify-center gap-2">
                <p className="text-sm font-bold text-gray-300">
                  Hi, {currentUser?.user_metadata?.first_name}{" "}
                  {currentUser?.user_metadata?.last_name}
                </p>
              </div>

              {isOpen && (
                <p className="text-sm text-gray-300">{currentUser?.email}</p>
              )}
            </div>
          </div>
          <ul className="pl-0 pt-2">
            {menuItem.map((menu, index) =>
              is_admin === false && menu.admin_only === true ? (
                <Fragment key={menu.name + index}></Fragment>
              ) : can_edit_inventory === false &&
                is_admin === false &&
                menu.need_inventory_permission === true ? (
                <Fragment key={menu.name + index}></Fragment>
              ) : (
                <Fragment key={menu.name + index}>
                  <li
                    onClick={() => {
                      setCurrentPath(menu.path);
                      navigate(menu.path);
                    }}
                    className={`mt-3 flex cursor-pointer items-center gap-x-4 rounded-md p-2 text-xl text-gray-300 hover:bg-[#fff] ${
                      !isOpen && "justify-center"
                    } ${currentPath.includes(menu.path) && "bg-[#fff]"} `}
                  >
                    <div>{menu.icon}</div>
                    <span
                      className={`${
                        !isOpen && "hidden"
                      } origin-left duration-200`}
                    >
                      {menu.name}
                    </span>
                  </li>
                  {(menu?.subtabs ?? []).length !== 0 &&
                    isOpen &&
                    currentPath.includes(menu.path) && (
                      <ul>
                        {(menu?.subtabs ?? []).map((subtab, i) => (
                          <li
                            key={subtab.name + i}
                            onClick={() => {
                              setCurrentPath(menu.path + "/" + subtab.path);
                              navigate(menu.path + "/" + subtab.path);
                            }}
                            className={`mt-3 flex cursor-pointer items-center gap-x-4 rounded-md p-2 pl-6 text-xl text-gray-300 hover:bg-[#fff] ${
                              !isOpen && "justify-center"
                            } ${
                              (currentPath.includes(
                                menu.path + "/" + subtab.path,
                              ) ||
                                (currentPath === menu.path && i === 0)) &&
                              "bg-[#fff]"
                            } `}
                          >
                            <div>
                              <BiSubdirectoryRight />
                            </div>
                            {subtab.name}
                          </li>
                        ))}
                      </ul>
                    )}
                </Fragment>
              ),
            )}
          </ul>
          <hr className="w-full text-gray-300"></hr>
          <li
            onClick={() => {
              setCurrentPath("/home/notification");
              navigate("/home/notification");
            }}
            className={`my-3 flex cursor-pointer items-center gap-x-4 rounded-md p-2 text-xl text-gray-300 hover:bg-[#fff]
            ${!isOpen && "justify-center"} ${
              currentPath.includes("notification") && "bg-[#fff]"
            }
          `}
          >
            <div>
              {notificationCount > 0 ? (
                <MdNotificationsActive size="25" className="text-yellow-300" />
              ) : (
                <MdNotifications size="25" />
              )}
            </div>
            <span className={`${!isOpen && "hidden"} origin-left duration-200`}>
              Notification
            </span>
            <div
              className={`flex w-full ${
                (!isOpen || notificationCount === 0) && "hidden"
              } justify-end`}
            >
              <div className="rounded-md bg-gray-600 px-2 py-1 text-sm">
                {notificationCount}
              </div>
            </div>
          </li>
          <button
            className={`flex w-full items-center gap-x-4 rounded-md bg-red-500 p-2 py-2 text-xl text-white hover:bg-red-700 ${
              !isOpen && "justify-center"
            }`}
            onClick={() => onUserSignOut()}
          >
            <div>
              <BiLogOutCircle color="white" size="30" />
            </div>
            <span className={`${!isOpen && "hidden"} origin-left duration-200`}>
              Log Out
            </span>
          </button>
          <FaArrowRight
            className={`absolute bottom-4 right-4 cursor-pointer rounded-full border-black ${
              isOpen && "rotate-180"
            }`}
            color="white"
            size="30"
            onClick={() => setIsOpen(!isOpen)}
          />
        </div>
      </div>
    )
  );
};
