import { supabase } from "utils/supabaseClient";

export default class UtilQueries {
  static async getCustomersCountByState() {
    // this is a supabase function. Easy to run analysis queries in the backend then aggregate on frontend.
    return await supabase.rpc("get_customer_count_by_state");
  }

  static async getServiceGroupedByYearAndMonth() {
    return await supabase.rpc("get_service_count_grouped_by_year_and_month");
  }

  static async getCustomersWithOldPCIDate() {
    return await supabase.rpc("get_customers_with_old_pci");
  }
}
