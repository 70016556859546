import { supabase } from "utils/supabaseClient";

const DB_NAME = "license";

export default class License {
    static async getAllData() {
        return await supabase
            .from(DB_NAME)
            .select(`
                *,
                license_type (
                    id,
                    value
                ),
                customer(
                    id,
                    name
                )
            `)
            .order("created_at", { ascending: false });
    }

    static async getLicenseByID(id) {
        return await supabase
            .from(DB_NAME)
            .select("*")
            .eq("id", id);
    }
    static async upsertLicense(license) {
        if (!license.is_active) {
            license.activated_at = null;
        }
        return await supabase.from(DB_NAME).upsert(license).select();
    }
}
