import React, { useEffect, useState } from "react";
import { StatusBadgeWithColor } from "utils/utils";
import { MiniTable } from "./MiniTable";
import Device from "db/device";

// Device/Customer can have multiple services.

const tableColumns = [
  { key: "id", header: "#" },
  { key: "model", header: "Model", cell: (row) => row.model?.value },
  { key: "serial_number", header: "Serial #" },
  { key: "terminal_id", header: "Terminal ID" },
  { key: "assigned_number", header: "Assigned #" },
  {
    key: "status",
    header: "Status",
    cell: (row) => <StatusBadgeWithColor status={row?.status?.value} />,
  },
];

export const CommonDeviceComponent = ({ entityType, entityID }) => {
  const [deviceList, setDeviceList] = useState([]);

  const fetchDeviceOnCurrentEntity = async () => {
    const { data } = await Device.getDevicesByCustomerID(entityID);

    if (data) {
      setDeviceList(data);
    }
  };

  useEffect(() => {
    if (entityType === "customer") {
      fetchDeviceOnCurrentEntity();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="relative flex h-full w-full flex-col items-center space-y-6 overflow-x-auto rounded-b-lg bg-white p-6 shadow">
      <MiniTable
        tableColumns={tableColumns}
        tableRows={deviceList}
        type="device"
      />
    </div>
  );
};
