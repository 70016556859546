import React, {
  useCallback,
  useState,
  useMemo,
  useEffect,
  useRef,
} from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Calendar, Views, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { AiOutlinePlus } from "react-icons/ai";
import Event from "db/event";
import Employee from "db/employee";
import { CalendarModal } from "./CalendarModal";
import Holidays from 'date-holidays';

const localizer = momentLocalizer(moment);

export const CalendarTab = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const current_url_path = location.pathname;
  const today = new Date();
  const clickRef = useRef(null);
  const [events, setEvents] = useState([]);
  const [holidays, setHolidays] = useState([]);
  const [birthdays, setBirthdays] = useState([]);
  const defaultDate = useMemo(() => new Date(), []);
  const eventPropGetter = useCallback(
    (event, start, end, isSelected) => ({
      ...(event.type === "timeoff" && {
        style: { backgroundColor: "#FF5C5C" },
      }),
      ...(event.type === "holiday" && {
        style: { backgroundColor: "#008000" },
      }),
      ...(event.type === "birthday" && {
        style: { backgroundColor: "#8CC493" },
      }),
      ...(event.completed && {
        style: { textDecoration: "line-through", opacity: "0.5" },
      })
    }),
    [],
  );

  const [isModalOpen, setIsModalOpen] = useState({
    isOpen: false,
    modalType: "", // "add" | "edit" | "view"
    id: -1,
    start: new Date(today.getFullYear(), today.getMonth(), today.getDate()),
    end: new Date(today.getFullYear(), today.getMonth(), today.getDate()),
  });

  const fetchEvents = async () => {
    const { data } = await Event.getAllData();

    if (data) {
      var handleTime = data.map((d) => {
        d.start = new Date(d.start);
        d.end = new Date(d.end);
        d.allDay = false;
        return d;
      });
      setEvents(handleTime);
    }
  };

  const fetchHolidays = (year) => {
    const hd = new Holidays('US');
    const data = hd.getHolidays(year);
    setHolidays(data.map((d, index) => {
      return {
        address: "",
        assigned: [],
        creator: "",
        customer: "",
        start: d.start,
        end: d.start,
        id: `hd${index}`,
        title: d.name,
        type: "holiday"
      }
    }));
  };

  const fetchDOB = async (year) => {
    const { data } = await Employee.getEmployeeDOB();
    setBirthdays(data.map((d, index) => {
      var birthday = new Date(d.birthday);
      var newDate = new Date(year, birthday.getMonth(), birthday.getDate() + 1);
      return {
        address: "",
        assigned: [],
        creator: "",
        customer: "",
        start: newDate,
        end: newDate,
        id: `bd${index}`,
        title: `${d.first_name}'s B-Day 🎂`,
        type: "birthday"
      }
    }));
  }

  useEffect(() => {
    fetchEvents();
    fetchHolidays(today.getFullYear());
    fetchDOB(today.getFullYear());

    if (id != null) {
      setIsModalOpen({
        isOpen: true,
        id: id,
        modalType: "edit",
      });
    } else {
      setIsModalOpen({
        isOpen: false,
        modalType: "", // "add" | "edit" | "view"
        id: -1,
        start: new Date(today.getFullYear(), today.getMonth(), today.getDate()),
        end: new Date(today.getFullYear(), today.getMonth(), today.getDate()),
      });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    /**
     * What Is This?
     * This is to prevent a memory leak, in the off chance that you
     * teardown your interface prior to the timed method being called.
     */
    return () => {
      window.clearTimeout(clickRef?.current);
    };
  }, []);

  const resetModal = () => {
    var dateWithoutTime = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
    );
    setIsModalOpen({
      isOpen: false,
      modalType: "",
      id: -1,
      start: dateWithoutTime,
      end: dateWithoutTime,
      type: "",
    });
  };

  const onSelectEvent = useCallback((calEvent) => {
    /**
     * Here we are waiting 250 milliseconds (use what you want) prior to firing
     * our method. Why? Because both 'click' and 'doubleClick'
     * would fire, in the event of a 'doubleClick'. By doing
     * this, the 'click' handler is overridden by the 'doubleClick'
     * action.
     */
    window.clearTimeout(clickRef?.current);
    clickRef.current = window.setTimeout(() => {
      if (["service", "timeoff"].indexOf(calEvent.type) !== -1) {
        navigate(current_url_path + "/" + calEvent.id);
        setIsModalOpen({
          currentEvent: calEvent,
          isOpen: true,
          modalType: "edit",
          id: calEvent.id,
          start: "",
          end: "",
          type: calEvent.type,
        });
      }

    }, 250);
    // eslint-disable-next-line
  }, []);

  const onSelectSlot = useCallback((slotInfo) => {
    /* Notice our use of the same ref as above. */
    window.clearTimeout(clickRef?.current);
    clickRef.current = window.setTimeout(() => {
      setIsModalOpen({
        isOpen: true,
        modalType: "add",
        id: -1,
        start: moment(slotInfo.start).format().substring(0, 16),
        end: moment(slotInfo.end.getTime() - 86400000).format().substring(0, 16),
        type: "service",
      });
    }, 250);
  }, []);

  const onNavigate = useCallback((date) => {
    var yearOnView = new Date(date).getFullYear();
    fetchHolidays(yearOnView);
    fetchDOB(yearOnView);
  }, []);

  return (
    <div className="m-2 lg:m-4">
      <div className="mb-1 flex items-center justify-between">
        <div className="mb-3 flex items-center gap-3">
          <h1 className="title mb-0">Calendar</h1>
          {setIsModalOpen != null && (
            <button
              className="flex items-center gap-2 rounded-full border-2 border-gray-300 bg-gray-100 px-3 py-2"
              onClick={() => {
                setIsModalOpen((prev) => {
                  return {
                    ...prev,
                    isOpen: true,
                    modalType: "add",
                    type: "service",
                  };
                });
              }}
            >
              <AiOutlinePlus size={20} />
              <span className="hidden md:block">New</span>Service
            </button>
          )}
          {setIsModalOpen != null && (
            <button
              className="flex items-center gap-2 rounded-full border-2 border-gray-300 bg-gray-100 px-3 py-2"
              onClick={() => {
                setIsModalOpen((prev) => {
                  return {
                    ...prev,
                    isOpen: true,
                    modalType: "add",
                    type: "timeoff",
                  };
                });
              }}
            >
              <AiOutlinePlus size={20} />
              <span className="hidden md:block">New</span>PTO
            </button>
          )}
        </div>
      </div>
      <div>
        <Calendar
          defaultDate={defaultDate}
          defaultView={Views.MONTH}
          events={[...events, ...holidays, ...birthdays]}
          localizer={localizer}
          eventPropGetter={eventPropGetter}
          onSelectEvent={onSelectEvent}
          onSelectSlot={onSelectSlot}
          onNavigate={onNavigate}
          popup
          selectable
          style={{ height: "90vh" }}
          endAccessor={({ end }) => new Date(end.getTime() + 1000)}
        />
      </div>
      {isModalOpen.isOpen && (
        <CalendarModal
          resetModal={resetModal}
          isModalOpen={isModalOpen}
          fetchEvents={fetchEvents}
        />
      )}
    </div>
  );
};
