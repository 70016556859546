import React, { useState, useEffect } from "react";
import Customer from "db/customer";
import Device from "db/device";
import DropdownField from "db/dropdownField";
import { FiEdit } from "react-icons/fi";
import { FaRegComments } from "react-icons/fa";
import { MdSupportAgent } from "react-icons/md";
import { GoHistory } from "react-icons/go";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CommonCommentsComponent } from "../shared/CommonCommentsComponent";
import { CommonHistoryComponent } from "shared/CommonHistoryComponent";
import { CommonServiceComponent } from "shared/CommonServiceComponent";
import { useGetCurrentEmployeeInfo } from "hooks/useGetCurrentEmployee";
import { useToast } from "hooks/useToast";
import { Dropdown } from "primereact/dropdown";
import { useGetFrequentlyUsedValues } from "hooks/useGetFrequentlyUsedValues";

const DEVICE_MODAL_TABS = [
  { name: "Main", icon: <FiEdit size={20} /> },
  { name: "Service", icon: <MdSupportAgent size={20} /> },
  { name: "Comments", icon: <FaRegComments size={20} /> },
  { name: "History", icon: <GoHistory size={20} /> },
];

const initialDeviceVal = {
  customer: "",
  model: "",
  serial_number: "",
  order_number: "",
  purchase_date: "",
  status: "",
  terminal_id: "",
  assigned_number: "",
  encryption: "",
  inactive: false,
};

export const DeviceForm = () => {
  const [params, setSearchParam] = useSearchParams();
  const formType = params.get("type");
  const id = params.get("id");
  const numOfTabChange = Number(params.get("back")) || 0;
  const navigate = useNavigate();
  const [device, setDevice] = useState(initialDeviceVal);
  // const [activeCustomers, setActiveCustomers] = useState([]);
  const [activeModels, setActiveModels] = useState([]);
  const [activeEncryptions, setActiveEncryption] = useState([]);
  // const [activeDeviceStatus, setActiveDeviceStatus] = useState([]);
  const { Customers, DeviceStatus } = useGetFrequentlyUsedValues({});
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [currentTab, setCurrentTab] = useState(
    params.get("tab") || DEVICE_MODAL_TABS[0].name,
  );
  const { is_admin, can_edit_inventory } = useGetCurrentEmployeeInfo();
  const { showSuccess, showError } = useToast();

  // const fetchActiveCustomer = async () => {
  //   const { data } = await Customer.getCustomerNameList();
  //   if (data) setActiveCustomers(data);
  // };

  const fetchActiveModels = async () => {
    const { data } = await DropdownField.getActiveDataByType("Device Model");
    if (data) setActiveModels(data);
  };

  const fetchActiveEncryption = async () => {
    const { data } = await DropdownField.getActiveDataByType("Encryption");
    if (data) setActiveEncryption(data);
  };

  // const fetchActiveDeviceStatus = async () => {
  //   const { data } = await DropdownField.getActiveDataByType("Device Status");
  //   if (data) setActiveDeviceStatus(data);
  // };

  const fetchCurrentDevice = async () => {
    const { data } = await Device.getDeviceByID(id);
    if (data) setDevice(data[0]);
  };

  const getSelectedCustomer = async (id) => {
    const { data } = await Customer.getCustomerById(id);
    if (data) setSelectedCustomer(data[0]);
  };

  const handleDelete = async () => {
    const data = await Device.deleteDevice(id);

    if (data.error) {
      console.log(data.error);
      showError();
    } else {
      showSuccess("Success!", "Device is deleted.");
      navigate(-1 - numOfTabChange);
    }
  }

  useEffect(() => {
    // fetchActiveCustomer();
    fetchActiveModels();
    fetchActiveEncryption();
    // fetchActiveDeviceStatus();
    fetchCurrentDevice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (device?.customer?.id || device?.customer) {
      getSelectedCustomer(device?.customer?.id || device?.customer);
    } else {
      setSelectedCustomer(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [device?.customer]);

  const handleInputChange = (e) => {
    const { name, value, checked } = e.target;
    if (name === "inactive") {
      setDevice({
        ...device,
        [name]: checked,
      });
    } else {
      setDevice({
        ...device,
        [name]: value === undefined ? "" : value,
      });
    }
  };

  const onFormSubmit = async (e) => {
    e.preventDefault();
    const data = await Device.upsertDevice(device);

    if (data.data) {
      if (formType === "add") {
        showSuccess("Creation successful!", "New device was created.");
      } else {
        showSuccess(
          "Edit successful!",
          "Your changes were saved successfully.",
        );
      }

      //navigate("/home/device");
      navigate(-1 - numOfTabChange); // Goes back to where it came from
    }

    if (data.error) {
      showError("Error", data.error.message);
    }
  };

  const deviceForm = () => {
    return (
      <form onSubmit={onFormSubmit}>
        <div className="flex items-center justify-end space-x-6 rounded-b border-t border-gray-200 px-3 pb-0 pt-3">
          <button
            type="button"
            hidden={!is_admin || formType !== "edit"}
            disabled={!is_admin || formType !== "edit"}
            className="rounded-lg bg-slate-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-slate-800 focus:outline-none focus:ring-4 focus:ring-slate-300 disabled:bg-gray-300"
            onClick={() => handleDelete()}
          >
            Delete
          </button>
          <button
            type="button"
            className="hover:bg-reed-800 rounded-lg bg-red-500 px-5 py-2.5 text-center text-sm font-medium text-white focus:outline-none focus:ring-4 focus:ring-red-300"
            onClick={() => navigate(-1 - numOfTabChange)}
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={!is_admin && !can_edit_inventory}
            title={
              !is_admin && !can_edit_inventory
                ? "You need admin or edit inventory access to edit device"
                : null
            }
            className="rounded-lg bg-blue-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 disabled:bg-gray-300"
          >
            Save
          </button>
        </div>
        <fieldset disabled={!is_admin && !can_edit_inventory}>
          <div className="space-y-6 p-6">
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6 sm:col-span-2">
                <label className="mb-2 block text-sm font-medium text-gray-900">
                  Customer
                </label>
                <Dropdown
                  value={device?.customer?.id || device?.customer || ""}
                  onChange={handleInputChange}
                  placeholder="Select Customer"
                  name="customer"
                  options={Customers.filter(c => !c.inactive || device?.customer?.id === c.id)}
                  optionLabel="name"
                  optionValue="id"
                  showClear
                  valueTemplate={(option, props) => {
                    if (option) {
                      return <div>{option?.name}</div>;
                    }

                    return <span>{props.placeholder}</span>;
                  }}
                  itemTemplate={(option) => {
                    return <div>{option?.name}</div>;
                  }}
                  scrollHeight="300px"
                  filter
                  className="w-full rounded-lg border border-gray-300 bg-gray-50 text-sm text-gray-900 shadow-sm focus:border-blue-600 focus:ring-blue-600"
                />
                {/* <select
                  className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 shadow-sm focus:border-blue-600 focus:ring-blue-600"
                  required={false}
                  name="customer"
                  value={!device.customer ? "" : device.customer.id}
                  onChange={handleInputChange}
                >
                  <option value="">Select Customer</option>
                  {activeCustomers.map((e) => {
                    return (
                      <option key={e.id} value={e.id}>
                        {e.name}
                      </option>
                    );
                  })}
                </select> */}
              </div>
              <div className="col-span-6 sm:col-span-2">
                <label className="mb-2 block text-sm font-medium text-gray-900">
                  Model *
                </label>
                <select
                  className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 shadow-sm focus:border-blue-600 focus:ring-blue-600"
                  required={true}
                  name="model"
                  value={!device.model ? "" : device.model.id}
                  onChange={handleInputChange}
                  disabled={!is_admin}
                >
                  <option value="" disabled>
                    Select Model
                  </option>
                  {activeModels.map((m) => {
                    return (
                      <option key={m.id} value={m.id}>
                        {m.value}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="col-span-6 sm:col-span-2">
                <label className="mb-2 block text-sm font-medium text-gray-900">
                  Serial # *
                </label>
                <input
                  type="text"
                  className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 shadow-sm focus:border-blue-600 focus:ring-blue-600"
                  placeholder="FD123456789"
                  required={true}
                  name="serial_number"
                  value={!device ? "" : device.serial_number}
                  onChange={handleInputChange}
                  disabled={!is_admin}
                />
              </div>
              <div className="col-span-6 sm:col-span-2">
                <label className="mb-2 block text-sm font-medium text-gray-900">
                  Order #
                </label>
                <input
                  type="text"
                  className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 shadow-sm focus:border-blue-600 focus:ring-blue-600"
                  required={false}
                  name="order_number"
                  value={!device ? "" : device.order_number}
                  onChange={handleInputChange}
                  disabled={!is_admin}
                />
              </div>
              <div className="col-span-6 sm:col-span-2">
                <label className="mb-2 block text-sm font-medium text-gray-900">
                  Purchase Date
                </label>
                <input
                  type="date"
                  className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 shadow-sm focus:border-blue-600 focus:ring-blue-600"
                  required={false}
                  name="purchase_date"
                  value={!device.purchase_date ? "" : device.purchase_date}
                  onChange={handleInputChange}
                  disabled={!is_admin}
                />
              </div>
              <div className="col-span-6 sm:col-span-2">
                <label className="mb-2 block text-sm font-medium text-gray-900">
                  Status
                </label>
                <select
                  className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 shadow-sm focus:border-blue-600 focus:ring-blue-600"
                  required={false}
                  name="status"
                  value={!device.status ? "" : device.status.id}
                  onChange={handleInputChange}
                >
                  <option value="">
                    Select Status
                  </option>
                  {DeviceStatus.filter(ds => !ds.inactive || device?.status?.id === ds.id).map((s) => {
                    return (
                      <option key={s.id} value={s.id}>
                        {s.value}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="col-span-6 sm:col-span-2">
                <label className="mb-2 block text-sm font-medium text-gray-900">
                  Encryption
                </label>
                <select
                  className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 shadow-sm focus:border-blue-600 focus:ring-blue-600"
                  name="encryption"
                  value={!device.encryption ? "" : device.encryption.id}
                  onChange={handleInputChange}
                  disabled={!is_admin && !can_edit_inventory}
                >
                  <option value="">Select Encryption</option>
                  {activeEncryptions.map((m) => {
                    return (
                      <option key={m.id} value={m.id}>
                        {m.value}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="col-span-6 sm:col-span-2">
                <label className="mb-2 block text-sm font-medium text-gray-900">
                  Terminal #
                </label>
                <input
                  type="number"
                  className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 shadow-sm focus:border-blue-600 focus:ring-blue-600"
                  placeholder="1234567890"
                  value={!device.terminal_id ? "" : device.terminal_id}
                  name="terminal_id"
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-span-6 sm:col-span-2">
                <label className="mb-2 block text-sm font-medium text-gray-900">
                  Assigned #
                </label>
                <input
                  type="number"
                  className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 shadow-sm focus:border-blue-600 focus:ring-blue-600"
                  placeholder="0"
                  value={!device.assigned_number ? "" : device.assigned_number}
                  name="assigned_number"
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-span-3 flex items-center sm:col-span-1">
                <input
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500"
                  name="inactive"
                  onChange={handleInputChange}
                  checked={!device ? false : device.inactive}
                />
                <label className="ml-2 text-sm font-medium text-gray-900">
                  Inactive
                </label>
              </div>
            </div>
          </div>
        </fieldset>
      </form>
    );
  };

  return (
    <div className="relative h-full max-h-full w-full align-middle">
      <div className="flex border-b bg-white p-4 shadow">
        <h1 className="subtitle md:title">
          Device {formType === "edit" ? `#${device?.id}` : ""}
        </h1>
        {formType === "edit" && (
          <div className="ml-8 flex flex-wrap gap-7">
            {DEVICE_MODAL_TABS.map((tab, index) => {
              return (
                <div
                  key={index}
                  className={`flex items-center gap-2 hover:cursor-pointer ${tab.name === currentTab ? "text-black" : "text-gray-400"}`}
                  onClick={() => {
                    setCurrentTab(DEVICE_MODAL_TABS[index].name);
                    params.set("tab", DEVICE_MODAL_TABS[index].name);
                    params.set("back", (numOfTabChange + 1).toString());
                    setSearchParam(params);
                  }}
                >
                  {tab.icon}
                  <h3 className="mb-0 hidden text-lg md:block">{tab.name}</h3>
                </div>
              );
            })}
          </div>
        )}
      </div>
      {currentTab === "Main" ? (
        deviceForm()
      ) : currentTab === "Service" ? (
        <CommonServiceComponent entityType="device" entityID={id} />
      ) : currentTab === "Comments" ? (
        <CommonCommentsComponent entityType="device" entityID={id} />
      ) : currentTab === "History" ? (
        <CommonHistoryComponent entityType="device" entityID={id} />
      ) : (
        <div className="relative flex flex-col items-center justify-center space-y-6 rounded-b-lg bg-white p-6 shadow">
          <p>component not found</p>
        </div>
      )}
      {selectedCustomer && (
        <div className="flex flex-col p-6 text-sm">
          <p className="font-bold">Customer Info - {selectedCustomer?.name}</p>
          <div className="flex gap-2">
            <p className="font-semibold">MID: </p>{" "}
            {selectedCustomer?.merchant_id}
          </div>
          <div className="flex gap-2 whitespace-nowrap">
            <p className="font-semibold">Address: </p>{" "}
            {selectedCustomer?.address}, {selectedCustomer?.city},{" "}
            {selectedCustomer?.state}, {selectedCustomer?.zip}
          </div>
          <div className="flex gap-2">
            <p className="font-semibold">Owner: </p>{" "}
            {selectedCustomer?.owner_fname} {selectedCustomer?.owner_lname}
          </div>
          <div className="flex gap-2">
            <p className="font-semibold">Store: </p>{" "}
            {selectedCustomer?.contact_store}
          </div>
          <div className="flex gap-2">
            <p className="font-semibold">Owner #1: </p>{" "}
            {selectedCustomer?.contact_owner}
          </div>
          <div className="flex gap-2">
            <p className="font-semibold">Owner #2: </p>{" "}
            {selectedCustomer?.contact_owner}
          </div>
          <div className="flex gap-2">
            <p className="font-semibold">Email: </p> {selectedCustomer?.email}
          </div>
          <div className="flex gap-2">
            <p className="font-semibold">Email #2: </p>{" "}
            {selectedCustomer?.email}
          </div>
        </div>
      )}
    </div>
  );
};
