import React, { useContext } from "react";
import Service from "db/service";
import { CommonTable } from "./CommonTable";
import { convertUTCToESTFormatted, StatusBadgeWithColor } from "utils/utils";
import { useInfiniteLoading } from "hooks/useInfiniteLoading";
import { AppContext } from "AppContext";

const tableColumns = [
  // {
  //   key: "id",
  //   header: "Service",
  //   cell: (row) => {
  //     return (
  //       <div className="flex flex-col gap-3">
  //         <div className="flex gap-2 whitespace-nowrap">
  //           <StatusBadgeWithColor status={row?.status?.value} />
  //           <p className="text-sm font-bold ">{row?.customer?.name}</p>
  //         </div>
  //         <div className="flex gap-2">
  //           <p className="font-semibold">Assigned:</p>
  //           {row?.assigned?.first_name}
  //         </div>
  //         {!(
  //           row?.device?.model?.value == null ||
  //           row?.device?.serial_number == null
  //         ) && (
  //           <div className="flex gap-2">
  //             <p className="font-semibold">Device:</p>
  //             {row?.device?.model?.value + " - " + row?.device?.serial_number}
  //           </div>
  //         )}
  //         {/* <div className="flex gap-2">
  //           <StatusBadgeWithColor status={row?.status?.value} />
  //         </div> */}
  //       </div>
  //     );
  //   },
  // },
  { key: "id", header: "#" },
  {
    key: "customer.name",
    header: "Customer",
    cell: (row) => {
      return (
        <div className="flex flex-col gap-3">
          <div className="flex gap-2 whitespace-nowrap">
            <p className="text-sm font-bold ">{row?.customer?.name}</p>
          </div>
        </div>
      );
    },
  },
  {
    key: "assigned_text",
    header: "Assigned",
    cell: (row) => {
      return (
        <div className="flex ">
          <p className="">{row?.assigned_text}</p>
        </div>
      );
    },
  },
  {
    key: "status.value",
    header: "Status",
    cell: (row) => {
      return (
        <div className="flex ">
          <StatusBadgeWithColor status={row?.status?.value} />
        </div>
      );
    },
  },
  {
    key: "request",
    header: "Request",
    cell: (row) => {
      return (
        <div className="no-scrollbar max-h-[150px] overflow-y-scroll whitespace-pre-line">
          {row?.request}
        </div>
      );
    },
    minWidth: "250px",
  },
  {
    key: "created_at",
    header: "Created Date",
    cell: (row) => {
      return (
        <div className="whitespace-nowrap">
          {convertUTCToESTFormatted(row.created_at)}
        </div>
      );
    },
  },
];

export const ServiceTab = () => {
  const { serviceTableFilter } = useContext(AppContext);

  const {
    items: services,
    hasMore,
    loadNext,
    loadingNext,
    triggerReload,
    filters,
    setFilters,
    initialPageLoaded,
  } = useInfiniteLoading({
    getItemsLazy: (start, end, lazyFilters) => {
      return Service.getServicesWithRangeAndFilter(start, end, lazyFilters);
    },
    filterValues: serviceTableFilter || {
      searchInput: "",
      inactive: null,
      customer: null,
      creator: null,
      assigned: null,
      serviceStatus: null,
      created_at: null,
    },
    name: "service",
  });

  return (
    <div>
      <div className="m-2 lg:m-5">
        <CommonTable
          tableName="Services"
          tableColumns={tableColumns}
          tableRows={services}
          requireCreateButton={true}
          requireSearch={true}
          lazyLoadRows={loadNext}
          lazyLoading={loadingNext}
          lazyHasMoreData={hasMore}
          refetchRows={triggerReload}
          tableFilter={filters}
          setTableFilter={setFilters}
          initialPageLoaded={initialPageLoaded}
        />
      </div>
    </div>
  );
};
