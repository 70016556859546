import Service from "db/service";
import React, { useEffect, useState } from "react";
import { DashboardTable } from "shared/DashboardTable";
import { LoadingSpinner } from "./LoadingSpinner";
import { IoMdRefreshCircle } from "react-icons/io";
import UtilQueries from "db/UtilQueries";
import { Chart } from "primereact/chart";
import { EntityPlatform, EntitySimple, convertUTCToESTFormatted } from "utils/utils";
import DropdownField from "db/dropdownField";
import Customer from "db/customer";
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

const TOP_10 = 10;

const NewServiceTable = (key) => {
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchServices = async () => {
    const { data } = await Service.getTopKNewestServices(TOP_10);
    if (data) {
      setServices(data);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchServices();
  }, [key]);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <DashboardTable
      tableColumns={[
        { key: "id", header: "#" },
        {
          key: "customer.name",
          header: "Customer",
          cell: (row) => row?.customer?.name,
        },
        {
          key: "assigned_text",
          header: "Assigned",
          cell: (row) => row?.assigned_text,
        },
        { key: "request", header: "Request" },
        {
          key: "created_at",
          header: "Created Date",
          cell: (row) => {
            return <div>{convertUTCToESTFormatted(row.created_at)}</div>;
          },
        },
      ]}
      tableRows={services}
      tableName="Newest Services"
      type="service"
    />
  );
};

const RecentUpdateServiceTable = (key) => {
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchServices = async () => {
    const { data } = await Service.getTopKNewestUpdatedServices(TOP_10);
    if (data) {
      setServices(data);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchServices();
  }, [key]);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <DashboardTable
      tableColumns={[
        { key: "id", header: "#" },
        {
          key: "customer.name",
          header: "Customer",
          cell: (row) => row?.customer?.name,
        },
        {
          key: "assigned_text",
          header: "Assigned",
          cell: (row) => row?.assigned_text,
        },
        { key: "request", header: "Request" },
        {
          key: "modified_at",
          header: "Updated Date",
          cell: (row) => {
            return <div>{convertUTCToESTFormatted(row.modified_at)}</div>;
          },
        },
      ]}
      tableRows={services}
      tableName="Recently Updated Services"
      type="service"
    />
  );
};

const OldestServiceFilteredByStatusTable = (key) => {
  const [services, setServices] = useState([]);
  const [serviceStatusList, setServiceStatusList] = useState([]);
  const [currentServiceStatus, setCurrentServiceStatus] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchActiveServiceStatus = async () => {
    const { data, error } =
      await DropdownField.getActiveDataByType("Service Status");
    if (data) {
      setServiceStatusList(data);
      if (data.length > 0) {
        setCurrentServiceStatus(data[0]?.id);
      }
    }
    if (error) {
      throw new Error(
        "There was an error trying to fetch service status dropdown field. Please contact the owner.",
      );
    }
  };

  const fetchServices = async () => {
    if (currentServiceStatus == null) {
      return;
    }
    const { data } = await Service.getTopKOldestServicesByStatus(
      TOP_10,
      currentServiceStatus,
    );
    if (data) {
      setServices(data);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchActiveServiceStatus();
  }, [key]);

  useEffect(() => {
    fetchServices();
  }, [currentServiceStatus]);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <DashboardTable
      tableColumns={[
        { key: "id", header: "#" },
        {
          key: "customer.name",
          header: "Customer",
          cell: (row) => row?.customer?.name,
        },
        {
          key: "assigned_text",
          header: "Assigned",
          cell: (row) => row?.assigned_text,
        },
        { key: "request", header: "Request" },
        {
          key: "created_at",
          header: "Created Date",
          cell: (row) => {
            return <div>{convertUTCToESTFormatted(row.created_at)}</div>;
          },
        },
      ]}
      tableRows={services}
      tableName="Oldest Services by Status"
      type="service"
      dropDownMenu={
        <select
          className="w-1/2 rounded-lg border border-gray-300 bg-gray-50 text-sm text-gray-900 shadow-sm focus:border-blue-600 focus:ring-blue-600 lg:w-1/3"
          required={false}
          name="status"
          value={currentServiceStatus}
          onChange={(e) => {
            const { value } = e.target;
            setCurrentServiceStatus(value);
          }}
        >
          {serviceStatusList.map((s) => {
            return (
              <option key={s.id} value={s.id}>
                {s.value}
              </option>
            );
          })}
        </select>
      }
    />
  );
};

const CustomersByStateBarChart = (key) => {
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});
  const [loading, setLoading] = useState(true);

  const fetchChartData = async () => {
    const { data } = await UtilQueries.getCustomersCountByState();
    if (data) {
      setChartData({
        labels: data.map((d) => d.state_name),
        datasets: [
          {
            label: "# of customers by States",
            data: data.map((d) => d.customer_count),
            backgroundColor: [
              "rgba(255, 159, 64, 0.2)",
              "rgba(75, 192, 192, 0.2)",
              "rgba(54, 162, 235, 0.2)",
              "rgba(153, 102, 255, 0.2)",
            ],
            borderColor: [
              "rgb(255, 159, 64)",
              "rgb(75, 192, 192)",
              "rgb(54, 162, 235)",
              "rgb(153, 102, 255)",
            ],
            borderWidth: 1,
          },
        ],
      });
      setChartOptions({
        maintainAspectRatio: false,
        scales: {
          y: {
            beginAtZero: true,
          },
        },
        plugins: {
          legend: {
            display: false,
          },
        },
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchChartData();
  }, [key]);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="flex flex-col items-center">
      <p className="subtitle mb-0 p-2 text-base font-semibold sm:text-lg">
        Customers distribution by State
      </p>
      <Chart
        type="bar"
        data={chartData}
        options={chartOptions}
        className="h-[40vh] w-full"
      />
    </div>
  );
};

const ServiceGroupedByYearMonthBarChart = (key) => {
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});
  const [loading, setLoading] = useState(true);

  const fetchChartData = async () => {
    const { data } = await UtilQueries.getServiceGroupedByYearAndMonth();
    if (data) {
      setChartData({
        labels: data.map((d) => d.year_month),
        datasets: [
          {
            label: "# of services created",
            data: data.map((d) => d.count),
            backgroundColor: [
              "rgba(255, 159, 64, 0.2)",
              "rgba(75, 192, 192, 0.2)",
              "rgba(54, 162, 235, 0.2)",
              "rgba(153, 102, 255, 0.2)",
            ],
            borderColor: [
              "rgb(255, 159, 64)",
              "rgb(75, 192, 192)",
              "rgb(54, 162, 235)",
              "rgb(153, 102, 255)",
            ],
            borderWidth: 1,
          },
        ],
      });
      setChartOptions({
        maintainAspectRatio: false,
        scales: {
          y: {
            beginAtZero: true,
          },
        },
        plugins: {
          legend: {
            display: false,
          },
        },
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchChartData();
  }, [key]);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="flex flex-col items-center">
      <p className="subtitle mb-0 p-2 text-base font-semibold sm:text-lg">
        Service creation count by year and month
      </p>
      <Chart
        type="bar"
        data={chartData}
        options={chartOptions}
        className="h-[40vh] w-full"
      />
    </div>
  );
};

const NewCustomerTable = (key) => {
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchCustomers = async () => {
    const { data } = await Customer.getTopKNewestCustomers(TOP_10);
    if (data) {
      setCustomers(data);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCustomers();
  }, [key]);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <DashboardTable
      tableColumns={[
        {
          key: "created_at",
          header: "Created On",
          cell: (row) => {
            return (
              <div>
                {convertUTCToESTFormatted(row.created_at)}
              </div>
            );
          },
        },
        {
          key: "name",
          header: "DBA",
          cell: (row) => {
            return (
              <div className="flex flex-col gap-1">
                <div className="flex gap-2 whitespace-nowrap">
                  <p className="text-sm font-bold ">{row?.name}</p>
                </div>
                <div className="flex gap-2">
                  <p className="font-semibold">Rep:</p> {row?.rep?.first_name}
                </div>
                <div className="flex gap-2">
                  <p className="font-semibold">MID:</p> {row?.merchant_id}{" "}
                </div>
                <div className="flex gap-2">
                  <EntityPlatform platform={row?.platform?.value} />
                </div>
              </div>
            );
          }
        },
        {
          key: "contact_store",
          header: "Contact",
          cell: (row) => {
            return (
              <div className="flex flex-col whitespace-nowrap">
                <div className="flex gap-2">
                  <p className="font-semibold">Owner: </p> {row?.owner_fname}{" "}{row?.owner_lname}
                </div>
                <div className="flex gap-2">
                  <p className="font-semibold">Store: </p> {row?.contact_store}
                </div>
                {row?.contact_owner && <div className="flex gap-2">
                  <p className="font-semibold">Owner #1: </p> {row?.contact_owner}
                </div>
                }
                {row?.contact_owner2 && <div className="flex gap-2">
                  <p className="font-semibold">Owner #2: </p> {row?.contact_owner2}
                </div>
                }
                {row?.email && <div className="flex gap-2">
                  <p className="font-semibold">Email #1: </p> {row?.email}
                </div>
                }
                {row?.email2 && <div className="flex gap-2">
                  <p className="font-semibold">Email #2: </p> {row?.email2}
                </div>
                }
              </div>
            );
          }
        },
      ]}
      tableRows={customers}
      tableName="Newest Customers"
      type="customer"
    />
  );
};

const CustomerPCIAlmostExpireTable = (key) => {
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchCustomers = async () => {
    const { data, error } = await UtilQueries.getCustomersWithOldPCIDate();
    if (data) {
      setCustomers(data);
      setLoading(false);
    }

    if (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchCustomers();
  }, [key]);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <DashboardTable
      tableColumns={[
        {
          key: "name",
          header: "DBA",
          cell: (row) => {
            return (
              <div className="flex flex-col gap-1">
                <div className="flex gap-2 whitespace-nowrap">
                  <p className="text-sm font-bold ">{row?.name}</p>
                </div>
                <div className="flex gap-2">
                  <p className="font-semibold">Rep:</p> {row?.first_name}
                </div>
                <div className="flex gap-2">
                  <p className="font-semibold">MID:</p> {row?.merchant_id}{" "}
                </div>
                <div className="flex gap-2">
                  <EntityPlatform platform={row?.value} />
                </div>
              </div>
            );
          }
        },
        {
          key: "accounts",
          header: "Accounts",
          cell: (row) => {
            return (
              <div className="no-scrollbar flex flex-col gap-2 overflow-y-auto whitespace-nowrap">
                {(row?.accounts ?? []).map((acc) => {
                  return (
                    <div className="flex flex-col" key={row.id + acc?.type}>
                      <div className="flex gap-2">
                        <EntitySimple value={acc?.type} />
                      </div>
                      <div className="flex gap-2">
                        <p className="font-semibold">ID: </p>
                        {acc?.account}
                      </div>
                      <div className="flex gap-2">
                        <p className="font-semibold">PW: </p>
                        {acc?.password}
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          }
        },
        { key: "last_pci_date", header: "PCI Expire Date" }
      ]}
      tableRows={customers}
      tableName="Customers - PCI Expire Date over 2 months"
      type="customer"
    />
  );
};

// 1. NEW/UPDATED 된 서비스 로그 TOP 10(최신순)
// 2. 서비스 로그 INPROGRESS, TOP10 (오래된순서로)
// 3. 서비스 로그 HOLD, TOP10 (오래된순서로)

// 4. 최근 등록된 손님 TOP 10
// 5. PCI EXPIRE될 손님들 TOP10(EXPIRE DATE 2달전)

// 6. STATE별 손님 COUNT, BAR GRAPH로 표시
// 7. 월별로 서비스로그 COUNT, BAR GRAPH로 표시
const Droppable = ({ children, gridSetting, dropIndex, dashboardOrder, fetchDashboardOrder, cacheDashboard }) => {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: "DashboardTable",
    drop: (item) => handleDrop(item),
    collect: monitor => ({ isOver: !!monitor.isOver() }),
  }), []);

  const handleDrop = (item) => {
    swapElements(dashboardOrder, item.dragIndex, dropIndex);
    cacheDashboard(dashboardOrder);
    fetchDashboardOrder();
  }

  const swapElements = (arr, index1, index2) => {
    [arr[index1], arr[index2]] = [arr[index2], arr[index1]];
  }

  return (
    <div
      ref={drop}
      className={`border-1 cursor-move ${gridSetting} ${isOver && "opacity-50 bg-yellow-300"}`}
    >
      {children}
    </div>
  )
}
const DraggableDashboard = ({ dragIndex, dashboardName, dashboardKey }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: "DashboardTable",
    collect: monitor => ({ isDragging: !!monitor.isDragging() }),
    item: { dragIndex }
  }));

  return (
    <div
      ref={drag}
      className={`cursor-move ${isDragging ? "opacity-50" : "opacity-100"}`}
    >
      {
        dashboardName === "NewServiceTable" ? <NewServiceTable key={dashboardKey} /> :
          dashboardName === "RecentUpdateServiceTable" ? <RecentUpdateServiceTable key={dashboardKey} /> :
            dashboardName === "OldestServiceFilteredByStatusTable" ? <OldestServiceFilteredByStatusTable key={dashboardKey} /> :
              dashboardName === "ServiceGroupedByYearMonthBarChart" ? <ServiceGroupedByYearMonthBarChart key={dashboardKey} /> :
                dashboardName === "NewCustomerTable" ? <NewCustomerTable key={dashboardKey} /> :
                  dashboardName === "CustomerPCIAlmostExpireTable" ? <CustomerPCIAlmostExpireTable key={dashboardKey} /> :
                    dashboardName === "CustomersByStateBarChart" ? <CustomersByStateBarChart key={dashboardKey} /> : ""
      }
    </div>
  )
}

const defaultDashboardOrder = [
  { id: 1, name: "NewServiceTable" },
  { id: 2, name: "RecentUpdateServiceTable" },
  { id: 3, name: "OldestServiceFilteredByStatusTable" },
  { id: 4, name: "ServiceGroupedByYearMonthBarChart" },
  { id: 5, name: "NewCustomerTable" },
  { id: 6, name: "CustomersByStateBarChart" },
  { id: 7, name: "CustomerPCIAlmostExpireTable" },
]
const dashboardTemplate = [
  "col-span-6 shadow-md lg:col-span-3 xl:col-span-2",
  "col-span-6 shadow-md lg:col-span-3 xl:col-span-2",
  "col-span-6 shadow-md lg:col-span-3 xl:col-span-2",
  "col-span-6 shadow-md lg:col-span-3",
  "col-span-6 shadow-md lg:col-span-3",
  "col-span-6 shadow-md lg:col-span-3",
  "col-span-6 shadow-md lg:col-span-3",
]

export const DashboardTab = () => {
  const [dashboardKey, setDashboardKey] = useState(0);
  const [dashboardOrder, setDashboardOrder] = useState([]);
  const fetchDashboardOrder = () => {
    const cachedDashboardOrder = localStorage.getItem("dashboardOrder");

    if (cachedDashboardOrder) {
      setDashboardOrder(JSON.parse(cachedDashboardOrder));
    } else {
      setDashboardOrder(defaultDashboardOrder);
    }
  }

  const cacheDashboard = async (newDashboardOrder) => {
    await localStorage.setItem("dashboardOrder", JSON.stringify(newDashboardOrder));
  }

  useEffect(() => {
    fetchDashboardOrder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex flex-col gap-3 p-5 md:p-5">
      <div className="flex items-center gap-3">
        <h1 className="title mb-0">Dashboard</h1>
        <button
          onClick={() => {
            setDashboardKey((prev) => prev + 1);
          }}
        >
          <div>
            <IoMdRefreshCircle
              className="hover:fill-green-600"
              size={40}
              color="gray"
            />
          </div>
        </button>
      </div>
      <DndProvider backend={HTML5Backend}>
        <div className="grid w-full grid-cols-6 gap-8">
          {
            dashboardOrder.map((d, index) =>
              <Droppable
                key={"droppable" + index}
                gridSetting={dashboardTemplate[index]}
                dropIndex={index}
                dashboardOrder={dashboardOrder}
                fetchDashboardOrder={fetchDashboardOrder}
                cacheDashboard={cacheDashboard}
                children={
                  <DraggableDashboard
                    dragIndex={index}
                    dashboardName={d.name}
                    dashboardKey={dashboardKey}
                  />
                }
              />
            )
          }
        </div>
      </DndProvider>
    </div>
  );
};
