import { getCurrentUser } from "utils/utils";
import { useState, useEffect } from "react";
import Employee from "db/employee";

export const useGetCurrentEmployeeInfo = () => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCurrentUser = async () => {
      try {
        const user = await getCurrentUser();
        const employee = await Employee.getEmployeeByID(user.id);
        setCurrentUser(employee.data[0]);
      } catch (error) {
        console.error("Error fetching current user:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCurrentUser();
  }, []); // Empty dependency array to ensure the effect runs only once on mount

  // Add more fields as needed.
  return {
    employee_id: currentUser?.id ?? "",
    can_edit_customer: currentUser?.can_edit_customer ?? false,
    can_edit_inventory: currentUser?.can_edit_inventory ?? false,
    is_admin: currentUser?.is_admin ?? false,
    loading,
  };
};
