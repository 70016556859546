import { AppContext } from "AppContext";
import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { GoRead } from "react-icons/go";
import { MdDeleteOutline } from "react-icons/md";
import Notification from "db/notification";
import moment from "moment";
import { useToast } from "hooks/useToast";

export const NotificationTab = () => {
  const {
    currentUser,
    notifications,
    setNotifications,
    fetchNotification,
    fetchNotificationCount,
  } = useContext(AppContext);
  const navigate = useNavigate();
  const [checkAll, setCheckAll] = useState(false);
  const { showSuccess, showError } = useToast();

  const markOneAsRead = async (id) => {
    const { data } = await Notification.markAsRead([id]);

    if (data) {
      fetchNotification();
      fetchNotificationCount();
    }
  };

  const markSelectedAsRead = async () => {
    const selectedNotificationID = notifications
      .filter((r) => r.checked === true)
      .map((r) => r.id);

    if (selectedNotificationID.length === 0) {
      alert("Nothing is selected");
    } else {
      const { data } = await Notification.markAsRead(selectedNotificationID);

      if (data) {
        fetchNotification();
        fetchNotificationCount();
        setCheckAll(false);
        showSuccess("Success!", "Selected notifications are marked as read.",);
      } else {
        console.log(data);
        showError();
      }
    }
  };

  const deleteSelected = async () => {
    const selectedNotificationID = notifications
      .filter((r) => r.checked === true)
      .map((r) => r.id);

    if (selectedNotificationID.length === 0) {
      alert("Nothing is selected");
    } else {
      const { data } = await Notification.delete(selectedNotificationID);

      if (data) {
        fetchNotification();
        fetchNotificationCount();
        setCheckAll(false);
        showSuccess("Success!", "Selected notifications are deleted.",);
      } else {
        console.log(data);
        showError();
      }
    }
  };

  useEffect(() => {
    if (currentUser) {
      fetchNotification();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  const handleInputChange = (index) => (e) => {
    const { name, value, checked } = e.target;
    var copyNotifications = [...notifications];

    if (name === "checked") {
      if (!checked) {
        setCheckAll(checked);
      }
      copyNotifications[index][name] = checked;
    } else if (name === "checkAll") {
      setCheckAll(checked);
      for (var rowIndex in copyNotifications) {
        copyNotifications[rowIndex]["checked"] = checked;
      }
    } else {
      copyNotifications[index][name] = value;
    }

    setNotifications(copyNotifications);
  };

  const columns = [
    {
      key: "checked",
      header: (
        <input
          type="checkbox"
          className="h-5 w-5 rounded border-gray-300 bg-gray-100 text-blue-600"
          name="checkAll"
          onChange={handleInputChange(-1)}
          checked={checkAll}
        />
      ),
      cell: (row, prop) => (
        <input
          type="checkbox"
          className="h-5 w-5 rounded border-gray-300 bg-gray-100 text-blue-600"
          name="checked"
          onChange={handleInputChange(prop.rowIndex)}
          checked={!row.checked ? checkAll : row.checked}
        />
      ),
      maxWidth: "w-14",
    },
    {
      key: "creator.first_name",
      header: "Sender",
      cell: (row) => (
        <div className={`${row.is_read ? "" : "font-semibold"}`}>
          {row?.creator?.first_name}
        </div>
      ),
      maxWidth: "w-24",
    },
    {
      key: "message",
      header: "Message",
      cell: (row) => {
        if (row.type === "event") {
          return (
            <div className={`${row.is_read ? "" : "font-semibold"}`}>
              <span
                onClick={() => {
                  navigate(`../calendar/${row.type_id}`);
                  markOneAsRead(row.id);
                }}
                className="cursor-pointer text-blue-400 underline"
              >
                This event
              </span>{" "}
              has been scheduled for you.
            </div>
          );
        } else if (row.type === "service") {
          return (
            <div className={`${row.is_read ? "" : "font-semibold"}`}>
              <span
                onClick={() => {
                  navigate(`../service/form?type=edit&id=${row.type_id}`);
                  markOneAsRead(row.id);
                }}
                className="cursor-pointer text-blue-400 underline"
              >
                Service #{row.type_id}
              </span>{" "}
              is assigned to you.
            </div>
          );
        } else if (row.type === "service_comment") {
          return (
            <div className={`${row.is_read ? "" : "font-semibold"}`}>
              <span
                onClick={() => {
                  navigate(`../service/form?type=edit&id=${row.type_id}`);
                  markOneAsRead(row.id);
                }}
                className="cursor-pointer text-blue-400 underline"
              >
                Service #{row.type_id}
              </span>{" "}
              has a new comment.
            </div>
          );
        }
      },
    },
    {
      key: "created_at",
      header: "Date/Time",
      cell: (row) => (
        <div className={`${row.is_read ? "" : "font-semibold"}`}>
          {moment(row.created_at).format("YYYY.MM.DD hh:mm A")}
        </div>
      ),
    },
  ];
  return (
    <div className="m-4">
      <div className="mb-2 flex items-center justify-between gap-3">
        <div className="flex items-center gap-3">
          <h1 className="title mb-0">Notification</h1>
          <button
            type="button"
            className="flex items-center gap-2 rounded-full border-2 border-gray-300 bg-gray-100 px-3 py-2"
            onClick={markSelectedAsRead}
          >
            <GoRead size={20} />
            <span className="hidden md:block">Mark As Read</span>
          </button>
          <button
            type="button"
            className="flex items-center gap-2 rounded-full border-2 border-gray-300 bg-gray-100 px-3 py-2"
            onClick={deleteSelected}
          >
            <MdDeleteOutline size={25} />
            <span className="hidden md:block">Delete</span>
          </button>
        </div>
      </div>
      <div className="h-[80vh] border shadow-md">
        <DataTable
          value={notifications}
          size="normal"
          tableStyle={{ width: "100%" }}
          rowClassName={(data) => {
            return {
              "bg-white": data.is_read === false,
              "bg-[#f2f6fc]": data.is_read === true,
            };
          }}
          scrollable
          scrollHeight="flex"
          rowHover={true}
          emptyMessage="No nofitication found."
        >
          {columns.map((col, index) => {
            return (
              <Column
                key={index}
                field={col.key}
                header={col.header}
                headerClassName={`py-3 ${col.maxWidth} text-xs sm:text-sm`}
                bodyClassName="py-3 text-xs sm:text-sm"
                body={col.cell}
                showClearButton={true}
              />
            );
          })}
        </DataTable>
      </div>
    </div>
  );
};
