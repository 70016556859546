import Customer from "db/customer";
import DropdownField from "db/dropdownField";
import Employee from "db/employee";
import { useState, useEffect } from "react";

export const useGetFrequentlyUsedValues = ({ tableFilter = null }) => {
  // table data filter from parent component

  const [Employees, setEmployees] = useState([]);
  const [ServiceStatus, setServiceStatus] = useState([]);
  const [DeviceStatus, setDeviceStatus] = useState([]);
  const [Customers, setCustomers] = useState([]);

  useEffect(() => {
    const fetchEmployees = async () => {
      const { data } = await Employee.getEmployeeList();
      if (data) setEmployees(data);
    };

    const fetchServiceStatus = async () => {
      const { data } = await DropdownField.getDataByType("Service Status");
      if (data) setServiceStatus(data);
    };

    const fetchDeviceStatus = async () => {
      const { data } = await DropdownField.getDataByType("Device Status");
      if (data) setDeviceStatus(data);
    };

    const fetchCustomers = async () => {
      const { data } = await Customer.getCustomerNameList();
      if (data) setCustomers(data);
    };

    if (tableFilter) {
      if (
        tableFilter.hasOwnProperty("creator") ||
        tableFilter.hasOwnProperty("assigned")
      ) {
        fetchEmployees();
      }

      if (tableFilter.hasOwnProperty("serviceStatus")) {
        fetchServiceStatus();
      }

      if (tableFilter.hasOwnProperty("deviceStatus")) {
        fetchDeviceStatus();
      }

      if (tableFilter.hasOwnProperty("customer")) {
        fetchCustomers();
      }
    } else {
      fetchEmployees();
      fetchServiceStatus();
      fetchDeviceStatus();
      fetchCustomers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    Employees,
    ServiceStatus,
    DeviceStatus,
    Customers,
  };
};
