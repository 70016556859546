import React, { useEffect, useState } from "react";
import Service from "db/service";
import ServiceDevice from "db/service_device";
import { MiniTable } from "./MiniTable";
import { StatusBadgeWithColor } from "utils/utils";

// Device/Customer can have multiple services.

const tableColumns = [
  {
    key: "assigned",
    header: "Assigned",
    cell: (row) => row.assigned_text,
  },
  {
    key: "status",
    header: "Status",
    cell: (row) => {
      return (
        <div className="flex ">
          <StatusBadgeWithColor status={row?.status?.value} />
        </div>
      );
    }
  },

  {
    key: "request",
    header: "Request",
    cell: (row) => {
      return (
        <div className="no-scrollbar max-h-[300px] overflow-y-scroll whitespace-pre-line">
          {row?.request}
        </div>
      );
    },
    minWidth: "500px",
  },
];

export const CommonServiceComponent = ({ entityType, entityID }) => {
  const [serviceList, setServiceList] = useState([]);

  const fetchServiceOnCurrentEntity = async () => {
    const { data } = await Service.getServicesByCustomerID(entityID);
    if (data) {
      setServiceList(data);
    }
  };

  const fetchServiceOnCurrentDevice = async () => {
    //const { data } = await Service.getServicesByDeviceID(entityID);
    const { data } = await ServiceDevice.getServicesByDeviceID(entityID);
    if (data) {
      setServiceList(data.map(d => d.service));
    }
  };

  useEffect(() => {
    if (entityType === "customer") {
      fetchServiceOnCurrentEntity();
    } else if (entityType === "device") {
      fetchServiceOnCurrentDevice();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex h-full w-full flex-col items-center space-y-6 overflow-x-auto rounded-b-lg bg-white p-6 shadow">
      <MiniTable
        tableColumns={tableColumns}
        tableRows={serviceList}
        type="service"
      />
    </div>
  );
};
