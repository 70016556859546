import React, { useContext } from "react";
import {
  EntityStatusSmall,
  convertUTCToESTFormatted,
  EntityPlatform,
  EntitySimple,
  EntityHotSpot,
} from "utils/utils";
import Customer from "db/customer";
import { CommonTable } from "./CommonTable";
import { useGetCurrentEmployeeInfo } from "hooks/useGetCurrentEmployee";
import { useInfiniteLoading } from "hooks/useInfiniteLoading";
import { AppContext } from "AppContext";

const tableColumns = [
  {
    key: "name",
    header: "DBA",
    cell: (row) => {
      return (
        <div className="flex flex-col gap-3">
          <div className="flex gap-2 whitespace-nowrap">
            <EntityStatusSmall inactive={row.inactive} />
            <p className="text-sm font-bold ">{row?.name}</p>
          </div>
          <div className="flex gap-2">
            <p className="font-semibold">Rep:</p> {row?.rep?.first_name}
          </div>
          <div className="flex gap-2">
            <p className="font-semibold">MID:</p> {row?.merchant_id}{" "}
            <EntityPlatform platform={row?.platform?.value} />
            <EntityHotSpot has_hotspot={row?.has_hotspot} />
          </div>
        </div>
      );
    },
    // minWidth: "300px",
  },
  {
    key: "address",
    header: "Address",
    cell: (row) => {
      return (
        <div className="flex flex-col whitespace-nowrap">
          <div>{row?.address}</div>
          <div>
            {row?.city}, {row?.state}, {row?.zip}
          </div>
        </div>
      );
    },
    // minWidth: "200px",
  },
  {
    key: "contact_store",
    header: "Contact",
    cell: (row) => {
      return (
        <div className="flex flex-col whitespace-nowrap">
          <div className="flex gap-2">
            <p className="font-semibold">Owner: </p> {row?.owner_fname}{" "}
            {row?.owner_lname}
          </div>
          <div className="flex gap-2">
            <p className="font-semibold">Store: </p> {row?.contact_store}
          </div>
          {row?.contact_owner && (
            <div className="flex gap-2">
              <p className="font-semibold">Owner #1: </p> {row?.contact_owner}
            </div>
          )}
          {row?.contact_owner2 && (
            <div className="flex gap-2">
              <p className="font-semibold">Owner #2: </p> {row?.contact_owner2}
            </div>
          )}
          {row?.email && (
            <div className="flex gap-2">
              <p className="font-semibold">Email #1: </p> {row?.email}
            </div>
          )}
          {row?.email2 && (
            <div className="flex gap-2">
              <p className="font-semibold">Email #2: </p> {row?.email2}
            </div>
          )}
        </div>
      );
    },
    // minWidth: "100px",
  },
  {
    key: "accounts",
    header: "Accounts",
    cell: (row) => {
      return (
        <div className="no-scrollbar flex w-[170px] flex-col gap-2 overflow-y-auto whitespace-nowrap">
          {(row?.accounts ?? []).map((acc) => {
            return (
              <div className="flex flex-col" key={row.id + acc?.type}>
                <div className="flex gap-2">
                  <EntitySimple value={acc?.type} />
                </div>
                <div className="flex gap-2">
                  <p className="font-semibold">ID: </p>
                  {acc?.account}
                </div>
                <div className="flex gap-2">
                  <p className="font-semibold">PW: </p>
                  {acc?.password}
                </div>
              </div>
            );
          })}
        </div>
      );
    },
    // minWidth: "180px",
  },
  // min width was 100
  { key: "last_pci_date", header: "PCI Expire Date", sortable: true },
  {
    key: "created_at",
    header: "Created Date",
    // minWidth: "180px",
    cell: (row) => {
      return (
        <div className=" whitespace-nowrap">
          {convertUTCToESTFormatted(row.created_at)}
        </div>
      );
    },
    sortable: true,
  },
];

export const CustomerTab = () => {
  const { is_admin, can_edit_customer } = useGetCurrentEmployeeInfo();
  const { customerTableFilter } = useContext(AppContext);

  const {
    items,
    hasMore,
    loadNext,
    loadingNext,
    triggerReload,
    filters,
    setFilters,
    initialPageLoaded,
  } = useInfiniteLoading({
    getItemsLazy: (start, end, lazyFilters) => {
      return Customer.getCustomersWithRangeAndFilter(start, end, lazyFilters);
    },
    filterValues: customerTableFilter || {
      inactive: null,
      searchInput: "",
      sortState: {
        sortField: null,
        sortOrder: null,
      },
    },
    name: "customer",
  });
  return (
    <div>
      <div className="m-2 lg:m-5">
        <CommonTable
          tableName="Customers"
          tableColumns={tableColumns}
          tableRows={items}
          enableInsert={is_admin || can_edit_customer}
          requireCreateButton={true}
          requireSearch={true}
          lazyLoadRows={loadNext}
          lazyLoading={loadingNext}
          lazyHasMoreData={hasMore}
          tableFilter={filters}
          setTableFilter={setFilters}
          refetchRows={triggerReload}
          initialPageLoaded={initialPageLoaded}
        />
      </div>
    </div>
  );
};
