import React, { useState, useEffect } from "react";
import Customer from "db/customer";
import Employee from "db/employee";
import DropdownField from "db/dropdownField";
import { FiEdit } from "react-icons/fi";
import { FaRegComments } from "react-icons/fa";
import { MdInventory2, MdSupportAgent } from "react-icons/md";
import { GoHistory } from "react-icons/go";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CommonCommentsComponent } from "../shared/CommonCommentsComponent";
import { CommonHistoryComponent } from "shared/CommonHistoryComponent";
import { CommonServiceComponent } from "shared/CommonServiceComponent";
import { CommonDeviceComponent } from "shared/CommonDeviceComponent";
import { formatPhoneNumber, getStates } from "utils/utils";
import { useGetCurrentEmployeeInfo } from "hooks/useGetCurrentEmployee";
import { useToast } from "hooks/useToast";
import { FaRegFileImage } from "react-icons/fa";
import { CommonPhotosComponent } from "shared/CommonPhotosComponent";

const CUSTOMER_MODAL_TABS = [
  { name: "Main", icon: <FiEdit size={20} /> },
  { name: "Service", icon: <MdSupportAgent size={20} /> },
  { name: "Device", icon: <MdInventory2 size={20} /> },
  { name: "Photo", icon: <FaRegFileImage size={20} /> },
  { name: "Comments", icon: <FaRegComments size={20} /> },
  { name: "History", icon: <GoHistory size={20} /> },
];

const initialCustomerVal = {
  rep: "",
  name: "",
  merchant_id: "",
  address: "",
  city: "",
  state: "",
  zip: "",
  contact_store: "",
  contact_owner: "",
  contact_owner2: "",
  last_pci_date: "",
  email: "",
  email2: "",
  has_hotspot: false,
  inactive: false,
  accounts: [{ type: "", account: "", password: "" }],
};

export const CustomerForm = () => {
  const states = getStates();
  const [params, setSearchParam] = useSearchParams();
  const formType = params.get("type");
  const id = params.get("id");
  const numOfTabChange = Number(params.get("back")) || 0;
  const navigate = useNavigate();
  const [customer, setCustomer] = useState(initialCustomerVal);
  const [reps, setReps] = useState([]);
  const [platform, setPlatform] = useState([]);
  const [currentTab, setCurrentTab] = useState(
    params.get("tab") || CUSTOMER_MODAL_TABS[0].name,
  );
  const { is_admin, can_edit_customer } = useGetCurrentEmployeeInfo();
  const { showSuccess, showError } = useToast();

  const fetchReps = async () => {
    const { data } = await Employee.getRep();
    if (data) {
      setReps(data);
    }
  };

  const fetchCustomerById = async () => {
    const { data } = await Customer.getCustomerById(id);
    if (data) {
      data[0].accounts.push({ type: "", account: "", password: "" });
      setCustomer(data[0]);
    }
  };

  const fetchPlatform = async () => {
    const { data } = await DropdownField.getActiveDataByType("Platform");
    if (data) setPlatform(data);
  };

  useEffect(() => {
    fetchReps();
    fetchPlatform();

    if (formType === "edit") {
      fetchCustomerById();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInputChange = (e) => {
    const { name, value, checked } = e.target;
    if (name === "has_hotspot" || name === "inactive") {
      setCustomer({
        ...customer,
        [name]: checked,
      });
    } else if (name.includes("accounts.")) {
      const index = Number(e.target.parentElement.parentElement.id);
      const key = name.replace("accounts.", "");
      const updatedData = customer.accounts.map((row, i) =>
        i === index ? { ...row, [key]: value } : row,
      );
      const currentRow = updatedData[index];
      const lastRow = updatedData[updatedData.length - 1];

      // Delete Empty Row
      if (
        !currentRow.type &&
        !currentRow.account &&
        !currentRow.password &&
        updatedData.length !== 1 &&
        index !== updatedData.length - 1
      ) {
        updatedData.splice(index, 1);
      }

      // Insert New Row
      if (lastRow.type && lastRow.account && lastRow.password) {
        updatedData.push({ type: "", account: "", password: "" });
      }

      setCustomer({
        ...customer,
        accounts: updatedData,
      });
    } else if (name.includes("contact")) {
      setCustomer({
        ...customer,
        [name]: formatPhoneNumber(value),
      });
    } else if (
      [
        "name",
        "address",
        "city",
        "owner_fname",
        "owner_lname",
        "email",
        "email2",
      ].indexOf(name) !== -1
    ) {
      setCustomer({
        ...customer,
        [name]: value.toUpperCase(),
      });
    } else {
      setCustomer({
        ...customer,
        [name]: value,
      });
    }
  };

  const onFormSubmit = async (e) => {
    e.preventDefault();
    const data = await Customer.upsertCustomer(customer);

    if (data.data) {
      if (formType === "add") {
        showSuccess("Creation successful!", "New customer was created.");
      } else {
        showSuccess(
          "Edit successful!",
          "Your changes were saved successfully.",
        );
      }
    }

    if (data.error) {
      showError();
    }

    navigate("/home/customer");
  };

  const customerForm = () => {
    return (
      <form onSubmit={onFormSubmit}>
        <div className="flex items-center justify-end space-x-6 rounded-b border-t border-gray-200 px-3 pb-0 pt-3">
          <button
            type="button"
            className="hover:bg-reed-800 rounded-lg bg-red-500 px-5 py-2.5 text-center text-sm font-medium text-white focus:outline-none focus:ring-4 focus:ring-red-300"
            onClick={() => navigate(-1 - numOfTabChange)}
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={!is_admin && !can_edit_customer}
            title={
              !is_admin && !can_edit_customer
                ? "You need admin or edit customer access to edit customers"
                : null
            }
            className="rounded-lg bg-blue-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 disabled:bg-gray-300"
          >
            Save
          </button>
        </div>
        <fieldset disabled={!is_admin && !can_edit_customer}>
          <div className="grid grid-cols-6 gap-6 p-4 sm:px-6">
            <div className="col-span-6 sm:col-span-1">
              <label className="mb-2 block text-sm font-medium text-gray-900">
                Rep. *
              </label>
              <select
                className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 shadow-sm focus:border-blue-600 focus:ring-blue-600"
                required={true}
                name="rep"
                value={customer.rep.id}
                onChange={handleInputChange}
              >
                <option value="">Select Rep.</option>
                {reps.map((e) => {
                  return (
                    <option key={e.id} value={e.id}>
                      {e.first_name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="col-span-6 sm:col-span-2">
              <label className="mb-2 block text-sm font-medium text-gray-900">
                DBA *
              </label>
              <input
                type="text"
                className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 shadow-sm focus:border-blue-600 focus:ring-blue-600"
                placeholder="POSCONNECT"
                required={true}
                value={customer.name || ""}
                name="name"
                onChange={handleInputChange}
              />
            </div>
            <div className="col-span-6 sm:col-span-1">
              <label className="mb-2 block text-sm font-medium text-gray-900">
                Platform *
              </label>
              <select
                className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 shadow-sm focus:border-blue-600 focus:ring-blue-600"
                required={true}
                name="platform"
                value={customer.platform}
                onChange={handleInputChange}
              >
                <option value="">Select Platform</option>
                {platform.map((e) => {
                  return (
                    <option key={e.id} value={e.id}>
                      {e.value}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="col-span-6 sm:col-span-2">
              <label className="mb-2 block text-sm font-medium text-gray-900">
                Merchant ID *
              </label>
              <input
                type="number"
                className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 shadow-sm focus:border-blue-600 focus:ring-blue-600"
                placeholder="122333444455555666666"
                value={customer.merchant_id || ""}
                required={true}
                name="merchant_id"
                onChange={handleInputChange}
              />
            </div>
            <div className="col-span-6 sm:col-span-3">
              <label className="mb-2 block text-sm font-medium text-gray-900">
                Address *
              </label>
              <input
                type="text"
                className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 shadow-sm focus:border-blue-600 focus:ring-blue-600"
                placeholder="530 HIGHLAND STATION DR 3002"
                required={true}
                value={customer.address || ""}
                name="address"
                onChange={handleInputChange}
              />
            </div>
            <div className="col-span-6 sm:col-span-1">
              <label className="mb-2 block text-sm font-medium text-gray-900">
                City *
              </label>
              <input
                type="text"
                className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 shadow-sm focus:border-blue-600 focus:ring-blue-600"
                placeholder="SUWANEE"
                required={true}
                value={customer.city || ""}
                name="city"
                onChange={handleInputChange}
              />
            </div>
            <div className="col-span-3 sm:col-span-1">
              <label className="mb-2 block text-sm font-medium text-gray-900">
                State *
              </label>
              <select
                className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 shadow-sm focus:border-blue-600 focus:ring-blue-600"
                required={true}
                name="state"
                value={customer.state}
                onChange={handleInputChange}
              >
                <option value="" disabled>
                  Select State
                </option>
                {states.map((state) => (
                  <option key={state} value={state}>
                    {state}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-span-3 sm:col-span-1">
              <label className="mb-2 block text-sm font-medium text-gray-900">
                Zip *
              </label>
              <input
                type="number"
                className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 shadow-sm focus:border-blue-600 focus:ring-blue-600"
                placeholder="30019"
                required={true}
                value={customer.zip || ""}
                name="zip"
                onChange={handleInputChange}
              />
            </div>
            <div className="col-span-6 sm:col-span-1">
              <label className="mb-2 block text-sm font-medium text-gray-900">
                Owner (First Name)
              </label>
              <input
                type="text"
                className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 shadow-sm focus:border-blue-600 focus:ring-blue-600"
                placeholder="DAVID"
                value={customer.owner_fname || ""}
                name="owner_fname"
                onChange={handleInputChange}
              />
            </div>
            <div className="col-span-6 sm:col-span-1">
              <label className="mb-2 block text-sm font-medium text-gray-900">
                Owner (Last Name)
              </label>
              <input
                type="text"
                className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 shadow-sm focus:border-blue-600 focus:ring-blue-600"
                placeholder="KIM"
                value={customer.owner_lname || ""}
                name="owner_lname"
                onChange={handleInputChange}
              />
            </div>
            <div className="col-span-6 sm:col-span-2">
              <label className="mb-2 block text-sm font-medium text-gray-900">
                Email #1
              </label>
              <input
                type="email"
                className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 shadow-sm focus:border-blue-600 focus:ring-blue-600"
                placeholder="JOHNDOE@GMAIL.COM"
                required={false}
                value={customer.email || ""}
                name="email"
                onChange={handleInputChange}
              />
            </div>
            <div className="col-span-6 sm:col-span-2">
              <label className="mb-2 block text-sm font-medium text-gray-900">
                Email #2
              </label>
              <input
                type="email"
                className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 shadow-sm focus:border-blue-600 focus:ring-blue-600"
                placeholder="JOHNDOE@GMAIL.COM"
                required={false}
                value={customer.email2 || ""}
                name="email2"
                onChange={handleInputChange}
              />
            </div>
            <div className="col-span-6 sm:col-span-1">
              <label className="mb-2 block text-sm font-medium text-gray-900">
                Contact (store)
              </label>
              <input
                type="tel"
                className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 shadow-sm focus:border-blue-600 focus:ring-blue-600"
                placeholder="(xxx)-xxx-xxxx"
                required={false}
                name="contact_store"
                value={customer.contact_store || ""}
                onChange={handleInputChange}
              />
            </div>
            <div className="col-span-6 sm:col-span-1">
              <label className="mb-2 block text-sm font-medium text-gray-900">
                Contact (owner) #1
              </label>
              <input
                type="tel"
                className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 shadow-sm focus:border-blue-600 focus:ring-blue-600"
                placeholder="(xxx)-xxx-xxxx"
                required={false}
                value={customer.contact_owner || ""}
                name="contact_owner"
                onChange={handleInputChange}
              />
            </div>
            <div className="col-span-6 sm:col-span-1">
              <label className="mb-2 block text-sm font-medium text-gray-900">
                Contact (owner) #2
              </label>
              <input
                type="tel"
                className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 shadow-sm focus:border-blue-600 focus:ring-blue-600"
                placeholder="(xxx)-xxx-xxxx"
                required={false}
                value={customer.contact_owner2 || ""}
                name="contact_owner2"
                onChange={handleInputChange}
              />
            </div>
            <div className="col-span-6 sm:col-span-1">
              <label className="mb-2 block text-sm font-medium text-gray-900">
                PCI Expire Date
              </label>
              <input
                type="date"
                className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 shadow-sm focus:border-blue-600 focus:ring-blue-600"
                required={false}
                name="last_pci_date"
                value={customer.last_pci_date || ""}
                onChange={handleInputChange}
              />
            </div>
            <div className="col-span-3 flex items-center sm:col-span-1">
              <input
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 bg-gray-100 text-blue-600 focus:ring-blue-500"
                name="has_hotspot"
                onChange={handleInputChange}
                checked={customer.has_hotspot}
              />
              <label className="ml-2 text-sm font-medium text-gray-900">
                Has Hotspot?
              </label>
            </div>
            <div className="col-span-3 flex items-center sm:col-span-1">
              <input
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500"
                name="inactive"
                onChange={handleInputChange}
                checked={customer.inactive}
              />
              <label className="ml-2 text-sm font-medium text-gray-900">
                Inactive
              </label>
            </div>
          </div>
          <div className="col-span-8 flex items-center overflow-x-auto p-4 sm:col-span-8 sm:p-6">
            <table className="text-md w-full table-auto text-left text-gray-700 shadow-md xl:table-fixed">
              <thead className="bg-gray-50 text-sm text-gray-800">
                <tr className="border-b-2 border-gray-500">
                  <th className="border-b px-4 py-2">Row #</th>
                  <th className="border-b px-4 py-2">Account Type</th>
                  <th className="border-b px-4 py-2">Account ID</th>
                  <th className="border-b px-4 py-2">Password</th>
                </tr>
              </thead>
              <tbody>
                {customer.accounts.map((row, index) => (
                  <tr key={index} id={index}>
                    <td className="border-b px-4 py-2">{index + 1}</td>
                    <td className="border-b px-4 py-2">
                      <input
                        type="text"
                        className="w-full rounded border border-gray-300 px-2 py-1"
                        name={"accounts.type"}
                        value={row.type}
                        onChange={handleInputChange}
                      />
                    </td>
                    <td className="border-b px-4 py-2">
                      <input
                        type="text"
                        className="w-full rounded border border-gray-300 px-2 py-1"
                        name={"accounts.account"}
                        value={row.account}
                        onChange={handleInputChange}
                      />
                    </td>
                    <td className="border-b px-4 py-2">
                      <input
                        type="text"
                        className="w-full rounded border border-gray-300 px-2 py-1"
                        name={"accounts.password"}
                        value={row.password}
                        onChange={handleInputChange}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </fieldset>
      </form>
    );
  };

  return (
    <div className="relative h-full max-h-full w-full align-middle">
      <div className="flex border-b bg-white p-4 shadow">
        <h1 className="subtitle md:title">Customer</h1>
        {formType === "edit" && (
          <div className="ml-6 flex flex-wrap gap-4">
            {CUSTOMER_MODAL_TABS.map((tab, index) => {
              return (
                <div
                  key={index}
                  className={`flex items-center gap-2 hover:cursor-pointer ${tab.name === currentTab ? "text-black" : "text-gray-400"}`}
                  onClick={() => {
                    setCurrentTab(CUSTOMER_MODAL_TABS[index].name);
                    params.set("tab", CUSTOMER_MODAL_TABS[index].name);
                    params.set("back", (numOfTabChange + 1).toString());
                    setSearchParam(params);
                  }}
                >
                  {tab.icon}
                  <h3 className="mb-0 hidden text-lg md:block">{tab.name}</h3>
                </div>
              );
            })}
          </div>
        )}
      </div>
      {currentTab === "Main" ? (
        customerForm()
      ) : currentTab === "Comments" ? (
        <CommonCommentsComponent entityType="customer" entityID={id} />
      ) : currentTab === "History" ? (
        <CommonHistoryComponent entityType="customer" entityID={id} />
      ) : currentTab === "Service" ? (
        <CommonServiceComponent entityType="customer" entityID={id} />
      ) : currentTab === "Device" ? (
        <CommonDeviceComponent entityType="customer" entityID={id} />
      ) : currentTab === "Photo" ? (
        <CommonPhotosComponent entityID={id} />
      ) : (
        <div className="relative flex flex-col items-center justify-center space-y-6 rounded-b-lg bg-white p-6 shadow">
          <p>component not found</p>
        </div>
      )}
    </div>
  );
};
