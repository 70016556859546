import { AppContext } from "AppContext";
import React, { useContext, useEffect, useState } from "react";
import Comment from "db/comment";
import { convertUTCToESTFormatted } from "utils/utils";
import { IoTrashOutline } from "react-icons/io5";
import { FiEdit } from "react-icons/fi";

export const CommonCommentsComponent = ({ entityType, entityID }) => {
  const { currentUser } = useContext(AppContext);

  const [currentComment, setCurrentComment] = useState("");
  const [comments, setComments] = useState([]);
  const [submittingComment, setSubmittingComment] = useState(false);
  const [clickedEdit, setClickedEdit] = useState(false);
  const [editCommentID, setEditCommentID] = useState(-1);
  const [editingComment, setEditingComment] = useState("");

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [deleteCommentID, setDeleteCommentID] = useState(-1);
  const [files, setFiles] = useState([]);

  const handleFileChange = (event) => {
    // Update the state with the selected files
    const uploadedFiles = event.target.files;

    for (var i = 0; i < uploadedFiles.length; i++) {
      const uploadedFile = uploadedFiles[i];

      if (/[^a-zA-Z0-9._-]/.test(uploadedFile.name)) {
        alert("File name should contain only alphabets and numbers");
        setFiles([]);
        // @ts-ignore
        document.getElementById("fileInput").value = "";
        return;
      }
    }

    setFiles([...event.target.files]);
  };

  const fetchCommentsOnCurrentEntity = async () => {
    const { data } = await Comment.getCommentByEntityTypeAndID(
      entityType,
      entityID,
    );
    if (data) {
      let tempData = data;
      for (let i = 0; i < tempData.length; i++) {
        const comm = tempData[i];
        if ((comm.photoKeys ?? []).length > 0) {
          const urls = await Comment.getPhotosURL(comm.photoKeys);
          tempData[i] = {
            ...comm,
            // @ts-ignore -- this is ok.
            photoUrls: urls,
          };
        }
      }
      setComments(tempData);
    }
  };

  useEffect(() => {
    fetchCommentsOnCurrentEntity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (e) => {
    setSubmittingComment(true);
    e.preventDefault();
    // eslint-disable-next-line no-unused-vars
    const { data, error } = await Comment.upsertComment({
      entity_id: entityID,
      entity_type: entityType,
      comment: currentComment,
      user: currentUser.id,
    });
    if (entityType === "service" && data && files.length > 0) {
      await Comment.uploadFiles(files, entityID, data[0].id);
      setFiles([]);
      // @ts-ignore
      document.getElementById("fileInput").value = "";
    }
    setCurrentComment("");
    fetchCommentsOnCurrentEntity();
    setSubmittingComment(false);
  };

  const handleEdit = async (e, commentID) => {
    e.preventDefault();
    // eslint-disable-next-line no-unused-vars
    const data = await Comment.upsertComment({
      id: commentID,
      entity_id: entityID,
      entity_type: entityType,
      comment: editingComment,
    });
    setClickedEdit(false);
    setEditCommentID(-1);
    setEditingComment("");
    fetchCommentsOnCurrentEntity();
  };

  const handleDelete = async (e, commentID) => {
    e.preventDefault();
    const data = await Comment.upsertComment({
      id: commentID,
      entity_id: entityID,
      entity_type: entityType,
      deleted: true,
    });
    setDeleteCommentID(-1);
    setShowDeleteDialog(false);
    fetchCommentsOnCurrentEntity();
  };

  return (
    <div className="relative flex h-full flex-col items-center space-y-6 rounded-b-lg bg-white p-6 shadow">
      {entityType === "service" && (
        <div className="flex w-full justify-center">
          <input
            type="file"
            id="fileInput"
            placeholder="Upload an image"
            onChange={handleFileChange}
            multiple
            accept="image/*"
          />
        </div>
      )}
      <form
        onSubmit={(e) => handleSubmit(e)}
        className="flex w-full items-center justify-between sm:w-3/4 lg:w-3/4"
        id="commentForm"
      >
        <textarea
          id="commentForm"
          form="commentForm"
          name="commentForm"
          className="border-1 w-3/4 rounded-xl border bg-gray-100 p-3"
          rows={2}
          placeholder="Type your comment..."
          value={currentComment}
          onChange={(e) => {
            setCurrentComment(e.target.value);
          }}
          style={{ resize: "none" }}
        />
        <input
          className="rounded border-b-4 border-blue-700 bg-blue-500 px-4 py-2 font-bold text-white hover:border-blue-500 hover:bg-blue-400 disabled:bg-gray-300"
          title="You cannot submit an empty comment."
          disabled={
            submittingComment || (currentComment.length < 1 && files.length < 1)
          }
          type="submit"
        />
      </form>
      {(comments ?? []).length === 0 ? (
        <div className="mx-auto w-full rounded-lg bg-white px-10 py-4 sm:w-3/4 lg:w-3/4">
          <div className="flex flex-col items-center justify-center py-3">
            <h1 className="mt-4 text-center text-2xl font-semibold text-gray-700">
              No comments
            </h1>
            <p className="mt-2 text-center text-gray-500">
              If you want to add a comment, please type in your comment and
              click submit.
            </p>
          </div>
        </div>
      ) : (
        <div className="no-scrollbar flex h-auto w-full flex-col items-center gap-3 overflow-y-auto sm:w-3/4 lg:w-3/4">
          {comments.map((comment, index) => {
            return (
              <div key={index} className="w-full rounded-xl bg-gray-100 p-3">
                <div className="flex items-center justify-between font-medium">
                  <div className="flex items-center gap-2">
                    <img
                      className="h-8 w-8 rounded-full sm:h-10 sm:w-10"
                      src={`https://ui-avatars.com/api/?name=${comment.user.first_name}+${comment.user.last_name}&rounded=true&background=random`}
                      alt="Rounded avatar"
                    />
                    <p className="m-0 text-sm sm:text-base">
                      {comment.user.first_name + " " + comment.user.last_name}
                    </p>
                  </div>
                  <p className="text-xs sm:text-base">
                    {convertUTCToESTFormatted(comment.created_at)}
                  </p>
                </div>
                {clickedEdit && editCommentID === comment.id ? (
                  <form
                    onSubmit={(e) => handleEdit(e, editCommentID)}
                    className="flex w-full items-center justify-between"
                    id="commentForm"
                  >
                    <textarea
                      id="commentUpdateForm"
                      form="commentUpdateForm"
                      name="commentUpdateForm"
                      className="border-1 w-full rounded-xl border bg-gray-100 p-3"
                      rows={comment?.comment.split(/\r\n|\r|\n/).length + 2}
                      value={editingComment}
                      onChange={(e) => {
                        setEditingComment(e.target.value);
                      }}
                      style={{ resize: "none" }}
                    />
                    <div className="flex-col ml-6">
                      <input
                        className="rounded border-b-4 border-red-700 bg-red-500 px-4 py-2 font-bold text-white hover:border-red-500 hover:bg-red-400"
                        type="submit"
                        value="Cancel"
                        onClick={() => {
                          setClickedEdit(false);
                          setEditCommentID(-1);
                          setEditingComment("");
                        }}
                      />
                      <input
                        className="rounded  border-b-4 border-blue-700 bg-blue-500 px-4 py-2 font-bold text-white hover:border-blue-500 hover:bg-blue-400 mt-4"
                        type="submit"
                        value="Save"
                      />
                    </div>
                    {/* <div className="mr-4"></div>
                    <input
                      className="rounded border-b-4 border-red-700 bg-red-500 px-4 py-2 font-bold text-white hover:border-red-500 hover:bg-red-400"
                      type="submit"
                      value="Cancel Edit"
                      onClick={() => {
                        setClickedEdit(false);
                        setEditCommentID(-1);
                        setEditingComment("");
                      }}
                    />
                    <div className="mr-4"></div>
                    <input
                      className="rounded  border-b-4 border-blue-700 bg-blue-500 px-4 py-2 font-bold text-white hover:border-blue-500 hover:bg-blue-400"
                      type="submit"
                      value="Edit"
                    /> */}
                  </form>
                ) : (
                  <pre className="mb-0">
                    <textarea
                      className="w-full bg-gray-100 p-3 h-full overflow-hidden resize-none"
                      rows={comment?.comment.split(/\r\n|\r|\n/).length + 2}
                      value={comment?.comment}
                      disabled={true}
                    />
                  </pre>
                )}
                <div className="mt-2 flex space-x-4 overflow-auto">
                  {(comment.photoUrls ?? []).map((url, index) => {
                    const extension = url.split(".").pop().toLowerCase();

                    if (
                      ["mp4", "avi", "mov", "wmv", "mkv"].includes(extension)
                    ) {
                      return (
                        <video className="h-auto w-3/12" controls>
                          <source src={url} key={index} />
                        </video>
                      );
                    }

                    return (
                      <img
                        key={index}
                        src={url}
                        alt={`${index}`}
                        className="h-auto w-3/12 cursor-pointer border-4 border-solid"
                        onClick={() => {
                          window.open(url, "_blank");
                        }}
                      />
                    );
                  })}
                </div>
                {/* can only edit/ delete your own comments. */}
                {comment?.user?.id === currentUser?.id && (
                  <div className="relative flex justify-end">
                    <button
                      onClick={(e) => {
                        setClickedEdit(true);
                        setEditCommentID(comment.id);
                        setEditingComment(comment.comment);
                      }}
                    >
                      <FiEdit size={18} />
                    </button>
                    <div className="ml-4"></div>
                    <button
                      onClick={(e) => {
                        setShowDeleteDialog(true);
                        setDeleteCommentID(comment.id);
                      }}
                    >
                      <IoTrashOutline size={18} />
                    </button>
                    {showDeleteDialog && deleteCommentID === comment.id && (
                      <div className="absolute bottom-0 left-1/2 -translate-x-1/2 transform">
                        <div className="w-full rounded-xl bg-gray-300 p-3">
                          Are you sure you want to delete this comment?
                          <div className="flex justify-evenly">
                            <button
                              onClick={(e) => {
                                setDeleteCommentID(-1);
                                setShowDeleteDialog(false);
                              }}
                              className="rounded-lg bg-blue-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300"
                            >
                              No
                            </button>
                            <button
                              onClick={(e) => {
                                handleDelete(e, deleteCommentID);
                              }}
                              className="rounded-lg bg-red-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-red-800 focus:outline-none focus:ring-4 focus:ring-blue-300"
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
