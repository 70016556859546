import { supabase } from "../utils/supabaseClient";
import { useNavigate } from "react-router-dom";
import { Auth } from "@supabase/auth-ui-react";
import logo from "../images/logo.png";
import {
  // Import predefined theme
  ThemeSupa,
} from "@supabase/auth-ui-shared";
import { useContext, useEffect } from "react";
import React from "react";
import { AppContext } from "AppContext";
import { getRouteFromCurrentUrl, redirectBaseUrl } from "utils/utils";

export const Login = () => {
  const { setCurrentUser } = useContext(AppContext);
  const navigate = useNavigate();
  useEffect(() => {
    const checkSession = async () => {
      const { data } = await supabase.auth.getSession();
      if (data.session) {
        setCurrentUser(data.session.user);
        navigate(getRouteFromCurrentUrl());
      }
    };
    checkSession();

    const { data: auth } = supabase.auth.onAuthStateChange((event, session) => {
      if (event === "SIGNED_IN") {
        setCurrentUser(session.user);
        navigate(getRouteFromCurrentUrl());
      } else if (event === "SIGNED_OUT") {
        setCurrentUser(null);
        navigate("/");
      }
    });

    return () => {
      auth.subscription.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex h-screen w-10/12 items-center justify-center lg:w-1/2 xl:w-1/4">
      <div
        className="p-3 w-100"
        style={{
          boxShadow: "2px 2px 15px black, -2px -2px 15px black",
          background: "white",
        }}
      >
        <div className="w-100">
          <img src={logo} className="App-logo m-auto" alt="logo" width={50} />
        </div>
        <Auth
          supabaseClient={supabase}
          appearance={{
            theme: ThemeSupa,
            variables: {
              default: {
                colors: {
                  inputLabelText: "black",
                  brandButtonText: "white",
                },
              },
            },
            className: {
              button: "bg-[#3fcf8e]",
            },
          }}
          providers={[]}
          theme="white"
          view="sign_in"
          redirectTo={`${redirectBaseUrl()}/reset-password`}
          localization={{
            variables: {
              sign_in: {
                email_label: "Email Address",
                password_label: "Password",
                email_input_placeholder: "",
                password_input_placeholder: "",
              },
              sign_up: {
                link_text: "",
              },
            },
          }}
        />
      </div>
    </div>
  );
};
