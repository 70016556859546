import React, { createContext, useEffect, useState } from "react";
import { supabase } from "utils/supabaseClient";
import Notification from "db/notification";

export const AppContext = createContext(null);

export const AppProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);
  const [toastRef, setToastRef] = useState(null);
  const [serviceTableFilter, setServiceTableFilter] = useState(
    JSON.parse(localStorage.getItem("serviceTableFilter")),
  );
  const [customerTableFilter, setCustomerTableFilter] = useState(
    JSON.parse(localStorage.getItem("customerTableFilter")),
  );
  const [deviceTableFilter, setDeviceTableFilter] = useState(
    JSON.parse(localStorage.getItem("deviceTableFilter")),
  );

  const fetchNotificationCount = async () => {
    const { data, count } = await Notification.getUnreadNotificationCountByID(
      currentUser.id,
    );

    if (data) {
      setNotificationCount(count);
    }
  };

  const fetchNotification = async () => {
    const { data } = await Notification.getNotificationByID(currentUser.id);

    if (data) {
      setNotifications(data);
    }
  };

  useEffect(() => {
    const getCurrentUser = async () => {
      const {
        data: { user },
      } = await supabase.auth.getUser();
      if (user) {
        setCurrentUser(user);
      }
    };
    getCurrentUser();
  }, []);

  return (
    <AppContext.Provider
      value={{
        currentUser,
        setCurrentUser,
        notifications,
        setNotifications,
        fetchNotification,
        notificationCount,
        fetchNotificationCount,
        toastRef,
        setToastRef,
        serviceTableFilter,
        setServiceTableFilter,
        customerTableFilter,
        setCustomerTableFilter,
        deviceTableFilter,
        setDeviceTableFilter,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
