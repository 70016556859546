import React, { useEffect, useState } from "react";
import { EntityStatus } from "utils/utils";
import Employee from "db/employee";
import { CommonTable } from "./CommonTable";

const tableColumns = [
  { key: "first_name", header: "First Name" },
  { key: "last_name", header: "Last Name" },
  { key: "phone", header: "Phone" },
  { key: "email", header: "Email" },
  {
    key: "inactive",
    header: "Status",
    cell: (row) => <EntityStatus inactive={row.inactive} />,
  },
];

export const SettingTabEmployee = () => {
  const [employees, setEmployees] = useState([]);

  const fetchEmployees = async () => {
    const { data } = await Employee.getAllData();
    if (data) {
      setEmployees(data);
    }
  };

  useEffect(() => {
    fetchEmployees();
  }, []);

  return (
    <div className="m-2 lg:m-5">
      <CommonTable
        tableName="Employees"
        tableColumns={tableColumns}
        tableRows={employees}
        requireCreateButton={true}
        requireSearch={false}
      />
    </div>
  );
};
