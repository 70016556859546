import { supabase } from "utils/supabaseClient";
import { isDev } from "utils/utils";

const DB_NAME = "employee";

export default class Employee {
  static async getAllData() {
    return await supabase
      .from(DB_NAME)
      .select("*")
      .order("created_at", { ascending: true });
  }

  static async getRep() {
    return await supabase
      .from(DB_NAME)
      .select("id, first_name")
      .eq("is_rep", true)
      .order("first_name", { ascending: true });
  }

  static async getEmployeeList() {
    return await supabase
      .from(DB_NAME)
      .select("id, first_name, inactive")
      .order("first_name", { ascending: true });
  }

  static async getFullNameByID(id) {
    return await supabase
      .from(DB_NAME)
      .select("first_name, last_name")
      .eq("id", id);
  }

  static async getEmployeeByID(id) {
    return await supabase
      .from(DB_NAME)
      .select("*")
      .eq("id", id);
  }

  static async getDashboardOrderByID(id) {
    return await supabase
      .from(DB_NAME)
      .select("dashboard_order")
      .eq("id", id);
  }

  static async getEmployeeDOB() {
    return await supabase
      .from(DB_NAME)
      .select("id, first_name, birthday")
      .eq("inactive", false)
      .not("birthday", "is", null);
  }

  static async inviteNewEmployee(employee) {
    return await supabase.functions.invoke("invite-user", {
      body: {
        first_name: employee.first_name,
        last_name: employee.last_name,
        email: employee.email,
        phone: employee.phone,
        inactive: employee.inactive,
        is_admin: employee.is_admin,
        can_edit_customer: employee.can_edit_customer,
        can_edit_inventory: employee.can_edit_inventory,
        isDev: isDev(),
      },
    });
  }

  static async upsertEmployee(employee) {
    return await supabase.from(DB_NAME).upsert(employee).select();
  }
}
