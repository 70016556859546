import React, { useState, useEffect } from "react";
import Employee from "db/employee";
import { formatPhoneNumber } from "utils/utils";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useGetCurrentEmployeeInfo } from "hooks/useGetCurrentEmployee";
import { useToast } from "hooks/useToast";

const initialEmployeeVal = {
  first_name: "",
  last_name: "",
  email: "",
  phone: "",
  birthday: "",
  inactive: false,
  is_rep: false,
  is_admin: false,
  can_edit_inventory: false,
  can_edit_customer: false,
};

export const SettingTabEmployeeForm = () => {
  const navigate = useNavigate();
  const [employee, setEmployee] = useState(initialEmployeeVal);
  const { is_admin } = useGetCurrentEmployeeInfo();
  const [params] = useSearchParams()
  const formType = params.get('type');
  const id = params.get('id');
  const { showSuccess, showError } = useToast();

  const fetchEmployeeById = async (employeeID) => {
    const { data } = await Employee.getEmployeeByID(employeeID);

    if (data) {
      setEmployee(data[0]);
    }
  };

  useEffect(() => {
    if (formType === "edit") {
      fetchEmployeeById(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInputChange = (e) => {
    const { name, value, checked } = e.target;
    if (
      [
        "is_rep",
        "is_admin",
        "can_edit_customer",
        "can_edit_inventory",
        "inactive",
      ].indexOf(name) !== -1
    ) {
      setEmployee({
        ...employee,
        [name]: checked,
      });
    } else if (name.includes("phone")) {
      setEmployee({
        ...employee,
        [name]: formatPhoneNumber(value),
      });
    } else {
      setEmployee({
        ...employee,
        [name]: value,
      });
    }
  };

  const onFormSubmit = async (e) => {
    e.preventDefault();

    var data = {};

    if (formType === "add") {
      data = await Employee.inviteNewEmployee(employee);
    } else if (formType === "edit") {
      data = await Employee.upsertEmployee(employee);
    }

    if (data.data) {
      if (formType === "add") {
        showSuccess("Creation successful!", "New employee is created.");
      } else {
        showSuccess("Edit successful!", "Your changes are saved successfully.");
      }
    }

    if (data.error) {
      showError();
    }

    navigate("/home/setting/employee");
  };

  return (
    <div className="relative h-full max-h-full w-full align-middle">
      <div className="border-b bg-white shadow p-4">
        <h1 className="subtitle md:title">Employee</h1>
      </div>
      <form onSubmit={onFormSubmit}>
        <div className="flex items-center justify-end space-x-6 rounded-b border-t border-gray-200 pt-3 px-3 pb-0">
          <button
            type="button"
            className="rounded-lg bg-red-500 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-reed-800 focus:outline-none focus:ring-4 focus:ring-red-300"
            onClick={() => navigate(-1)}
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={!is_admin}
            title={!is_admin ? "Only admin can edit a user" : null}
            className="rounded-lg bg-blue-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 disabled:bg-gray-300"
          >
            Save
          </button>
        </div>
        <div className="space-y-6 p-6">
          <div className="grid grid-cols-6 gap-6">
            <div className="col-span-6 sm:col-span-3">
              <label className="mb-2 block text-sm font-medium text-gray-900">
                First Name *
              </label>
              <input
                type="text"
                className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 shadow-sm focus:border-blue-600 focus:ring-blue-600"
                placeholder="John"
                required={true}
                name="first_name"
                onChange={handleInputChange}
                value={employee.first_name}
                disabled={!is_admin}
              />
            </div>
            <div className="col-span-6 sm:col-span-3">
              <label className="mb-2 block text-sm font-medium text-gray-900">
                Last Name *
              </label>
              <input
                type="text"
                className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 shadow-sm focus:border-blue-600 focus:ring-blue-600"
                placeholder="Doe"
                required={true}
                name="last_name"
                onChange={handleInputChange}
                value={employee.last_name}
                disabled={!is_admin}
              />
            </div>
            <div className="col-span-6 sm:col-span-3">
              <label className="mb-2 block text-sm font-medium text-gray-900">
                Email *
              </label>
              <input
                type="email"
                className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 shadow-sm focus:border-blue-600 focus:ring-blue-600"
                placeholder="johndoe@gmail.com"
                required={true}
                name="email"
                value={employee.email}
                onChange={handleInputChange}
                disabled={formType === "edit"}
              />
            </div>
            <div className="col-span-6 sm:col-span-3">
              <label className="mb-2 block text-sm font-medium text-gray-900">
                Phone
              </label>
              <input
                type="text"
                className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 shadow-sm focus:border-blue-600 focus:ring-blue-600"
                placeholder="(xxx)-xxx-xxxx"
                required={false}
                name="phone"
                value={employee.phone}
                onChange={handleInputChange}
                disabled={!is_admin}
              />
            </div>
            <div className="col-span-6 sm:col-span-1">
              <label className="mb-2 block text-sm font-medium text-gray-900">
                Birthday 🎂
              </label>
              <input
                type="date"
                className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 shadow-sm focus:border-blue-600 focus:ring-blue-600"
                required={false}
                name="birthday"
                value={employee.birthday}
                onChange={handleInputChange}
                disabled={!is_admin}
              />
            </div>
            <div className="col-span-3 flex items-center sm:col-span-1">
              <input
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 bg-gray-100 text-blue-600 focus:ring-blue-500"
                name="is_rep"
                onChange={handleInputChange}
                checked={employee.is_rep}
                disabled={!is_admin}
              />
              <label className="ml-2 text-sm font-medium text-gray-900">
                Is Rep?
              </label>
            </div>
            <div className="col-span-3 flex items-center sm:col-span-1">
              <input
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 bg-gray-100 text-blue-600 focus:ring-blue-500"
                name="is_admin"
                onChange={handleInputChange}
                checked={employee.is_admin}
                disabled={!is_admin}
              />
              <label className="ml-2 text-sm font-medium text-gray-900">
                Is Admin?
              </label>
            </div>
            <div className="col-span-3 flex items-center sm:col-span-1">
              <input
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 bg-gray-100 text-blue-600 focus:ring-blue-500"
                name="can_edit_customer"
                onChange={handleInputChange}
                checked={employee.can_edit_customer}
                disabled={!is_admin}
              />
              <label className="ml-2 text-sm font-medium text-gray-900">
                Edit Customer?
              </label>
            </div>
            <div className="col-span-3 flex items-center sm:col-span-1">
              <input
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 bg-gray-100 text-blue-600 focus:ring-blue-500"
                name="can_edit_inventory"
                onChange={handleInputChange}
                checked={employee.can_edit_inventory}
              />
              <label className="ml-2 text-sm font-medium text-gray-900">
                Edit Inventory?
              </label>
            </div>
            <div className="col-span-3 flex items-center sm:col-span-1">
              <input
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500"
                name="inactive"
                onChange={handleInputChange}
                checked={employee.inactive}
                disabled={!is_admin}
              />
              <label className="ml-2 text-sm font-medium text-gray-900">
                Inactive
              </label>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
